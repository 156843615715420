import { BaseApiService } from './BaseApiService';

class ProfileApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/profile/', access, onError);
  }

  getPocket$ = (id) => {
    return this.http.getAjax(`${id}/pocket/`);
  };

  getPocket = (id) => {
    return this.http.getFetch(`${id}/pocket/`);
  };

  postWallet$ = (id, asset) => {
    return this.http.postAjax(`${id}/pocket/wallets/`, { asset: asset });
  };

  postWallet = (id, asset) => {
    return this.http.postFetch(`${id}/pocket/wallets/`, { asset: asset });
  };
}

export default ProfileApiService;
