import { Big } from 'big.js'
import Humanize from "humanize-plus"
import {localeNumberSeparators} from "./utils"

Big.NE = -11 // exponent notation safely outside 8 decimal points

// this should only run once
export const addHumanizeToNumber = () => {

  const countSignificantDecimals = (number) => {

    let decimalStart = number.toString().search("\\.")
    let decimals = number.toString().slice(decimalStart+1)

    let numberOfCeros = 0
    for(let i = decimals.length - 1; i >= 0;  i--){
      if(decimals[i] !== "0"){
        break
      }
      numberOfCeros = numberOfCeros + 1
    }
    return decimals.length - numberOfCeros
  }

  Object.assign(Number.prototype, {
    humanize(fixedPointDecimals){
      return this.toLocaleString(this.locale, {maximumFractionDigits:fixedPointDecimals})
    },

    shortHumanize(fixedPointDecimals){
      let short = Humanize.compactInteger(this, fixedPointDecimals)
      return Number(short.slice(0,-1)).toLocaleString(this.locale, {maximumFractionDigits:fixedPointDecimals}) + short.slice(-1)
    }
  })

  Object.assign(Big.prototype, {
    humanize(fixedPointDecimals) {
      const [thousandSeparator, decimalSeparator] = localeNumberSeparators(this.locale)
      // TBD:  This seems buggy.  --enewhuis
      let significantDecimals = countSignificantDecimals(this)
      fixedPointDecimals = significantDecimals > fixedPointDecimals ? significantDecimals : fixedPointDecimals
      return Humanize.formatNumber(this.valueOf(), fixedPointDecimals, thousandSeparator, decimalSeparator)
    }
  })
}

// It should run only once, before any number is created
export const setLocaleForNumber = (locale) => {
  // eslint-disable-next-line no-extend-native
  Object.defineProperty(Number.prototype, "locale", {
    value:locale,
    configurable:true
  })

  Object.defineProperty(Big.prototype, "locale", {
    value:locale,
    configurable:true
  })
}

export const formatInteger = (value, locale) =>
  Humanize.formatNumber(value, 0, ...localeNumberSeparators(locale))

export const format = (value, asset, locale) => {
  const rm = Big.RM; Big.RM = 0
  const [thousandSeparator, decimalSeparator] = localeNumberSeparators(locale)

  let short =
    value === 0 ? '0' : Big(value)
      .div(Big(10**(asset.decimals)))
      .round(asset.decimals_min)
      .valueOf()
  short = Humanize.formatNumber(short, asset.decimals_min, thousandSeparator, decimalSeparator)
  if (value < 0 && short[0] !== '-') {
    short = `-${short}` // to preserve sign when negative amount is less than displayed precision
  }

  let long =
    value === 0 ? '0' : Big(value)
      .div(Big(10**asset.decimals))
      .valueOf()
  long = Humanize.formatNumber(long, asset.decimals, thousandSeparator, decimalSeparator)

  Big.RM = rm
  return [short, long]
}