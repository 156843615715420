import { useState, useEffect } from 'react';
import { fetchQuery } from 'react-relay';
import { environment } from '../relay';
import useAssetSearchQuery from './useAssetSearchQuery';

export default function useAssetSearch(assetSlug) {
  const [slug] = useState(assetSlug);
  const [result, setResult] = useState(null);

  useEffect(() => {
    fetchQuery(environment, useAssetSearchQuery, {
      classFilter: {
        slug: { equalTo: slug },
      },
      assetFilter: {
        visible: { equalTo: true },
        visibleInvest: { equalTo: true },
        securitiesAssetclassByAssetClassId: {
          slug: { equalTo: slug },
        },
      },
      venueFilter: {
        securitiesAssetclassByAssetClassId: {
          slug: { equalTo: slug },
        },
      },
      perkFilter: {
        visibility: { equalTo: 'public' },
        securitiesVenueByVenueId: {
          securitiesAssetclassByAssetClassId: {
            slug: { equalTo: slug },
          },
        },
      },
    }).subscribe({
      next: (data) => {
        setResult(data);
      },
    });
  }, [slug]);

  return result;
}
