import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { rootEpic } from './rootEpic';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import LogRocket from 'logrocket';

type FixMeLater = any;

if (process.env.NODE_ENV === 'production') {
  LogRocket.init('lj4tm2/eric-r-newhuis');
}

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware();

const configureStore = (preloadedState?: FixMeLater) => {
  const store = createStore(
    rootReducer(history),
    preloadedState,
    process.env.NODE_ENV === 'production'
      ? applyMiddleware(routerMiddleware(history), thunk, epicMiddleware)
      : composeWithDevTools(
          applyMiddleware(routerMiddleware(history), thunk, epicMiddleware)
        )
  );

  epicMiddleware.run(rootEpic);

  return store;
};

export default configureStore;
