import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { OpenInNewIcon } from '../icons';
import { useSimpleDialog } from '../hooks';
import { DocumentDialog } from '.';

const DocumentButton = ({ field, form, title, content, caption }) => {
  const [clicked, setClicked] = useState(field?.value ?? false);
  const color = clicked ? 'secondary' : 'primary';
  const [isOpen, handleOpen, handleClose] = useSimpleDialog(false);

  const handleEnterKeyOrClick = () => {
    setClicked(true);
    handleOpen(true);
    form && form.setFieldValue(field.name, true);
  };

  const handleBlur = () => {
    field && field.onBlur(field.name);
  };

  return (
    <>
      <Button
        variant='outlined'
        color={color}
        endIcon={<OpenInNewIcon color={color} />}
        onKeyPress={(e) => e.charCode === 13 && handleEnterKeyOrClick()}
        onClick={handleEnterKeyOrClick}
        onBlur={handleBlur}
      >
        {caption}
      </Button>
      <DocumentDialog
        title={title}
        content={content}
        isOpen={isOpen}
        handleClose={handleClose}
      />
    </>
  );
};

export default DocumentButton;
