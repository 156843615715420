import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query useAssetClassesQuery($lang: String = "en") {
    assetClasses: allSecuritiesAssetclasses {
      nodes {
        slug
        isOpen
        classcontent(languageCode: $lang) {
          nodes {
            code
            contentName
            tag
            text
          }
        }
      }
    }
  }
`;
