import graphql from 'babel-plugin-relay/macro';

export default graphql`
  fragment LedgerFeedFragment_wallet on SecuritiesWallet
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "Cursor" }
    orderBy: {
      type: "[SecuritiesLedgersOrderBy!]"
      defaultValue: [PRIMARY_KEY_DESC]
    }
  ) {
    id
    balance
    asset: securitiesAssetByAssetId {
      isActive
      isBurnEnabled
      isMintEnabled
    }
    ledger: securitiesLedgersByWalletId(
      first: $count
      after: $cursor
      orderBy: $orderBy # Non-pagination variables
    ) @connection(key: "LedgerFeed_ledger") {
      edges {
        node {
          creditDebit
          id
          tx: securitiesTransactionByTransactionId {
            type: djangoContentTypeByPolymorphicCtypeId {
              model
            }
            time
          }
        }
      }
    }
  }
`;
