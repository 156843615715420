/**
 * @generated SignedSource<<9a8be5c54cba2a4b3b658593c38e089f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PromotionsQuery$variables = {
  language: string;
};
export type PromotionsQuery$data = {
  readonly promos: {
    readonly nodes: ReadonlyArray<{
      readonly classcontent: {
        readonly nodes: ReadonlyArray<{
          readonly code: string | null;
          readonly contentName: string | null;
          readonly tag: string | null;
          readonly text: string | null;
        } | null>;
      };
      readonly code: string;
      readonly image: {
        readonly file: {
          readonly path: string | null;
        } | null;
      } | null;
      readonly property: {
        readonly faceValue: number;
        readonly faceValueFiat: {
          readonly external: {
            readonly asset: {
              readonly code: string;
              readonly symbol: string | null;
            } | null;
          } | null;
        } | null;
      } | null;
    } | null>;
  } | null;
};
export type PromotionsQuery = {
  response: PromotionsQuery$data;
  variables: PromotionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "language"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "condition",
    "value": {
      "isPromoted": true
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "languageCode",
      "variableName": "language"
    }
  ],
  "concreteType": "SecuritiesAssetClasscontentConnection",
  "kind": "LinkedField",
  "name": "classcontent",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SecuritiesAssetClasscontentRecord",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": "path",
  "args": null,
  "kind": "ScalarField",
  "name": "file",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faceValue",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PromotionsQuery",
    "selections": [
      {
        "alias": "promos",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesAssetsConnection",
        "kind": "LinkedField",
        "name": "allSecuritiesAssets",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SecuritiesAsset",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": "image",
                "args": null,
                "concreteType": "FilerImage",
                "kind": "LinkedField",
                "name": "filerImageByFeaturedArtId",
                "plural": false,
                "selections": [
                  {
                    "alias": "file",
                    "args": null,
                    "concreteType": "FilerFile",
                    "kind": "LinkedField",
                    "name": "filerFileByFilePtrId",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "property",
                "args": null,
                "concreteType": "SecuritiesPropertyasset",
                "kind": "LinkedField",
                "name": "securitiesPropertyassetByAssetPtrId",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": "faceValueFiat",
                    "args": null,
                    "concreteType": "SecuritiesFiatasset",
                    "kind": "LinkedField",
                    "name": "securitiesFiatassetByFaceValueQuoteAssetId",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "external",
                        "args": null,
                        "concreteType": "SecuritiesExternalasset",
                        "kind": "LinkedField",
                        "name": "securitiesExternalassetByExternalassetPtrId",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "asset",
                            "args": null,
                            "concreteType": "SecuritiesAsset",
                            "kind": "LinkedField",
                            "name": "securitiesAssetByAssetPtrId",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allSecuritiesAssets(condition:{\"isPromoted\":true})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PromotionsQuery",
    "selections": [
      {
        "alias": "promos",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesAssetsConnection",
        "kind": "LinkedField",
        "name": "allSecuritiesAssets",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SecuritiesAsset",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": "image",
                "args": null,
                "concreteType": "FilerImage",
                "kind": "LinkedField",
                "name": "filerImageByFeaturedArtId",
                "plural": false,
                "selections": [
                  {
                    "alias": "file",
                    "args": null,
                    "concreteType": "FilerFile",
                    "kind": "LinkedField",
                    "name": "filerFileByFilePtrId",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "property",
                "args": null,
                "concreteType": "SecuritiesPropertyasset",
                "kind": "LinkedField",
                "name": "securitiesPropertyassetByAssetPtrId",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": "faceValueFiat",
                    "args": null,
                    "concreteType": "SecuritiesFiatasset",
                    "kind": "LinkedField",
                    "name": "securitiesFiatassetByFaceValueQuoteAssetId",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "external",
                        "args": null,
                        "concreteType": "SecuritiesExternalasset",
                        "kind": "LinkedField",
                        "name": "securitiesExternalassetByExternalassetPtrId",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "asset",
                            "args": null,
                            "concreteType": "SecuritiesAsset",
                            "kind": "LinkedField",
                            "name": "securitiesAssetByAssetPtrId",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allSecuritiesAssets(condition:{\"isPromoted\":true})"
      }
    ]
  },
  "params": {
    "cacheID": "903d3f07938e8649098cca40fab0a515",
    "id": null,
    "metadata": {},
    "name": "PromotionsQuery",
    "operationKind": "query",
    "text": "query PromotionsQuery(\n  $language: String!\n) {\n  promos: allSecuritiesAssets(condition: {isPromoted: true}) {\n    nodes {\n      code\n      classcontent(languageCode: $language) {\n        nodes {\n          code\n          contentName\n          tag\n          text\n        }\n      }\n      image: filerImageByFeaturedArtId {\n        file: filerFileByFilePtrId {\n          path: file\n          id\n        }\n        id\n      }\n      property: securitiesPropertyassetByAssetPtrId {\n        faceValue\n        faceValueFiat: securitiesFiatassetByFaceValueQuoteAssetId {\n          external: securitiesExternalassetByExternalassetPtrId {\n            asset: securitiesAssetByAssetPtrId {\n              code\n              symbol\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0a37b8201d4473648f4b66bfc2d2cf9";

export default node;
