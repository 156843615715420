import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Toolbar,
} from '@material-ui/core';
import { Formik } from 'formik';
import FormikAssetQuantityInput from './fields/FormikAssetQuantityInput';
import * as Yup from 'yup';
import Stone from './Stone';
import {
  useAsset,
  useError,
  useIntl,
  useSnack,
  useWallet,
  useApiService,
} from '../hooks';
import { VenueApiService } from '../services';

const Booster = ({ venue }) => {
  const [open, setOpen] = useState(false);
  const LT = useAsset('LT');
  const wallet = useWallet('LT');
  const translate = useIntl();
  const onSnack = useSnack();
  const onError = useError();
  const venueApi = useApiService(VenueApiService);

  const current = Math.round(venue.boost / 10 ** LT.decimals);

  return (
    <>
      <Toolbar>
        <Button
          size='small'
          variant='contained'
          color='secondary'
          onClick={() => setOpen(true)}
        >
          <Stone>venue.boost.cta</Stone>
        </Button>
      </Toolbar>
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>
          <Stone>venue.boost.title</Stone>
        </DialogTitle>
        <Formik
          initialValues={{
            amount: '',
          }}
          validationSchema={Yup.object().shape({
            amount: Yup.number()
              .positive()
              .min(100000000)
              .max(wallet?.available ?? 0),
          })}
          onSubmit={(values, { setSubmitting }) => {
            venueApi.postBoosts(venue.rowId, values).then((response) => {
              setSubmitting(false);
              if (response.ok) {
                setOpen(false);
                onSnack('success', translate.venue.boost.success);
              } else {
                onError(response);
              }
            });
          }}
          render={({ isSubmitting, isValid, submitForm, touched }) => (
            <>
              <DialogContent>
                <Stone paragraph>venue.boost.caption</Stone>
                <Stone paragraph variant='caption'>
                  venue.boost.description
                </Stone>
                <Stone paragraph value={current}>
                  venue.boost.current
                </Stone>
                <FormikAssetQuantityInput
                  autoFocus
                  name='amount'
                  label={<Stone>venue.boost.amount</Stone>}
                  asset={LT}
                  disabled={!wallet?.available}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color='default'>
                  <Stone>dialog.cancel</Stone>
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting || !isValid || !touched}
                  onClick={submitForm}
                >
                  <Stone>send.submit</Stone>
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
};

export default Booster;
