import { createSelector } from 'reselect';

const selectAllNotificationCounts = createSelector(
  (state) => state.profiles,
  (profiles) =>
    profiles.map((p) => {
      return { count: p.unread_notification_count };
    })
);

export default selectAllNotificationCounts;
