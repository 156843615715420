import { useState, useEffect } from 'react';
import { fetchQuery } from 'react-relay';
import { environment } from '../relay';
import useSubscribeIssueOfferQuery from './useSubscribeIssueOfferQuery';

export default function useSubscribeIssueOffer(assetId) {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchQuery(environment, useSubscribeIssueOfferQuery, {
      assetId: assetId,
      issueFilter: {
        // TODO: extend this to find only those with current offers
        //       to avoid need for findCurrentOffers
        securitiesIssueoffersByIssueIdExist: true,
      },
    }).subscribe({
      next: (data) => {
        setData(data);
      },
    });
  }, [assetId]);

  return data;
}
