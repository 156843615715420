import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query useCountriesQuery {
    countries: allCitiesLightCountries(orderBy: NAME_ASC) {
      nodes {
        rowId
        name
      }
    }
  }
`;
