/**
 * @generated SignedSource<<037d2b11d930a1680094946142b231cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationsNotificationCondition = {
  actionObjectContentTypeId?: number | null;
  actionObjectObjectId?: string | null;
  actorContentTypeId?: number | null;
  actorObjectId?: string | null;
  data?: string | null;
  deleted?: boolean | null;
  description?: string | null;
  emailed?: boolean | null;
  level?: string | null;
  public?: boolean | null;
  recipientId?: number | null;
  rowId?: number | null;
  targetContentTypeId?: number | null;
  targetObjectId?: string | null;
  timestamp?: any | null;
  unread?: boolean | null;
  verb?: string | null;
};
export type ActivityStreamQuery$variables = {
  count: number;
  filter: NotificationsNotificationCondition;
};
export type ActivityStreamQuery$data = {
  readonly stub: {
    readonly " $fragmentSpreads": FragmentRefs<"PagedFeed_stub">;
  } | null;
};
export type ActivityStreamQuery = {
  response: ActivityStreamQuery$data;
  variables: ActivityStreamQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = [
  {
    "kind": "Literal",
    "name": "rowId",
    "value": 42
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "condition",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      "PRIMARY_KEY_DESC"
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityStreamQuery",
    "selections": [
      {
        "alias": "stub",
        "args": (v2/*: any*/),
        "concreteType": "DjangoContentType",
        "kind": "LinkedField",
        "name": "djangoContentTypeByRowId",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              }
            ],
            "kind": "FragmentSpread",
            "name": "PagedFeed_stub"
          }
        ],
        "storageKey": "djangoContentTypeByRowId(rowId:42)"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ActivityStreamQuery",
    "selections": [
      {
        "alias": "stub",
        "args": (v2/*: any*/),
        "concreteType": "DjangoContentType",
        "kind": "LinkedField",
        "name": "djangoContentTypeByRowId",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "feed",
            "args": (v4/*: any*/),
            "concreteType": "NotificationsNotificationsConnection",
            "kind": "LinkedField",
            "name": "notificationsNotificationsByActorContentTypeId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationsNotificationsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "NotificationsNotification",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rowId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "data",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "verb",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timestamp",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unread",
                        "storageKey": null
                      },
                      {
                        "alias": "actionId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actionObjectObjectId",
                        "storageKey": null
                      },
                      {
                        "alias": "action",
                        "args": null,
                        "concreteType": "DjangoContentType",
                        "kind": "LinkedField",
                        "name": "djangoContentTypeByActionObjectContentTypeId",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "model",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "feed",
            "args": (v4/*: any*/),
            "filters": [
              "condition",
              "orderBy"
            ],
            "handle": "connection",
            "key": "ActivityStream_feed",
            "kind": "LinkedHandle",
            "name": "notificationsNotificationsByActorContentTypeId"
          }
        ],
        "storageKey": "djangoContentTypeByRowId(rowId:42)"
      }
    ]
  },
  "params": {
    "cacheID": "d664f0a04eb53144211a57133124730e",
    "id": null,
    "metadata": {},
    "name": "ActivityStreamQuery",
    "operationKind": "query",
    "text": "query ActivityStreamQuery(\n  $filter: NotificationsNotificationCondition!\n  $count: Int!\n) {\n  stub: djangoContentTypeByRowId(rowId: 42) {\n    ...PagedFeed_stub_26eJ3g\n    id\n  }\n}\n\nfragment PagedFeed_stub_26eJ3g on DjangoContentType {\n  id\n  feed: notificationsNotificationsByActorContentTypeId(condition: $filter, first: $count, orderBy: [PRIMARY_KEY_DESC]) {\n    edges {\n      node {\n        id\n        rowId\n        data\n        description\n        verb\n        timestamp\n        unread\n        actionId: actionObjectObjectId\n        action: djangoContentTypeByActionObjectContentTypeId {\n          model\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "40caec6e9c5d2b2fa6a616f85ae7b338";

export default node;
