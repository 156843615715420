import React from 'react';
import { useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  LinearProgress,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import { TextField, Select } from 'formik-material-ui';
import * as Yup from 'yup';
import selectProxyAndLTAssets from '../selectors/selectProxyAndLTAssets';
import FormikAssetField from './fields/FormikAssetField';
import FormikNumberField from './fields/FormikNumberField';
import FormikTextarea from './fields/FormikTextarea';
import FormikSelectField from './fields/FormikSelectField';
import FormikCheckboxField from './fields/FormikCheckboxField';
import { useError, useIntl, useApiService } from '../hooks';
import { GroupsApiService } from '../services';
import Schema from '../data/Schema';
import { ResponsiveDialog as Dialog } from '.';

const PerkDialog = ({
  // eslint-disable-next-line no-unused-vars
  dispatch,
  venue = null,
  perk,
  onCancel,
  onSubmit,
  open,
}) => {
  const { groups: t_ } = useIntl();
  const defaultAssetCode = useSelector(
    (state) => state.session.profile.defaultAssetCode
  );
  const assets = useSelector((state) => selectProxyAndLTAssets(state));
  const onError = useError();
  const groupsApi = useApiService(GroupsApiService);

  if (!assets.size) {
    return <LinearProgress />;
  }

  const key = (venue?.rowId ?? 0) + '-' + (perk?.rowId ?? 0);

  return (
    <Formik
      key={key}
      initialValues={
        perk
          ? {
              ...perk,
              quoteAsset: perk.quote?.asset.code,
            }
          : {
              venueId: venue ? venue.rowId : null,
              name: '',
              perkType: 'social',
              image: '',
              description: '',
              visibility: 'public',
              identity: 'any',
              membership: 'none',
              transparency: 'secret',
              quoteAsset: defaultAssetCode,
              duesLifetime: '',
              duesAnnual: '',
              duesMonthly: '',
              duesWeekly: '',
              duesDaily: '',
              duesHourly: '',
              duesMinute: '',
              isFlexibleFee: false,
              isTippable: false,
              iaActive: true,
            }
      }
      validationSchema={Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required(),
        duesLifetime: Schema.perk_decimal_fee,
        duesAnnual: Schema.perk_decimal_fee,
        duesMonthly: Schema.perk_decimal_fee,
        duesWeekly: Schema.perk_decimal_fee,
        duesDaily: Schema.perk_decimal_fee,
        duesHourly: Schema.perk_decimal_fee,
        duesMinute: Schema.perk_decimal_fee,
        isFlexibleFee: Yup.boolean(),
        isTippable: Yup.boolean(),
        isActive: Yup.boolean(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        groupsApi.post(values).then((response) => {
          setSubmitting(false);
          response.ok ? onSubmit() : onError(response.statusText);
        });
      }}
    >
      {({
        // eslint-disable-next-line no-unused-vars
        errors,
        isSubmitting,
        isValid,
        submitForm,
        touched,
        values,
      }) => {
        const asset = assets.get(values.quoteAsset);
        return (
          <Form>
            <Dialog
              fullWidth={true}
              maxWidth='xs'
              onClose={onCancel}
              open={open}
            >
              <DialogTitle>{venue ? venue.name : t_.dialog.title}</DialogTitle>
              <DialogContent>
                {venue ? (
                  <Typography paragraph variant='subtitle1'>
                    {t_.dialog.title}
                  </Typography>
                ) : null}
                <Typography variant='caption'>
                  {t_.dialog.description}
                </Typography>
                <Box py={1}>
                  <Field
                    autoFocus
                    type='text'
                    label={t_.dialog.name}
                    fullWidth
                    name='name'
                    component={TextField}
                  />
                </Box>
                <Box py={1}>
                  <FormControl>
                    <InputLabel htmlFor='perkType'>
                      {t_.perkType?.label ?? 'Perk Type'}
                    </InputLabel>
                    <FormikSelectField
                      component={Select}
                      name='perkType'
                      inputProps={{
                        id: 'perkType',
                      }}
                    >
                      {{
                        social:
                          t_.perkType?.social.offer ??
                          'A social group or association',
                      }}
                      {{
                        service:
                          t_.perkType?.service.offer ??
                          'Offer a service on a time basis.',
                      }}
                      {{
                        product:
                          t_.perkType?.product.offer ??
                          'Build, make, or produce a product.',
                      }}
                      {{
                        event:
                          t_.perkType?.event.offer ??
                          'Plan and coordinate an event or performance.',
                      }}
                      {{
                        personalty:
                          t_.perkType?.personalty.offer ??
                          'Sell your original work or collectable.',
                      }}
                      {/* Not ready yet...
                      <MenuItem disabled value='realty'>
                        {t_.perkType?.realty.offer ?? "Sell your real estate's future value."}
                      </MenuItem>
                      <MenuItem disabled value='debt'>
                          {t_.perkType?.debt.offer ?? 'Issue debt.'}
                      </MenuItem>*/}
                    </FormikSelectField>
                  </FormControl>
                </Box>
                <Box py={2}>
                  <FormControl fullWidth>
                    <Field
                      name='description'
                      component={FormikTextarea}
                      rowsMin={5}
                      style={{ fontFamily: 'Roboto' }}
                      placeholder={t_.description}
                    />
                    <FormHelperText>{t_.field_help.description}</FormHelperText>
                  </FormControl>
                </Box>
                <Typography paragraph variant='subtitle1'>
                  {t_.audience}
                </Typography>
                <Box pb={2} display='flex' flexDirection='row'>
                  <Box pr={1}>
                    <FormControl>
                      <InputLabel htmlFor='visibility'>
                        {t_.visibility.label}
                      </InputLabel>
                      <FormikSelectField
                        component={Select}
                        name='visibility'
                        inputProps={{
                          id: 'visibility',
                        }}
                      >
                        {{ public: t_.visibility.public }}
                        {{ private: t_.visibility.private }}
                      </FormikSelectField>
                    </FormControl>
                  </Box>
                  <Box pl={1}>
                    <FormControl>
                      <InputLabel htmlFor='identity'>
                        {t_.identity.label}
                      </InputLabel>
                      <FormikSelectField
                        name='identity'
                        inputProps={{
                          id: 'identity',
                        }}
                      >
                        {{ any: t_.identity.any }}
                        {{ verified: t_.identity.verified }}
                        {{ accredited: t_.identity.accredited }}
                      </FormikSelectField>
                    </FormControl>
                  </Box>
                  <Box pl={1}>
                    <FormControl>
                      <InputLabel htmlFor='membership'>
                        {t_.membership.label}
                      </InputLabel>
                      <FormikSelectField
                        component={Select}
                        name='membership'
                        inputProps={{
                          id: 'membership',
                        }}
                      >
                        {{ none: t_.membership.none }}
                        {{ any: t_.membership.public }}
                        {{ request: t_.membership.request }}
                        <MenuItem disabled value='invite'>
                          {t_.membership.invite}
                        </MenuItem>
                      </FormikSelectField>
                    </FormControl>
                  </Box>
                </Box>
                {values.membership === 'none' ? (
                  <div />
                ) : (
                  <>
                    <Box pb={2}>
                      <FormControl>
                        <InputLabel htmlFor='transparency'>
                          {t_.transparency.label}
                        </InputLabel>
                        <FormikSelectField
                          name='transparency'
                          inputProps={{
                            id: 'transparency',
                          }}
                        >
                          {{ secret: t_.transparency.secret }}
                          {{ totals: t_.transparency.totals }}
                          {{ contribs: t_.transparency.contribs }}
                        </FormikSelectField>
                        <FormHelperText>{t_.transparency.hint}</FormHelperText>
                      </FormControl>
                    </Box>
                    <Box pb={2}>
                      <Typography variant='subtitle1'>
                        {t_.fee_schedule}
                      </Typography>
                      <Box display='flex'>
                        <Box pr={2}>
                          <Box py={1}>
                            <FormikNumberField
                              decimalScale={asset.decimals_min}
                              label={t_.dues.lifetime}
                              name='duesLifetime'
                            />
                          </Box>
                          <Box py={1}>
                            <FormikNumberField
                              decimalScale={asset.decimals_min}
                              label={t_.dues.annual}
                              name='duesAnnual'
                            />
                          </Box>
                          <Box py={1}>
                            <FormikNumberField
                              decimalScale={asset.decimals_min}
                              label={t_.dues.monthly}
                              name='duesMonthly'
                            />
                          </Box>
                          <Box py={1}>
                            <FormikNumberField
                              decimalScale={asset.decimals_min}
                              label={t_.dues.weekly}
                              name='duesWeekly'
                            />
                          </Box>
                          <Box py={1}>
                            <FormikNumberField
                              decimalScale={asset.decimals_min}
                              label={t_.dues.daily ?? 'Daily'}
                              name='duesDaily'
                            />
                          </Box>
                          <Box py={1}>
                            <FormikNumberField
                              decimalScale={asset.decimals_min}
                              label={t_.dues.hourly ?? 'Hourly'}
                              name='duesHourly'
                            />
                          </Box>
                          <Box py={1}>
                            <FormikNumberField
                              decimalScale={asset.decimals_min}
                              label={t_.dues.minute ?? 'Per Minute'}
                              name='duesMinute'
                            />
                          </Box>
                        </Box>
                        <Box pb={2}>
                          <FormikAssetField
                            label={t_.dues.asset}
                            name='quoteAsset'
                            assets={assets}
                            disabled={values.membership === 'none'}
                          />
                          <Box py={1}>
                            <FormControl>
                              <FormikCheckboxField
                                name='isFlexibleFee'
                                label={t_.dues_any}
                              />
                              <FormHelperText>
                                {t_.dues_any_hint}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                          <Box py={1}>
                            <FormControl>
                              <FormikCheckboxField
                                name='isTippable'
                                label={t_.tippable}
                              />
                              <FormHelperText>
                                {t_.tippable_hint}
                              </FormHelperText>
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
                <Box py={1}>
                  <FormControl>
                    <FormikCheckboxField
                      name='isActive'
                      label={t_.active.label}
                    />
                    <FormHelperText>{t_.active.hint}</FormHelperText>
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color='default'>
                  {t_.dialog.cancel}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  disabled={!touched || !isValid || isSubmitting}
                  onClick={submitForm}
                >
                  {isSubmitting ? (
                    <CircularProgress size={20} />
                  ) : (
                    t_.dialog.submit
                  )}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PerkDialog;
