import { format, formatRelative } from 'date-fns';
import {
  enUS as en,
  enUS as en_US,
  enGB as en_GB,
  es,
  pt,
  ptBR as pt_BR,
  arSA as ar
} from 'date-fns/locale';

// As components are migrated to functional components that can support hooks, 
// convert them to use useDateFns instead of these. Locale is not 
// passed into the hook; it retrieves it from application state on its own.


const locales = { en, en_US, en_GB, es, pt, pt_BR, ar };

function getBestLocale(loc) {
  const [lang, region] = loc.split('-', 2);

  const langRegion = lang + (region ? '_' + region.toUpperCase() : '');

  return locales[langRegion] ?? locales[lang] ?? locales['en'];
}

function fmt(date, formatStr, loc) {
  return format(date, formatStr, {
    locale: getBestLocale(loc),
  });
}

function fmtRel(date, baseDate, loc) {
  return formatRelative(date, baseDate, {
    locale: getBestLocale(loc),
  });
}

export { fmt as format, fmtRel as formatRelative };
