import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query TransferActivityQuery($rowId: Int!) {
    transfer: securitiesTransferByTransactionPtrId(transactionPtrId: $rowId) {
      note
      quantity
      networkAsset: securitiesNetworkassetByAssetId {
        asset: securitiesAssetByAssetPtrId {
          code
          decimals
          decimals_min: decimalsMin
          icon
          use_static_icon: useStaticIcon
        }
      }
    }
  }
`;
