import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVertIcon } from '../icons';

// props.options = [{key, icon, label}]
//
const SelectedMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(props.value);

  function handleClickListItem(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuItemClick(event, index) {
    setSelectedIndex(index);
    setAnchorEl(null);
    props.onSelect(index, props.options[index]);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <List component='nav' aria-label='Device settings'>
        <ListItem button onClick={handleClickListItem}>
          <ListItemText
            primary={props.label}
            secondary={
              selectedIndex === null ? null : props.options[selectedIndex].label
            }
          />
          <MoreVertIcon />
        </ListItem>
      </List>
      <Menu
        id='lock-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.options.map((option, index) => (
          <MenuItem
            key={index}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SelectedMenu;
