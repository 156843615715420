/**
 * @generated SignedSource<<a0d661aba03127ad108d57e6efdf38e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PagedFeed_stub$data = {
  readonly feed: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly action: {
          readonly model: string;
        } | null;
        readonly actionId: string | null;
        readonly data: string | null;
        readonly description: string | null;
        readonly id: string;
        readonly rowId: number;
        readonly timestamp: any;
        readonly unread: boolean;
        readonly verb: string;
      } | null;
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "PagedFeed_stub";
};
export type PagedFeed_stub$key = {
  readonly " $data"?: PagedFeed_stub$data;
  readonly " $fragmentSpreads": FragmentRefs<"PagedFeed_stub">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    },
    {
      "defaultValue": [
        "PRIMARY_KEY_DESC"
      ],
      "kind": "LocalArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "feed"
        ]
      }
    ]
  },
  "name": "PagedFeed_stub",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "feed",
      "args": [
        {
          "kind": "Variable",
          "name": "condition",
          "variableName": "filter"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "concreteType": "NotificationsNotificationsConnection",
      "kind": "LinkedField",
      "name": "__ActivityStream_feed_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "NotificationsNotificationsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NotificationsNotification",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "data",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "verb",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timestamp",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unread",
                  "storageKey": null
                },
                {
                  "alias": "actionId",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "actionObjectObjectId",
                  "storageKey": null
                },
                {
                  "alias": "action",
                  "args": null,
                  "concreteType": "DjangoContentType",
                  "kind": "LinkedField",
                  "name": "djangoContentTypeByActionObjectContentTypeId",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "model",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DjangoContentType",
  "abstractKey": null
};
})();

(node as any).hash = "397e935b08acbed98605be37aa0186bd";

export default node;
