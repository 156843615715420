import { BaseApiService } from './BaseApiService';

class MintsApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/mints/', access, onError);
  }

  postRequest(values) {
    return this.http.postFetch(``, values)
  }

  postRequest$(values) {
    return this.http.postAjax(``, values)
  }

}

export default MintsApiService;
