import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  makeStyles,
} from '@material-ui/core';
import {
  Anon,
  Auth,
  ContactUs,
  FAQ,
  MainContainer,
  Stone,
  PolicyList,
} from '../components';
import { useAccordionGroup } from '../hooks';

export const useHelpStyles = makeStyles((theme) => ({
  root: {
    minHeight: 600,
  },
  library: {
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  centered: {
    textAlign: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  paddedBottom: {
    paddingBottom: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  anonHeaderSpacer: {
    height: theme.spacing(8),
  },
}));

export const Help = () => {
  const classes = useHelpStyles();
  const { expanded, handleChange } = useAccordionGroup();

  return (
    <MainContainer maxWidth='sm' className={classes.root}>
      <Anon>
        <div className={classes.anonHeaderSpacer} />
      </Anon>
      <Paper className={classes.paper} elevation={1}>
        <Stone variant='h5'>help.title</Stone>
        <Accordion
          key='faq-panel'
          expanded={expanded === 'faq-panel'}
          onChange={handleChange('faq-panel')}
        >
          <AccordionSummary key='faq-summary'>
            <Stone variant='h6'>help.faq</Stone>
          </AccordionSummary>
          <AccordionDetails key='faq=details'>
            {expanded === 'faq-panel' && <FAQ />}
          </AccordionDetails>
        </Accordion>
        <Auth key='policy-panel'>
          <Accordion
            expanded={expanded === 'policy-panel'}
            onChange={handleChange('policy-panel')}
          >
            <AccordionSummary key='policy-summary'>
              <Stone variant='h6'>help.policies</Stone>
            </AccordionSummary>
            <AccordionDetails key='policy=details'>
              {expanded === 'policy-panel' && <PolicyList />}
            </AccordionDetails>
          </Accordion>
        </Auth>
        <Accordion
          key='contact-panel'
          expanded={expanded === 'contact-panel'}
          onChange={handleChange('contact-panel')}
        >
          <AccordionSummary>
            <Stone variant='h6'>help.contact_us</Stone>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === 'contact-panel' && <ContactUs />}
          </AccordionDetails>
        </Accordion>
      </Paper>
    </MainContainer>
  );
};

export default Help;
