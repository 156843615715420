import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Subject, of, map } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FRIENDS_FETCH_ } from '../state/friends';
import { List } from 'immutable';
import ProfileAvatar from './ProfileAvatar';
import {
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Popper,
  TextField,
  withStyles,
} from '@material-ui/core';
import deburr from 'lodash/deburr';
import autocomplete from '../operators/autocomplete';

const styles = () => ({
  input: {
    minWidth: 240,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
})

const renderInputComponent = ({ classes, inputRef = () => {}, ref, ...other }) => (
  <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
        startAdornment: (
            <InputAdornment position="start">@</InputAdornment>
          ),
      }}
      {...other}
  />
)

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match('@' + suggestion.name, query)
  const parts = parse('@' + suggestion.name, matches)

  return (
    <MenuItem selected={isHighlighted} component="div">
      <ListItemIcon>
        <ProfileAvatar profile={suggestion} />
      </ListItemIcon>
      <ListItemText
        primary={
          parts.map(
            (part, index) =>
              part.highlight
              ?
              <span key={String(index)} style={{ fontWeight: 500 }}>{part.text}</span>
              :
              <strong key={String(index)} style={{ fontWeight: 300 }}>{part.text}</strong>
          )
        }
        secondary={suggestion.display_name}
      />
    </MenuItem>
  )
}

class FriendPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.value || '',
      suggestions: List()
    }
    this.value$ = new Subject()
    this.debounced$ = this.value$.pipe(autocomplete(1000, this.fetch))
  }

  componentDidMount() {
    this.props.friendsFetch()
    this.debouncer = this.debounced$.subscribe(this.props.onChange)
  }

  componentWillUnmount() {
    if (this.debouncer) {
      this.debouncer.unsubscribe()
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleChange = (event, { newValue, method }) => {
    this.setState({ value: newValue })
    this.value$.next(newValue)
  }

  fetch = value => {
    const { profileId } = this.props
    if (value) {
      const profile = this.props.friends.get(value)
      return profile
        ? of(profile)
        : ajax.getJSON(`/api/profile?address=${value}`).pipe(
          map(r => r.length === 0 ? '' : r[0].id === profileId ? '' : r[0])
        )
    }
    return of('')
  }

  getSuggestions = value => {
    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length
    let count = 0

    return inputLength === 0
      ?
      List()
      :
      this.props.friends.reduce(
        (acc, friend) => {
          if (count < 5 && inputValue === friend.name.slice(0, inputLength).toLowerCase()) {
            count += 1
            return acc.push(friend)
          }
          else {
            return acc
          }
        },
        List()
      )
  }

  onSuggestionsFetchRequested = ({ value }) => {
    let suggestions = this.getSuggestions(value)
    this.setState({ suggestions })
  }

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: List() })
  }

  getLabel = () => {
    const { translate, variant } = this.props
    const { value } = this.state

    if (variant === 'email') {
      if (!value) {
        return translate.recipient.profile_or_email
      }
      else if (value[0] === '@' || value.search('@') === -1) {
        return translate.recipient.profile
      }
      else {
        return translate.recipient.email
      }
    }
    else {
      return translate.recipient.profile
    }
  }

  render() {
    const { autoFocus, classes } = this.props
    const { value, suggestions } = this.state

    return (
      <Autosuggest
        suggestions={suggestions.toArray()}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={suggestion => suggestion.name}
        renderInputComponent={renderInputComponent}
        renderSuggestion={renderSuggestion}
        inputProps={{
          autoFocus,
          classes,
          label: this.getLabel(),
          value,
          onChange: this.handleChange,
          onBlur: this.props.onBlur,
          inputRef: node => {
              this.popperNode = node
          },
        }}
        theme={{
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Popper anchorEl={this.popperNode} open={Boolean(options.children)}>
            <Paper
              square
              {...options.containerProps}
              style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
            >
              {options.children}
            </Paper>
          </Popper>
        )}
      />
    )
  }
}

const mapStateToProps = state => ({
  profileId: state.session.profileID,
  friends: state.friends,
  translate: state.view.translate
})

const mapDispatchToProps = dispatch => ({
  friendsFetch: () => dispatch(FRIENDS_FETCH_()),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, {withTheme: true}),
)(FriendPicker)
