import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import {
  Avatar,
  Badge,
  Box,
  Drawer,
  Grid,
  List,
  Typography,
  isWidthDown,
  makeStyles,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { PersonAddIcon } from '../../icons';
import { SESSION_FETCH_ } from '../../state/session';
import { useIntl, useWidth } from '../../hooks';
import selectAllNotificationCounts from '../../selectors/selectAllNotificationCounts';
import ProfileAvatar from '../ProfileAvatar';
import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  panel: {
    margin: theme.spacing(2),
    width: 320,
  },
  drawerPaper: {
    zIndex: 0,
    [theme.breakpoints.down('sm')]: { height: 'auto' },
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  fab: {
    zIndex: theme.zIndex.drawer + 1,
    margin: 0,
    top: 8,
    right: 8,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
  },
  fab_layout: {
    zIndex: theme.zIndex.drawer + 1,
    margin: 0,
    top: 60,
    right: 8,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
  },

  avatar: {
    // minWidth: theme.spacing(6),
    width: 32, //48,
    height: 32, //48,
  },
  bigAvatar: {
    width: 72,
    height: 72,
  },
  button: {
    margin: theme.spacing(1),
  },
  displayName: {
    margin: theme.spacing(1),
  },
  lociAddress: {
    margin: theme.spacing(1),
  },
  badge: {
    colorSecondary: blue[400],
  },
  drawer: {
    backgroundColor: theme.palette.type === 'dark' ? '#121212' : "#FFF",
  },
}));

const ProfileDrawer = ({ open, onToggle, onNewProfileClick }) => {
  const classes = useStyles();
  const activeProfile = useSelector((state) =>
    state.profiles.get(state.session.profileID)
  );
  const profiles = useSelector((state) => state.profiles);
  const translate = useIntl();
  const width = useWidth();

  const dispatch = useDispatch();
  const switchProfile = useCallback(
    (profile) => dispatch(SESSION_FETCH_(profile.id)),
    [dispatch]
  );

  const allUnreadCounts = useSelector(state => selectAllNotificationCounts(state));

  if (!activeProfile) {
    return <div />;
  }

  const firstLetterOfAddress = activeProfile.name?.[0].toUpperCase() ?? '?';

  const otherProfiles = profiles.reduce(
    (acc, profile) =>
      profile.id === activeProfile.id ? acc : acc.push(profile),
    ImmutableList()
  );

  const profileList = otherProfiles.size ? (
    <List>
      {otherProfiles.map((profile) => {
        return (
          <NavItem
            key={profile.name}
            icon={
              <Badge
                badgeContent={
                  allUnreadCounts &&
                  allUnreadCounts.getIn([profile.id, 'count'])
                }
                overlap='circular'
                color='primary'
                max={999}
              >
                <ProfileAvatar profile={profile} className={classes.avatar} />
              </Badge>
            }
            onClick={() => switchProfile(profile)}
            primaryText={`@${profile.name}`}
          />
        );
      })}
    </List>
  ) : null;

  const handleClose = () => {
    onToggle(false);
  };

  return (
    <Drawer
      PaperProps={{className: classes.drawer}}
      anchor={isWidthDown('sm', width) ? 'bottom' : 'left'}
      open={open}
      onClose={handleClose}
    >
      <Box
        className={classes.panel}
        classes={{ paper: classes.drawerPaper }}
        tabIndex={0}
        role='button'
        onClick={handleClose}
        onKeyDown={handleClose}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Avatar
              alt={activeProfile.display_name}
              src={activeProfile.photo}
              className={classes.bigAvatar}
            >
              {firstLetterOfAddress}
            </Avatar>
          </Grid>
          <Grid item xs={8}>
            <Typography variant='h5' className={classes.displayName}>
              {activeProfile.display_name}
            </Typography>
            <Typography variant='h6' className={classes.lociAddress}>
              @{activeProfile.name}
            </Typography>
            {activeProfile.captain && (
              <Typography
                variant='caption'
                color='textSecondary'
                paragraph
                className={classes.lociAddress}
              >
                {translate.groups.captain}: @{activeProfile.captain}
              </Typography>
            )}
          </Grid>
        </Grid>
        <List>
          {profileList}
          <NavItem
            key='add-new-profile'
            icon={<PersonAddIcon className={classes.avatar} />}
            onClick={onNewProfileClick}
            primaryText={translate.nav.new_profile}
          />
        </List>
      </Box>
    </Drawer>
  );
};

export default ProfileDrawer;
