import React from 'react'
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import ResponsiveDialog from './ResponsiveDialog';
import ProfileList from './ProfileList';
import { useIntl } from '../hooks';

const ProfileListDialog = ({ onClose, profiles, title, ...props }) => {
  const translate = useIntl();
  return (
    <ResponsiveDialog {...props} title={title} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ProfileList profiles={profiles} />
      </DialogContent>
      <DialogActions>
        <Button
            onClick={onClose}
            color="default">
          {translate.dialog.close}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  )
};

export default ProfileListDialog;
