import { useState, useEffect } from 'react';
import { fetchQuery } from 'react-relay';
import { environment } from '../relay';
import useActiveFiatAssetsQuery from './useActiveFiatAssetsQuery';

export default function useActiveFiatAssets() {
  const [assets, setAssets] = useState(null);

  useEffect(() => {
    fetchQuery(environment, useActiveFiatAssetsQuery).subscribe({
      next: (data) => {
        setAssets(data?.assets?.nodes);
      },
    });
  }, []);

  return assets;
}
