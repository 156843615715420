import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useViewer } from '../hooks';

// TBD: Evolve to handle additional authorization models?

const OwnContext = createContext();

//
// The value set explicitly is either true, false, or undefined,
// and never null because null represents the anon viewer only.
//
// Internally value will thus be one of null, true, false, or number.
// If number, then it is the Profile rowId of the viewer.
//
const OwnProvider = ({value, children}) => {
  return value === undefined
  ?
  <OwnViewerProvider>{children}</OwnViewerProvider>
  :
  <OwnContext.Provider value={value}>{children}</OwnContext.Provider>
  ;
};

const OwnViewerProvider = ({children}) => {
  const value = useViewer()?.rowId ?? null; // ensure number or null
  return (
    <OwnContext.Provider value={value}>{children}</OwnContext.Provider>
  );
}

// What is is?
//
// value = null - viewer is the anonymous viewer
//         number - profile rowId of the viewer
//         true/false - set by parent Own.Provider, true = render children
//
const test = (of, value) =>
  /* eslint-disable eqeqeq */
  //
  // Simple context of <Own.Provider value={true|false}> where the context decides.
  //
  of === undefined ? (value === true ? true : value === false ? false :
    (()=>{
      const xtra = value === null ? "<Own> missing 'of' attribute?" : '';
      throw new Error(`unexpected Own.Provider context value ${value}.${xtra}`)
    })())
  :
  //
  // Compare to Profile.id on each use of <Own of={profileId|ObjectWithRowId|ObjectWithId}>
  // within the context of <Own.Provider>
  //
  typeof(of) === 'object' ? value === of.rowId || value === of.id
  :
  value === of // number (profile's rowId) or null (anonymous-only content)
  /* eslint-enable eqeqeq */
  ;

const Own = ({ and=true, anon, children, of, or=false, unless=false }) => {
  const value = useContext(OwnContext);
  if (value === undefined) {
    return <></>
  }
  return (
    /* eslint-disable no-mixed-operators */
    <>
      {(test(anon ? null : of, value) && !!and || !!or) && !unless ? children : null}
    </>
    /* eslint-enable no-mixed-operators */
  );
};

export const NotOwn = ({ and=true, anon, children, of, or=false, unless=false }) => {
  const value = useContext(OwnContext);
  if (value === undefined) {
    return <></>
  }
  return (
    /* eslint-disable no-mixed-operators */
    <>
      {(!test(anon ? null : of, value) && !!and || !!or) && !unless ? children : null}
    </>
    /* eslint-enable no-mixed-operators */
  );
};

export const Auth = ({ own, or, children }) => {
  const value = useContext(OwnContext);
  if (or) {
    return <>{children}</>;
  }
  if (value === null) {
    return <></>;
  }
  if (own === undefined) {
    return <>{children}</>;
  }
  if (value === own) {
    return <>{children}</>;
  }
  if (typeof(own) === 'object') {
    if (value === own.rowId || value === own.id) {
      return <>{children}</>;
    }
  }
  return <></>;
}

export const Anon = ({ children }) => {
  const value = useContext(OwnContext);
  if (value === null) {
    return <>{children}</>;
  }
  else {
    return <></>;
  }
}

OwnViewerProvider.propTypes = {
  children: PropTypes.any,
};

OwnProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.bool
};

Own.propTypes = {
  and: PropTypes.any,
  children: PropTypes.any,
  of: PropTypes.any,
  or: PropTypes.any,
  unless: PropTypes.any
};

NotOwn.propTypes = {
  and: PropTypes.any,
  children: PropTypes.any,
  of: PropTypes.any,
  or: PropTypes.any,
  unless: PropTypes.any
};

Own.Context = OwnContext;
Own.Provider = OwnProvider;
Own.Consumer = OwnContext.Consumer;

export default Own;
