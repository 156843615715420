import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Big } from 'big.js';
import { format } from '../utils/number';
import { useLang } from '../hooks';

Big.NE = -11; // exponent notation safely outside 8 decimal points

const useStyles = makeStyles((theme) => ({
  precise: {
    minWidth: theme.spacing(14),
  },
  ergonomic: {
    minWidth: theme.spacing(10),
  },
  price1: {
    position: 'absolute',
  },
  price2: {},
}));

const PrecisePrice = ({
  align,
  asset,
  // dispatch,
  code,
  isPrecise,
  value,
  color,
  suffix,
  ...props
}) => {
  const classes = useStyles();
  const locale = useLang();

  const [short, long] = format(value, asset, locale);
  const color1 = color === 'secondary' ? 'secondary' : 'textPrimary';
  const color2 = color === 'secondary' ? 'textSecondary' : 'textSecondary';

  const code_str = code ? `${code} ` : '';
  const suffix_str1 = suffix && !isPrecise ? ` ${suffix}` : '';
  const suffix_str2 = suffix && isPrecise ? ` ${suffix}` : '';

  // TODO: Get left-aligned with precision to work.
  //
  return (
    <Box
      className={isPrecise ? classes.precise : classes.ergonomic}
      align={isPrecise ? 'left' : align || 'left'}
    >
      <div>
        <Typography
          className={isPrecise ? classes.price1 : classes.price2}
          color={color1}
          {...props}
        >
          {code_str}
          {short}
          {suffix_str1}
        </Typography>
        {isPrecise && (
          <Typography className={classes.price2} color={color2} {...props}>
            {code_str}
            {long}
            {suffix_str2}
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default PrecisePrice;
