import React from 'react'
import { TextField } from '@material-ui/core'
import { Field, getIn } from 'formik'
import NumberFormat from '../NumberFormat'

const FormikNumberField = props => {
  const {
    disabled,
    form: { errors, isSubmitting, setFieldValue, touched },
    field
  } = props

  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return (
    <NumberFormat
        value={field.value}
        {...props}
        error={showError}
        helperText={showError ? fieldError : props.helperText}
        disabled={disabled || isSubmitting}
        customInput={TextField}
        onValueChange={({value}) => setFieldValue(field.name, value)}
    />
  )
}

export default props =>
    <Field type="text" component={FormikNumberField} {...props} />
