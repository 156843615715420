import React from 'react';
import { useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import {
  Button,
  LinearProgress,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import getTaxIDInfo from '../../data/getTaxIDInfo';
import getAccountNumberType from '../../data/getAccountNumberType';
import { useIntl, useApiService } from '../../hooks';
import { LinksApiService } from '../../services';

const features = {
  /*
   * traditional bank info
   */
  bank: {
    bank_routing_number: { LUSD: true, others: false },
    email: { LUSD: false, LMXN: false, others: true },
    tax_id: { LUSD: false, others: true },
    type_account: { LUSD: true, LARS: true, LVES: true, others: false },
  },
  /*
   * mobile bank info
   */
  mobile: {
    email: { others: true },
    tax_id: { others: true },
  },
};

// interface Values {
//   email: string;
// }

const AddBankDialog = ({
  asset,
  onClose,
  onError,
  onSubmit,
  variant,
  ...rest
}) => {
  const translate = useIntl();
  const profileId = useSelector(state => state.session.profileID);
  const linksApi = useApiService(LinksApiService);

  const hasFeature = (feature) => {
    const f = features[variant || 'bank'][feature];
    if (f === undefined) {
      return false;
    }
    const t = f[asset.code];
    return t === undefined ? f['others'] : t;
  };

  // hack the country code for TaxID info
  const country = asset.code.substr(1, 2);
  const taxIDInfo = getTaxIDInfo(country);
  const taxIDLabel =
    taxIDInfo.label ||
    `${taxIDInfo.person.label} / ${taxIDInfo.business.label}`;
  const taxIDHelp =
    taxIDInfo.mask || `${taxIDInfo.person.mask} / ${taxIDInfo.business.mask}`;
  const account_number_type =
    variant === 'mobile' ? 'mobile' : getAccountNumberType(country);
  const account_types = [
    {
      value: 'none',
      label: translate.addbank.account_type,
    },
    {
      value: 'checking',
      label: translate.addbank.checking,
    },
    {
      value: 'savings',
      label: translate.addbank.savings,
    },
  ];

  return (
    <Formik
      initialValues={{
        bank_name: '',
        beneficiary: '',
        bank_routing_number: '',
        account_number: '',
        type_account: 'none',
        email: '',
        tax_id: '',
        nickname: '',
      }}
      validate={(values) => {
        const errors /*: Partial<Values> */ = {};
        if (!values.bank_name) {
          errors.bank_name = translate.common.required;
        }
        if (!values.beneficiary) {
          errors.beneficiary = translate.common.required;
        }
        if (!values.bank_name) {
          errors.bank_name = translate.common.required;
        }
        if (hasFeature('bank_routing_number')) {
          if (!values.bank_routing_number) {
            errors.bank_routing_number = translate.common.required;
          } else if (!/^[0-9]{9}$/.test(values.bank_routing_number)) {
            errors.bank_routing_number = translate.invalidRoutingNumber;
          }
        }
        if (!values.account_number) {
          errors.account_number = translate.common.required;
        }
        if (hasFeature('type_account')) {
          if (values.type_account === 'none') {
            errors.type_account = translate.common.required;
          }
        }
        if (hasFeature('email')) {
          if (!values.email) {
            errors.email = translate.common.required;
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = translate.errors.invalid_email;
          }
        }
        if (hasFeature('tax_id')) {
          if (!values.tax_id) {
            errors.tax_id = translate.common.required;
          } else {
            if (taxIDInfo.regex) {
              if (!taxIDInfo.regex.test(values.tax_id)) {
                errors.tax_id = taxIDHelp;
              }
            } else {
              if (
                !taxIDInfo.person.regex.test(values.tax_id) &&
                !taxIDInfo.business.regex.test(values.tax_id)
              ) {
                errors.tax_id = taxIDHelp;
              }
            }
          }
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        linksApi.post({
          profile_id: profileId,
          asset_id: asset.id,
          account_number_type,
          ...values,
          type_account: hasFeature('type_account')
            ? values.type_account
            : variant
            ? variant
            : '',
        }).then((response) => {
          setSubmitting(false);
          response.ok ? onSubmit(response.bodyJson.link_id) : onError(response.bodyJson.error)
        });
      }}
      // eslint-disable-next-line no-unused-vars
      render={({ submitForm, isSubmitting, values, setFieldValue }) => (
        <Form>
          <Dialog {...rest}>
            <DialogTitle>{translate.addbank.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {translate.addbank.description}
              </DialogContentText>
              <Field
                type='text'
                label={translate.addbank.institution}
                name='bank_name'
                component={TextField}
              />
              <br />
              <Field
                type='text'
                label={translate.addbank.beneficiary}
                name='beneficiary'
                component={TextField}
              />
              <br />
              {hasFeature('bank_routing_number', asset) && (
                <>
                  <Field
                    type='text'
                    label={translate.addbank.routing_number}
                    name='bank_routing_number'
                    component={TextField}
                  />
                  <br />
                </>
              )}
              <Field
                type='text'
                label={translate._account_number_type[account_number_type]}
                name='account_number'
                component={TextField}
              />
              <br />
              {hasFeature('type_account') && (
                <>
                  <Field
                    type='text'
                    name='type_account'
                    select
                    margin='normal'
                    component={TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {account_types.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        disabled={option.value === 'none'}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                  <br />
                </>
              )}
              {hasFeature('email') && (
                <>
                  <Field
                    name='email'
                    type='email'
                    label={translate.common.email}
                    component={TextField}
                  />
                  <br />
                </>
              )}
              {hasFeature('tax_id') && (
                <>
                  <Field
                    name='tax_id'
                    type='text'
                    label={taxIDLabel}
                    component={TextField}
                  />
                  <br />
                </>
              )}
              <br />
              <Field
                type='text'
                label={translate.addbank.link_name}
                name='nickname'
                helperText={translate.addbank.link_name_helpertext}
                component={TextField}
              />
              {isSubmitting && <LinearProgress />}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color='primary'>
                {translate.dialog.cancel}
              </Button>
              <Button
                variant='contained'
                color='primary'
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {translate.dialog.submit}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    />
  );
};

export default AddBankDialog;
