import React, { useState } from 'react';
import { Button, Link } from '@material-ui/core';
import { OpenInNewIcon } from '../icons';
import DocumentAgreeDialog from './DocumentAgreeDialog';

// TODO: Open as popup dialog that can be easily dismissed once read.

const DocumentPop = ({
  field = undefined,
  form = undefined,
  document_role,
  document_id,
  caption,
}) => {
  const [clicked, setClicked] = useState(field?.value ?? false);
  const [open, setOpen] = useState(false);
  const color = clicked ? 'secondary' : 'primary';

  const handleEnterKeyOrClick = () => {
    setClicked(true);
    setOpen(true);
    form && form.setFieldValue(field.name, true);
  };

  const handleBlur = () => {
    field && field.onBlur(field.name);
  };

  return (
    <>
      <DocumentAgreeDialog
        document_role={document_role}
        document_id={document_id}
        open={open}
        onClose={() => setOpen(false)}
        onAgree={() => setOpen(false)}
        button_tag="common.ok"
      />
      <Link
        onClick={handleEnterKeyOrClick}
        rel='noreferrer noopener'
        underline='none'
      >
        <Button
            variant='outlined'
            color={color}
            endIcon={<OpenInNewIcon color={color} />}
            onKeyPress={(e) => e.charCode === 13 && handleEnterKeyOrClick()}
            onClick={handleEnterKeyOrClick}
            onBlur={handleBlur}
        >
          {caption}
        </Button>
      </Link>
    </>
  );
};

export default DocumentPop;
