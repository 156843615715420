import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query useAssetContentQuery($code: String!, $lang: String = "en") {
    assets: securitiesAssetByCode(code: $code) {
      classcontent(languageCode: $lang) {
        nodes {
          code
          contentName
          tag
          text
        }
      }
    }
  }
`;
