import { ofType } from 'redux-observable'
import { map } from 'rxjs'
import { Environment, Network, /* Observable, */ RecordSource, Store } from 'relay-runtime'
// import { SubscriptionClient } from 'subscriptions-transport-ws'
import { SESSION_FETCHED } from './session'

// action types
//
export const RELAY_INIT = 'RELAY:INIT'

// action creators
//
export const RELAY_INIT_ = session =>
  ({ type: RELAY_INIT, session })

const INITIAL_STATE = {
  environment: null
}

export const reduce = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  default:
    return state
  case RELAY_INIT:
    return ({
      ...state,
      environment: new_environment(action.session.gqApiUrl, action.session.getToken)
    })
  }
}

export const epics = [
  (action$, state$) => action$.pipe(
    ofType(SESSION_FETCHED),
    map(() => RELAY_INIT_(state$.value.session))
  )
]

// eslint-disable-next-line no-unused-vars
const new_environment = (url, getToken) => {
  /*
  const client = new SubscriptionClient(url, {reconnect: true})
  const subscribe = (request, variables) => Observable.from(
    client.request({
      query: request.text,
      operationName: request.name,
      variables
  }))
  */

  const fetchQuery = (operation, variables) => fetch('/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then(response => response.json())

  return new Environment({
    network: Network.create(fetchQuery/*, subscribe*/),
    store: new Store(new RecordSource())
  })
}
