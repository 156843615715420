export default function useFindCurrentOffers(props) {
  const results = [];
  const today = Date.now();
  const issues = props?.asset?.issues?.nodes;


  for (let i in issues) {
    const offers = issues[i].offers.nodes;
    for (let o in offers) {
      const offer = offers[o];
      const offerDate = Date.parse(offer.offerDate);
      const closeDate = Date.parse(offer.closeDate);
      if (offerDate <= today && today <= closeDate) {
        results.push(offer);
      }
    }
  }

  return results;
}
