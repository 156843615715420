/**
 * @generated SignedSource<<14a5d15f78d3300834f12bdefb94566b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HandleRequestActivityQuery$variables = {
  rowId: number;
};
export type HandleRequestActivityQuery$data = {
  readonly request: {
    readonly note: string | null;
    readonly status: string;
  } | null;
};
export type HandleRequestActivityQuery = {
  response: HandleRequestActivityQuery$data;
  variables: HandleRequestActivityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rowId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "transactionPtrId",
    "variableName": "rowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HandleRequestActivityQuery",
    "selections": [
      {
        "alias": "request",
        "args": (v1/*: any*/),
        "concreteType": "TransfersHold",
        "kind": "LinkedField",
        "name": "transfersHoldByTransactionPtrId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HandleRequestActivityQuery",
    "selections": [
      {
        "alias": "request",
        "args": (v1/*: any*/),
        "concreteType": "TransfersHold",
        "kind": "LinkedField",
        "name": "transfersHoldByTransactionPtrId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eebc0e40bb3d659ddfdc60067b0d0171",
    "id": null,
    "metadata": {},
    "name": "HandleRequestActivityQuery",
    "operationKind": "query",
    "text": "query HandleRequestActivityQuery(\n  $rowId: Int!\n) {\n  request: transfersHoldByTransactionPtrId(transactionPtrId: $rowId) {\n    note\n    status\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "38bb0ebee043ea6d85b28bb4954c6d9e";

export default node;
