import React from 'react'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'

const LinkInfo = ({link, translate}) => {
  return (
    ( link && (link.eftpush || (link.bank && link.bank.typeAccount) ) )
    ?
    <>
      <Typography variant="h6">
        {translate.views.link.type[link.eftpush ? 'eft' : link.bank.typeAccount].subtitle}
      </Typography>
      {
        link.eftpush && link.eftpush.institution
        ?
        /* eft push mint */
        <Typography>{link.eftpush.institution}</Typography>
        :
        link.bank && link.bank.typeAccount === 'paypal'
        ?
        /* paypal */
        (link.bank.accountNumber && <Typography>{link.bank.accountNumber}</Typography>)
        :
        link.bank && link.bank.typeAccount === 'virtual'
        ?
        /* cuit */
        (link.bank.accountNumber && <Typography>{link.bank.accountNumber}</Typography>)
        :
        link.bank && link.bank.typeAccount === 'mobile'
        ?
        /* mobile */
        <>
          { link.bank && link.bank.beneficiaryName && <Typography>{link.bank.beneficiaryName}</Typography> }
          { link.bank && link.bank.email && <Typography>{link.bank.email}</Typography> }
          { link.bank && link.bank.accountNumber && <Typography>{link.bank.accountNumber}</Typography> }
          { link.bank && link.bank.bankName && <Typography>{link.bank.bankName}</Typography> }
          { link.bank && link.bank.taxId && <Typography>{link.bank.taxId}</Typography> }
        </>
        :
        /* bank */
        <>
          { link.bank && link.bank.beneficiaryName && <Typography>{link.bank.beneficiaryName}</Typography> }
          { link.bank && link.bank.bankName && <Typography>{link.bank.bankName}</Typography> }
          { link.bank && link.bank.typeAccount && <Typography>{link.bank.typeAccount}</Typography> }
          { link.bank && link.bank.accountNumberType && <Typography>{link.bank.accountNumberType}</Typography> }
          { link.bank && link.bank.accountNumber && <Typography>{link.bank.accountNumber}</Typography> }
          { link.bank && link.bank.bankRoutingNumber && <Typography>{link.bank.bankRoutingNumber}</Typography> }
        </>
      }
    </>
    :
    <Typography>{translate.views.link.none}</Typography>
  )
}

const mapStateToProps = state => ({
  translate: state.view.translate,
})

export default connect(mapStateToProps)(LinkInfo)