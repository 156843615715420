import React from 'react';
import { CircularProgress, Select, MenuItem } from '@material-ui/core';
import { VIEW_SET_LANGUAGE_ } from '../state/view';
import { useAction, useLocales, useLang } from '../hooks';

const LanguageChoice = (props) => {
  const { userLocales: locales } = useLocales();
  const language = useLang();
  const setLanguage = useAction(VIEW_SET_LANGUAGE_);

  if (!locales) {
    return <CircularProgress />;
  }

  return (
    <Select
      value={language}
      onChange={(e) => setLanguage(e.target.value)}
      {...props}
    >
      {locales.map((locale) => (
        <MenuItem
          key={locale.code}
          value={locale.code}
          selected={locale.code === language}
        >
          {locale.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageChoice;
