import React from 'react';
import NumberFormat from './NumberFormat';
import { Box, Button, Card, CardMedia, Typography } from '@material-ui/core';
import { useLink } from '../hooks';

const Promo = ({ code, classcontent, image, property }) => {
  const pushLink = useLink();

  const assetContent = classcontent.nodes.reduce(
    (a, n) => ({ ...a, [n.contentName]: n.text }),
    {}
  );

  return (
    <Card raised>
      <CardMedia
        image={`/media/${image?.file?.path}`}
        title={assetContent.name}
        style={{ color: 'white' }}
      >
        <Box p={2} display='flex'>
          <Box flexBasis='67%'>
            <Typography variant='h6'>{assetContent.name}</Typography>
            {property && property.faceValue > 0 && (
              <Typography variant='h3'>
                {property.faceValueFiat.external.asset.symbol}
                <NumberFormat
                  value={property.faceValue}
                  displayType='text'
                  isNumericString={true}
                />
              </Typography>
            )}
            <Typography>{assetContent.caption || '.'}</Typography>
          </Box>
          <Box
            flexGrow='1'
            display='flex'
            alignItems='flex-end'
            justifyContent='flex-end'
          >
            <Box
              pr={{ xs: 1, sm: 2, md: 4, lg: 4, xl: 4 }}
              pb={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
            >
              <Button
                size='large'
                variant='contained'
                color='primary'
                onClick={() => pushLink(`/preview/${code}`)}
              >
                View Now
              </Button>
            </Box>
          </Box>
        </Box>
      </CardMedia>
    </Card>
  );
};

export default Promo;
