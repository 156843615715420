import { BaseApiService } from './BaseApiService';

class VenuesApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/venues/', access, onError);
  }

  postSearch({ name }) {
    return this.http.postFetch('search/', { name });
  }

  postSearch$({ name }) {
    return this.http.postAjax('search/', { name });
  }
}

export default VenuesApiService;
