import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  IconButton,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useError, useIntl, useViewer, useApiService } from '../hooks';
import { SettingsIcon as EditIcon } from '../icons';
import { MembershipApiService } from '../services';
import { Anon, Auth, NotOwn } from './Own';

const useStyles = makeStyles((theme) => ({
  perkInactive: {
    backgroundColor: theme.palette.divider,
  },
  perkActive: {},
  editButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

const Perk = ({ onChange, onClick, perk }) => {
  const t_ = useIntl();
  const classes = useStyles();
  const assets = useSelector((state) => state.assets);
  const membership = perk.is_member?.nodes[0] ?? null;
  const onError = useError();
  const viewerId = useViewer()?.rowId;
  const [total, setTotal] = useState(null);
  const location = useLocation();
  const membershipApi = useApiService(MembershipApiService);

  useEffect(() => {
    if (assets && perk.quote) {
      if (viewerId === perk.owner.rowId || perk.transparency !== 'secret') {
        const asset = assets.get(perk.quote.asset.code);
        if (asset) {
          setTotal(
            Math.round(
              perk.members?.nodes.reduce(
                (acc, node) =>
                  acc +
                    node.contribs?.nodes.reduce(
                      (acc, contrib) =>
                        acc +
                        parseFloat(contrib.transfer.quantity) /
                          10 ** asset.decimals,
                      0
                    ) ?? 0,
                0
              ) ?? 0
            )
          );
        }
      }
    }
  }, [assets, perk, viewerId]);

  const handleOptionChange = async (event, membership) => {
    const response = await membershipApi.post(membership.rowId, {
      [event.target.name]: event.target.checked,
    });
    if (response.ok) {
      onChange && onChange();
    } else {
      onError(response);
    }
  };

  return (
    <Paper
      className={perk.isActive ? classes.perkActive : classes.perkInactive}
    >
      <Box p={2} style={{ position: 'relative' }}>
        <Auth own={perk.owner}>
          <IconButton
            className={classes.editButton}
            color='primary'
            onClick={onClick}
            size='small'
          >
            <EditIcon fontSize='small' />
          </IconButton>
        </Auth>
        <Typography variant='h6'>{perk.name}</Typography>
        <Typography variant='caption'>
          {t_.groups.visibility[perk.visibility]},
          {t_.groups.identity[perk.identity]},
          {t_.groups.membership[perk.membership]}
        </Typography>
        <Typography variant='body2' paragraph>
          {perk.description}
        </Typography>
        {membership && (
          <Box p={1}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>
                {t_.membership.declaration}
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={membership.isVisibleToMembers}
                      onChange={(e) => handleOptionChange(e, membership)}
                      name='isVisibleToMembers'
                    />
                  }
                  label={
                    <Typography variant='caption'>
                      {t_.membership.isVisibleToMembers.description}
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={membership.isVisibleToAssociatedMembers}
                      onChange={(e) => handleOptionChange(e, membership)}
                      name='isVisibleToAssociatedMembers'
                    />
                  }
                  label={
                    <Typography variant='caption'>
                      {t_.membership.isVisibleToAssociatedMembers.description}
                    </Typography>
                  }
                />
              </FormGroup>
            </FormControl>
          </Box>
        )}
        {(membership !== 'none' || total > 0) && ( // show total in case membership setting changed after collecting
          <Auth
            own={perk.owner}
            or={total !== null && perk.transparency !== 'secret'}
          >
            <Typography
              variant='caption'
              color={total > 0 ? 'secondary' : 'textSecondary'}
            >
              {total} {t_.raised ?? 'Raised'}
            </Typography>
          </Auth>
        )}
        <Auth>
          <NotOwn of={perk.owner}>
            <Button
              fullWidth
              disabled={membership !== null}
              variant={membership === null ? 'contained' : 'outlined'}
              color={membership === null ? 'primary' : 'default'}
              onClick={onClick}
            >
              {membership === null ? t_.groups.join.cta : t_.groups.leave.cta}
            </Button>
          </NotOwn>
        </Auth>
        <Anon>
          <Link
            to={{ pathname: '/signup', state: { from: location } }}
            style={{ textDecoration: 'none' }}
          >
            <Button fullWidth variant='contained' color='primary'>
              {t_.groups.join.cta}
            </Button>
          </Link>
        </Anon>
      </Box>
    </Paper>
  );
};

export default Perk;
