import React from 'react';
import { Box } from '@material-ui/core';
import ProfileCard from './ProfileCard';

const ProfileList = ({ profiles }) => {
  return (
    <Box>
      {profiles?.map((profile, index) => (
        <Box pt={1} key={index}>
          <ProfileCard profile={profile} />
        </Box>
      ))}
    </Box>
  );
};

export default ProfileList;