import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { ContactUsIcon } from '../icons';
import { useIntl } from '../hooks';
import { useHelpStyles } from '../containers/Help';

const ContactUs = () => {
  const classes = useHelpStyles();
  const { help } = useIntl();
  const profile = useSelector(state => state.session.profile);

  const handleClick = (e) => {
    e.preventDefault();
    return window.location = (`mailto:hello@loci-mail.com?subject=General%20Question%20from%20Profile:%20@${profile.name}`);
  };

  return (
    <Grid container direction='row' alignItems='center'>
      <Grid item xs={1}>
        <ContactUsIcon />
      </Grid>
      <Grid item xs={11} className={classes.paddedBottom}>
        <Button onClick={handleClick}>{help.email_hello}</Button>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
