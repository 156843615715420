import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query AgentBankInfoQuery($filter: SecuritiesAgentbankaccountFilter!) {
    accounts: allSecuritiesAgentbankaccounts(filter: $filter) {
      nodes {
        accountNumber
        bankName
        cbu
        clabe
        email
        agency
        entity
        iban
        rowId
        routingNumber
        recipientAddress
        recipientAddressL2
        swift
        taxId
        toa
      }
    }
  }
`;
