import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Menu,
  makeStyles,
} from '@material-ui/core';
import { MoreVertIcon } from '../../icons';
import { formatAsset } from '../../utils/utils';
import { AssetImage } from '../../components';
import { useIntl } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  label: {
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
  },
  icon: {
    fill: theme.palette.background.paper,
  },
  listItemText: {
    padding: 0,
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  avatar: {
    marginRight: 16,
  },
}));

const AssetChooser = ({
  assets,
  selectedCode,
  nullChoice,
  labelAvailable,
  isMenuOpen,
  setMenuOpenState,
  onChange,
}) => {
  const classes = useStyles();
  const wallets = useSelector((state) => state.wallets);
  const translate = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (isMenuOpen !== undefined && !isMenuOpen) {
      setAnchorEl(null);
    }
  }, [isMenuOpen]);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);

    if (setMenuOpenState !== undefined) {
      setMenuOpenState(true);
    }
  };

  const handleMenuItemClick = (code) => {
    setAnchorEl(null);
    onChange(code);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (setMenuOpenState !== undefined) {
      setMenuOpenState(false);
    }
  };

  let selectedAsset = assets.get(selectedCode);
  let available = formatAsset(
    wallets.getIn([selectedCode, 'available'], 0),
    selectedAsset
  );

  return (
    <div className={classes.root}>
      <List component='nav'>
        <ListItem
          button
          aria-haspopup='true'
          aria-controls='dd-lock-menu'
          onClick={handleClickListItem}
          className={classes.noPadding}
        >
          <AssetImage
            component={Avatar}
            className={classes.avatar}
            src={selectedAsset ? selectedAsset.icon : ''}
            alt={selectedAsset ? selectedAsset.code : ''}
            useStatic={selectedAsset ? selectedAsset.use_static_icon : false}
          />
          <ListItemText
            className={classes.listItemText}
            primary={
              selectedAsset
                ? `${selectedAsset.code} - ${selectedAsset.name}`
                : '-'
            }
            secondary={
              labelAvailable
                ? `${available} ${translate.wallet.available}`
                : null
            }
          />
          <MoreVertIcon className={classes.icon} />
        </ListItem>
      </List>

      <Menu
        id='dd-lock-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {nullChoice ? (
          <MenuItem
            selected={selectedCode === ''}
            onClick={() => handleMenuItemClick('')}
            className={classes.itemText}
          >
            <Avatar className={classes.avatar} />
            {nullChoice}
          </MenuItem>
        ) : null}
        {Array.from(
          assets
            .map((asset) => (
              <MenuItem
                key={asset.code}
                selected={asset.code === selectedCode}
                onClick={() => handleMenuItemClick(asset.code)}
                className={classes.itemText}
              >
                <AssetImage
                  component={Avatar}
                  className={classes.avatar}
                  alt={asset.code}
                  src={asset.icon}
                  useStatic={asset.use_static_icon}
                />
                {asset.code + ' - ' + asset.name}
              </MenuItem>
            ))
            .values()
        )}
      </Menu>
    </div>
  );
};

AssetChooser.propTypes = {
  assets: PropTypes.object.isRequired,
  selectedCode: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool,
  setMenuOpenState: PropTypes.func,
  nullChoice: PropTypes.string,
  labelAvailable: PropTypes.bool,
};

export default AssetChooser;
