/**
 * @generated SignedSource<<8033f81598cc6029bd42728a4f01f9fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useAssetContentQuery$variables = {
  code: string;
  lang?: string | null;
};
export type useAssetContentQuery$data = {
  readonly assets: {
    readonly classcontent: {
      readonly nodes: ReadonlyArray<{
        readonly code: string | null;
        readonly contentName: string | null;
        readonly tag: string | null;
        readonly text: string | null;
      } | null>;
    };
  } | null;
};
export type useAssetContentQuery = {
  response: useAssetContentQuery$data;
  variables: useAssetContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  },
  {
    "defaultValue": "en",
    "kind": "LocalArgument",
    "name": "lang"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "code",
    "variableName": "code"
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "languageCode",
      "variableName": "lang"
    }
  ],
  "concreteType": "SecuritiesAssetClasscontentConnection",
  "kind": "LinkedField",
  "name": "classcontent",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SecuritiesAssetClasscontentRecord",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAssetContentQuery",
    "selections": [
      {
        "alias": "assets",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesAsset",
        "kind": "LinkedField",
        "name": "securitiesAssetByCode",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAssetContentQuery",
    "selections": [
      {
        "alias": "assets",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesAsset",
        "kind": "LinkedField",
        "name": "securitiesAssetByCode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "792f97ada895932dc8dc6a0181dc6925",
    "id": null,
    "metadata": {},
    "name": "useAssetContentQuery",
    "operationKind": "query",
    "text": "query useAssetContentQuery(\n  $code: String!\n  $lang: String = \"en\"\n) {\n  assets: securitiesAssetByCode(code: $code) {\n    classcontent(languageCode: $lang) {\n      nodes {\n        code\n        contentName\n        tag\n        text\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ef25809943ebaf0fd60e6ec990ff059";

export default node;
