import { useState, useEffect } from 'react';
import { fetchQuery } from 'react-relay';
import { environment } from '../relay';
import { useLang } from '.';
import useAssetExploreQuery from './useAssetExploreQuery';

export default function useAssetExplore() {
  const locale = useLang();
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchQuery(environment, useAssetExploreQuery, {
      language: locale,
    }).subscribe({
      next: (data) => {
        setData(data);
      },
    });
  }, [locale]);

  return data;
}
