import React from 'react';
import { Box, Typography } from '@material-ui/core';
import AlertDialog from './AlertDialog';
import { SuccessIcon } from '../icons';

const Success = ({ message, title, ...props }) => (
  <AlertDialog
    title={title}
    content={
      <>
        <Box p={4} align='center'>
          <SuccessIcon style={{ fontSize: 96 }} />
        </Box>
        <Box p={3} align='center'>
          <Typography>{message}</Typography>
        </Box>
      </>
    }
    variant='success'
    {...props}
  />
);

export default Success;
