import { createSelector } from 'reselect';

// Get total unread notifications in active user's other profiles

const selectOtherUnread = createSelector(
  (state) => state.profiles,
  (state) => state.session.profile.id,
  (profiles, activeProfileId) =>
    profiles.filter(p => p.id !== activeProfileId).reduce((acc, p) => acc + p.unread_notification_count, 0)
);

export default selectOtherUnread;
