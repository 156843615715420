import { createTheme, responsiveFontSizes } from '@material-ui/core';

export const make_theme = (type) =>
  responsiveFontSizes(
    createTheme({
      palette: {
        type,
        primary: {
          main: type === 'dark' ? '#FFAE94' : '#EB4F20',
          dark: type === 'dark' ? '#CA7E66' : '#B11100',
          light: type === 'dark' ? '#FFE0C5' : '#FF814D',
          contrastText: type === 'dark' ? '#121212' : '#FFF',
        },
        secondary: {
          main: type === 'dark' ? '#A5D6A7' : '#4CAF4F',
          dark: type === 'dark' ? '#75A478' : '#087F22',
          light: type === 'dark' ? '#D7FFD9' : '#80E27D',
          contrastText: type === 'dark' ? '#121212' : '#FFF',
        },
        background: {
          paper: type === 'dark' ? '#121212FF' : '#FFF',
          default: type === 'dark' ? '#121212FF' : '#FAFAFA',
        },
      },
      // props: {
      // },
      typography: {
        useNextVariants: true,
      },
      ...(type === 'light'
        ? {
            // overrides: {
            //   MuiTableRow: {
            //     whiteSpace: 'nowrap',
            //     '&:nth-of-type(odd)': {
            //       backgroundColor: 'rgb(251,251,251)',
            //     },
            //     '&:nth-of-type(even)': {
            //       backgroundColor: 'rgb(247,247,247)',
            //     },
            //   },
            // },
          }
        : {
            // night theme overrides
            overrides: {
              MuiPaper: {
                elevation0: {
                  backgroundColor: '#121212FF',
                },
                elevation1: {
                  backgroundColor: '#1D1D1DFF',
                },
                elevation2: {
                  backgroundColor: '#222222FF',
                },
                elevation3: {
                  backgroundColor: '#242424FF',
                },
                elevation4: {
                  backgroundColor: '#272727FF',
                },
                elevation5: {
                  backgroundColor: '#292929FF',
                },
                elevation6: {
                  backgroundColor: '#2C2C2CFF',
                },
                elevation7: {
                  backgroundColor: '#2D2D2DFF',
                },
                elevation8: {
                  backgroundColor: '#2E2E2EFF',
                },
                elevation9: {
                  backgroundColor: '#2F2F2FFF',
                },
                elevation10: {
                  backgroundColor: '#303030FF',
                },
                elevation11: {
                  backgroundColor: '#313131FF',
                },
                elevation12: {
                  backgroundColor: '#333333FF',
                },
                elevation13: {
                  backgroundColor: '#333333FF',
                },
                elevation14: {
                  backgroundColor: '#343434FF',
                },
                elevation15: {
                  backgroundColor: '#343434FF',
                },
                elevation16: {
                  backgroundColor: '#353535FF',
                },
                elevation17: {
                  backgroundColor: '#353535FF',
                },
                elevation18: {
                  backgroundColor: '#363636FF',
                },
                elevation19: {
                  backgroundColor: '#363636FF',
                },
                elevation20: {
                  backgroundColor: '#363636FF',
                },
                elevation21: {
                  backgroundColor: '#373737FF',
                },
                elevation22: {
                  backgroundColor: '#373737FF',
                },
                elevation23: {
                  backgroundColor: '#373737FF',
                },
                elevation24: {
                  backgroundColor: '#373737FF',
                },
              },
              MuiTableSortLabel: {
                root: {
                  color: 'rgba(255, 255, 255, 0.7)',
                  '&:hover': {
                    color: 'rgba(255, 255, 255, 1.0)',
                  },
                  '&:active': {
                    color: 'rgba(255, 255, 255, 1.0)',
                  },
                },
                active: {
                  color: 'rgba(255, 255, 255, 1.0)',
                },
              },
              // MuiTableRow: {
              //   whiteSpace: 'nowrap',
              //   '&:nth-of-type(odd)': {
              //     backgroundColor: 'rgb(50,50,50)',
              //   },
              //   '&:nth-of-type(even)': {
              //     backgroundColor: 'rgb(56,56,56)',
              //   },
              // },
              MuiTableCell: {
                root: {
                  borderBottom: '1px solid rgba(81, 81, 81, 1)',
                  padding: '0px 0px 0px 0px',
                  '&:first-child': {
                    //paddingLeft: '4px'
                  },
                  '&:last-child': {
                    paddingRight: '0px',
                  },
                },
                head: {
                  color: 'rgba(255, 255, 255, 0.7)',
                },
              },
            },
          }),
    })
  );
