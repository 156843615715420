import React from 'react';
import { DateTime } from 'luxon';
import { Box, Divider, makeStyles } from '@material-ui/core';
import { orange, green, grey, red } from '@material-ui/core/colors';
import {
  LogoIcon,
  BankIcon as MintIcon,
  BurnIcon,
  SendIcon,
  RequestIcon,
  TradeIcon,
  ExploreIcon,
  GameIcon,
} from '../icons';
import { PrecisePrice } from '../components';
import { useIntl } from '../hooks';

const SelectIcon = (Icon, className) => <Icon className={className} />;

const mapEntryToIcon = (entry, classes) =>
  SelectIcon(
    {
      load: MintIcon,
      cryptoload: MintIcon,
      mintfee: MintIcon,
      unload: BurnIcon,
      unloadhold: BurnIcon,
      unloadunhold: BurnIcon,
      order: TradeIcon,
      cancel: TradeIcon,
      closeorder: TradeIcon,
      trade: TradeIcon,
      assets: ExploreIcon,
      send: SendIcon,
      transfer: SendIcon,
      hold: RequestIcon,
      unhold: RequestIcon,
      talentgift: MintIcon,
      payout: GameIcon,
      move: SendIcon,
      issuesubscription: ExploreIcon,
    }[entry.type] || LogoIcon,
    entry.type === 'order'
      ? classes.orderIcon
      : entry.amount < 0
      ? classes.debitIcon
      : classes.creditIcon
  );

const useStyles = makeStyles((theme) =>
  theme.palette.type === 'light'
    ? {
        credit: {
          backgroundColor: green[50],
        },
        order: {
          backgroundColor: orange[50],
        },
        debit: {
          backgroundColor: red[50],
        },
        date: {
          backgroundColor: grey[100],
        },
      }
    : {
        creditIcon: {
          color: green[600],
        },
        orderIcon: {
          color: orange[600],
        },
        debitIcon: {
          color: red[600],
        },
        date: {
          backgroundColor: grey[700],
        },
      }
);

const LedgerEntry = ({ asset, entry, isPrecise }) => {
  const classes = useStyles();
  const translate = useIntl();

  return entry.amount ? (
    <Box
      className={
        entry.type === 'order'
          ? classes.order
          : entry.amount < 0
          ? classes.debit
          : classes.credit
      }
      p={0}
      display='flex'
      flexDirection='row'
      alignItems='center'
    >
      <Box p={1}>{mapEntryToIcon(entry, classes)}</Box>
      <Box p={1} flexGrow='1'>
        {translate.ledgeritem[entry.type] || entry.type}
      </Box>
      <Box p={1}>
        <PrecisePrice
          align='right'
          asset={asset}
          isPrecise={isPrecise}
          value={entry.amount}
        />
      </Box>
      <Box p={1}>
        <PrecisePrice
          align='right'
          asset={asset}
          isPrecise={isPrecise}
          value={entry.balance}
        />
      </Box>
    </Box>
  ) : (
    <Box className={classes.date} align='center'>
      <Divider />
      {entry.time.toLocaleString(DateTime.DATE_MED)}
    </Box>
  );
};

export default LedgerEntry;
