import { useState, useEffect } from 'react';
import { fetchQuery } from 'react-relay';
import { environment } from '../relay';
import useLocalesQuery from './useLocalesQuery';

export default function useLocales() {
  const [userLocales, setUserLocales] = useState(null);
  const [allLocales, setAllLocales] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchQuery(environment, useLocalesQuery).subscribe({
      start: () => {},
      complete: () => {},
      error: (err) => {
        setError(err);
      },
      next: (data) => {
        setUserLocales(data?.userLocales?.nodes);
        setAllLocales(data?.allLocales?.nodes);
      },
    });
  }, []);

  return { userLocales, allLocales, error };
}
