import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { markets } from 'liquibook-lib';
import {
  formatAsset,
  orderStatusCodeLabel,
  cancelStatusCodeLabel,
} from '../utils/utils';
import { format } from '../utils/dateWrappers';
import { ORDERS_CANCEL_ } from '../state/orders';
import OrdersToolbar from './OrdersToolbar';
import { VIEW_LAYOUT_SORT_CHANGE_ } from '../state/view';
import classNames from 'classnames';
import './animation.css';

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 280,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  checkbox: {
    height: '40px',
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {
    // must be blank to override color
  },
  cell: {
    paddingLeft: 8,
    paddingRight: 8,
    height: '40px',
  },
  animationSell: {
    animationName: 'orderTableSellGreenFrame',
    animationDuration: '0.5s',
  },
  animationBuy: {
    animationName: 'orderTableBuyRedFrame',
    animationDuration: '0.5s',
  },
  animationOpen: {
    animationName: 'orderTableYellowKeyFrame',
    animationDuration: '0.5s',
  },
});

function desc(a, b, orderBy) {
  let cb, ca;

  switch (orderBy) {
    case 'orderId':
      cb = parseInt(b.orderID);
      ca = parseInt(a.orderID);
      break;
    case 'buy':
      cb = markets.Side.BUY === b.side ? b.quantity : 0;
      ca = markets.Side.BUY === a.side ? a.quantity : 0;
      break;
    case 'sell':
      cb = markets.Side.SELL === b.side ? b.quantity : 0;
      ca = markets.Side.SELL === a.side ? a.quantity : 0;
      break;
    case 'remaining':
      cb = b.quantity - b.filled;
      ca = a.quantity - a.filled;
      break;
    case 'total':
      cb = b.filledCost ? b.filledCost : 0;
      ca = a.filledCost ? a.filledCost : 0;
      break;
    case 'avgfillprice':
      cb = b.filled === 0 ? 0 : b.filledCost / b.filled;
      ca = a.filled === 0 ? 0 : a.filledCost / a.filled;
      break;
    case 'type':
      cb = markets.OrderType[b.orderType];
      ca = markets.OrderType[a.orderType];
      break;
    case 'status':
      cb = b.status ? orderStatusCodeLabel(b.status) : null;
      ca = a.status ? orderStatusCodeLabel(a.status) : null;
      break;
    default:
      cb = b[orderBy];
      ca = a[orderBy];
      break;
  }

  if (cb < ca) {
    return -1;
  }
  if (cb > ca) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line no-unused-vars
const getSorting = (order, orderBy, side) =>
  order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);

const formatPrice = (market, val) =>
  market ? (val ? formatAsset(val, market.quote_asset) : '') : '';

const formatQty = (market, val) =>
  market ? (val ? formatAsset(val, market.base_asset) : '') : '';

const cols = [
  {
    id: 'time',
    align: 'left',
    label: 'time',
    val: (o) => o.order.timestamp,
  },
  {
    id: 'orderId',
    align: 'right',
    label: 'orderId',
    val: (o) => o.order.orderId,
  },
  {
    id: 'market',
    align: 'left',
    label: 'market',
    val: (o) => o.order.market,
  },
  {
    id: 'buy',
    align: 'right',
    label: 'BUY',
    val: (o) => (o.order.side === markets.Side.BUY ? o.order.price : null),
  },
  {
    id: 'sell',
    align: 'right',
    label: 'SELL',
    val: (o) => (o.order.side === markets.Side.SELL ? o.order.price : null),
  },
  {
    id: 'price',
    align: 'right',
    label: 'limit',
    val: (o) => o.order.price,
  },
  {
    id: 'filled',
    align: 'right',
    label: 'filled',
    wide: true,
    // eslint-disable-next-line no-unused-vars
    val: (o) => 0,
  },
  {
    id: 'remaining',
    align: 'right',
    label: 'remaining',
    wide: true,
    val: (o) => o.order.quantity,
  },
  {
    id: 'total',
    align: 'right',
    label: 'total',
    wide: true,
    val: (o) => o.order.price * o.order.quantity,
  },
  {
    id: 'avgfillprice',
    align: 'right',
    label: 'averagePrice',
    wide: true,
    // eslint-disable-next-line no-unused-vars
    val: (o) => null,
  },
  {
    id: 'type',
    align: 'left',
    label: 'type',
    wide: true,
    val: (o) => markets.OrderType.Name(o.order.orderType),
  },
  {
    id: 'status',
    align: 'left',
    label: 'status',
    // eslint-disable-next-line no-unused-vars
    val: (o) => null,
  },
];

class EnhancedTableHead extends Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      wide,
      translate,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {cols
            .filter((col) => wide || !col.wide)
            .map((col) => {
              return (
                <TableCell
                  key={col.id}
                  className={classes.cell}
                  align={col.align}
                  sortDirection={orderBy === col.id ? order : false}
                >
                  <Tooltip
                    title='Sort'
                    placement={
                      col.align === 'right' ? 'bottom-end' : 'bottom-start'
                    }
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === col.id}
                      direction={order}
                      onClick={this.createSortHandler(col.id)}
                    >
                      {translate[col.label]}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              );
            }, this)}

          <TableCell className={classes.cell} padding='checkbox'>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected > 0}
              onChange={onSelectAllClick}
              classes={{
                root: classes.checkbox,
                checked: classes.checked,
              }}
            />
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// eslint-disable-next-line no-class-assign
EnhancedTableHead = withStyles(styles)(EnhancedTableHead);

class OrdersTable extends Component {
  state = {
    selected: [],
    data: this.props.orders,
    previousData: this.props.orders,
    change: this.props.change,
    page: 0,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: 'time',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let order = nextProps.layout ? nextProps.layout.order : 'desc';
    let orderBy = nextProps.layout ? nextProps.layout.orderBy : 'time';
    this.setState({ order: order, orderBy: orderBy });
    if (nextProps.orders !== this.props.orders) {
      this.setState({
        data: nextProps.orders,
        previousData: this.props.orders,
      });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
    let sort = { order, orderBy, component: this.props.gridKey };
    this.props.saveSorts(sort);
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const { data } = this.state;
      let selected = [],
        index = 0;
      for (var i in data) {
        if (typeof data[i].order !== 'undefined') {
          if (
            data[i].order.status !== markets.OrderStatusCode.OS_CANCELED &&
            data[i].order.status !== markets.OrderStatusCode.OS_FILLED
          ) {
            selected[index] = data[i].order.clOrdID;
            index++;
          }
        }
      }
      this.setState({ selected: selected });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    // if order status is filled or canceled
    if (
      this.state.data[id].order.status ===
        markets.OrderStatusCode.OS_CANCELED ||
      this.state.data[id].order.status === markets.OrderStatusCode.OS_FILLED
    )
      return;

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleCancelOrders = () => {
    const { selected } = this.state;
    const { cancelOrders } = this.props;
    const { orders } = this.props;

    selected.forEach((clOrdID) => {
      cancelOrders(orders[clOrdID].order);
    });
    this.setState({ selected: [] });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;
  isNewOrder = (newOrders, id) => newOrders.indexOf(id) !== -1;

  checkToday = (orderDay) => {
    let day = new Date(orderDay * 1000);
    let today = new Date();
    return day.getFullYear() === today.getFullYear() &&
      day.getMonth() === today.getMonth() &&
      day.getDate() === today.getDate()
      ? true
      : false;
  };

  addOrderToArray = (arr, index, mData) => {
    if (arr[index].timestamp != null) {
      arr[index].time = mData.timestamp.seconds;
    }
    if (typeof mData.cancelStatus !== 'undefined') {
      arr[index].cancelStatus = mData.cancelStatus;
    }
  };

  render() {
    const { classes, locale, marketprops, change, size, translate } =
      this.props;
    const { data, previousData, order, orderBy, selected, rowsPerPage, page } =
      this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const wide = size === undefined || size !== 'small';

    let index = 0,
      arrOrders = [];
    for (var i in data) {
      if (typeof data[i].order !== 'undefined') {
        arrOrders[index] = data[i].order;
        this.addOrderToArray(arrOrders, index, data[i].order);
        index++;
      }
    }

    return (
      <Paper className={classes.root}>
        <OrdersToolbar
          selected={selected}
          cancelOrders={this.handleCancelOrders}
        />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
          >
            <EnhancedTableHead
              translate={translate}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={arrOrders.length}
              wide={wide}
            />
            <TableBody>
              {stableSort(arrOrders, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => {
                  n.id = n.clOrdID;

                  const orderId = n.orderID === 0 ? '' : n.orderID;
                  const isSelected = this.isSelected(n.id);
                  const market = marketprops[n.market];
                  const buy = n.side === markets.Side.BUY ? n.quantity : null;
                  const sell = n.side === markets.Side.SELL ? n.quantity : null;
                  const total = n.filledCost ? n.filledCost : 0;
                  const filled = n.filled ? n.filled : 0;
                  const remaining = n.quantity - n.filled;
                  const avgfillprice =
                    n.filled === 0
                      ? 0
                      : (
                          (10 ** (market ? market.base_asset.decimals : 0) *
                            n.filledCost) /
                          n.filled
                        ).toFixed(market ? market.base_asset.decimals : 0);
                  const type = markets.OrderType[n.orderType];
                  let status = n.status ? orderStatusCodeLabel(n.status) : null;
                  if (n.status === markets.OrderStatusCode.OS_PARTIAL_FILL) {
                    var percent = ((n.filled / n.quantity) * 100).toFixed(0);
                    status = percent + '%';
                  }
                  if (
                    typeof n.cancelStatus !== 'undefined' &&
                    n.status !== markets.OrderStatusCode.OS_CANCELED
                  ) {
                    let cancelStatus =
                      ' CANCEL ' + cancelStatusCodeLabel(n.cancelStatus.code);
                    status = status + cancelStatus;
                  }

                  let date;
                  if (n.timestamp) {
                    date = new Date();
                    date.setTime(n.timestamp.seconds * 1000);
                    // date = moment(date).format('YYYY-MM-DD HH:mm:ss')
                    date = format(date, 'yyyy-MM-dd HH:mm:ss', locale);
                  }

                  let isOpen = false;
                  let isFilled = false;

                  const isChangedOrder =
                    change !== null && change.order.id === n.id;

                  if (isChangedOrder) {
                    if (previousData[n.id] === undefined) {
                      isOpen = n.status === markets.OrderStatusCode.OS_OPEN;
                      isFilled =
                        n.status === markets.OrderStatusCode.OS_FILLED ||
                        n.status === markets.OrderStatus.OS_PARTIAL_FILL;
                    } else {
                      if (previousData[n.id].status !== change.order.status) {
                        isOpen =
                          change.order.status ===
                          markets.OrderStatusCode.OS_OPEN;
                        isFilled =
                          change.order.status ===
                            markets.OrderStatusCode.OS_FILLED ||
                          n.status === markets.OrderStatus.OS_PARTIAL_FILL;
                      }
                    }
                  }

                  return (
                    <TableRow
                      hover
                      onClick={(event) => this.handleClick(event, n.id)}
                      role='checkbox'
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                      className={classNames(
                        classes.cell,
                        isChangedOrder && isOpen && classes.animationOpen,
                        isChangedOrder &&
                          isFilled &&
                          n.side === markets.Side.BUY &&
                          classes.animationSell,
                        isChangedOrder &&
                          isFilled &&
                          n.side === markets.Side.SELL &&
                          classes.animationBuy
                      )}
                    >
                      <TableCell className={classes.cell}>{date}</TableCell>

                      <TableCell className={classes.cell} align='right'>
                        {orderId}
                      </TableCell>

                      <TableCell
                        className={classes.cell}
                        component='th'
                        scope='row'
                      >
                        {n.market}
                      </TableCell>

                      <TableCell className={classes.cell} align='right'>
                        {formatQty(market, buy)}
                      </TableCell>
                      <TableCell className={classes.cell} align='right'>
                        {formatQty(market, sell)}
                      </TableCell>
                      <TableCell className={classes.cell} align='right'>
                        {formatPrice(market, n.price)}
                      </TableCell>
                      {wide && (
                        <TableCell className={classes.cell} align='right'>
                          {formatQty(market, filled)}
                        </TableCell>
                      )}
                      {wide && (
                        <TableCell className={classes.cell} align='right'>
                          {formatQty(market, remaining)}
                        </TableCell>
                      )}
                      {wide && (
                        <TableCell className={classes.cell} align='right'>
                          {formatPrice(market, total)}
                        </TableCell>
                      )}
                      {wide && (
                        <TableCell className={classes.cell} align='right'>
                          {formatPrice(market, avgfillprice)}
                        </TableCell>
                      )}
                      {wide && (
                        <TableCell className={classes.cell}>{type}</TableCell>
                      )}
                      <TableCell className={classes.cell}>{status}</TableCell>
                      <TableCell className={classes.cell} padding='checkbox'>
                        {n.status === markets.OrderStatusCode.OS_CANCELED ||
                        n.status === markets.OrderStatusCode.OS_FILLED ||
                        n.status === markets.OrderStatusCode.OS_REJECTED ||
                        typeof n.cancelStatus !== 'undefined' ? null : (
                          <Checkbox
                            checked={isSelected}
                            classes={{
                              root: classes.checkbox,
                              checked: classes.checked,
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 40 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component='div'
          count={arrOrders.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          page={page}
          backIconButtonProps={{
            'aria-label': translate.orders.prev_page,
          }}
          nextIconButtonProps={{
            'aria-label': translate.orders.next_page,
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

OrdersTable.propTypes = {
  classes: PropTypes.object.isRequired,
  cancelOrders: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => ({
  orders: state.orders.all.toJS(),
  change: state.orders.change,
  locale: state.view.translate._lang,
  marketprops: state.marketprops,
  market: state.view.market,
  ordersFilters: state.filterMenus.OrdersFilters,
  translate: state.view.translate,
  layout: state.view.layout.get(props.gridKey),
});

const mapDispatchToProps = (dispatch) => ({
  cancelOrders: (order) => dispatch(ORDERS_CANCEL_(order)),
  saveSorts: (sort) => dispatch(VIEW_LAYOUT_SORT_CHANGE_(sort)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrdersTable));
