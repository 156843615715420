import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { List, Map } from 'immutable';
import {
  Grid,
  Card,
  IconButton,
  Input,
  InputAdornment,
  LinearProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  selectAvailableWants,
  selectWants,
  selectTradableAssets,
  selectMarketAssets,
} from '../selectors/convertables';
import { mfv, pv2f } from '../utils/utils';
import { MAXIcon } from '../icons';
import AssetInput from './asset/Input';
import AssetChooser from './asset/Chooser';

const styles = (theme) => ({
  root: {},
  nowrap: {
    whiteSpace: 'nowrap',
  },
  adornmentButton: {
    marginLeft: -12,
    marginBottom: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(2),
  },
});

class RateSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variant: props.variant,
      market: null,
      want: null,
      have: null,
      customRate: false, // default to MAX rate
      haveRate: '',
      haveValue: null,
      wantRate: '',
      wantValue: null,
      give: '',
      giveValue: null,
      take: '',
      takeValue: null,
      constWant: true, // which to hold constant when rate changes
      submitCount: 0,
      wantMenuOpenState: false,
      haveMenuOpenState: false,
      focus: '',
    };
  }

  static deriveHaveAssets(want, props) {
    const { assets, wants, wallets } = props;
    if (props.variant === 'max') {
      let haves = wants.get(want.code) || List();
      return haves.reduce(
        (acc, have) =>
          have.buyWith
            ? acc.set(have.buyWith, assets.get(have.buyWith))
            : acc.set(have.sell, assets.get(have.sell)),
        Map()
      );
    } else if (props.variant === 'private') {
      return wallets.reduce(
        (acc, wallet, code) =>
          code !== want.code && wallet.available
            ? acc.set(code, assets.get(code))
            : acc,
        Map()
      );
    } /* street */ else {
      //
      // Only allow combinations that include a NetworkAsset on either side of a trade.
      //
      if (want && want.taxonomy[0] === 'networkasset') {
        return assets.reduce(
          (acc, asset, code) =>
            asset.taxonomy[0] === 'networkasset' || code === 'BTC'
              ? acc
              : acc.set(code, asset),
          Map()
        );
      } else {
        return wallets.reduce((acc, wallet, code) => {
          const asset = assets.get(code);
          return asset.taxonomy[0] === 'networkasset' /*&& wallet.available*/
            ? acc.set(code, asset)
            : acc;
        }, Map());
      }
    }
  }

  static getMarketRates(market, willBuy) {
    let rate = pv2f(mfv(market, willBuy ? 'ask' : 'bid'), market);
    if (rate === null) {
      return {
        haveRate: '',
        wantRate: '',
      };
    }
    if (willBuy) {
      return {
        haveRate:
          Math.round(10 ** market.quote_asset.decimals / rate) /
          10 ** market.quote_asset.decimals,
        wantRate: rate,
      };
    } else {
      return {
        haveRate: rate,
        wantRate:
          Math.round(10 ** market.quote_asset.decimals / rate) /
          10 ** market.quote_asset.decimals,
      };
    }
  }

  static deduceMarket(props, have, want) {
    const { variant, markets } = props;
    if (!want || !have) {
      return {};
    }
    let willBuy;
    let market = markets[`${want.code}|${have.code}`];
    if (market) {
      willBuy = true;
    } else {
      willBuy = false; // will sell
      market = markets[`${have.code}|${want.code}`];
      if (!market) {
        if (variant === 'max') {
          return {
            market: null,
            willBuy: false,
            haveRate: '',
            wantRate: '',
          };
        } else {
          // mash up a market that corresponds to the one the user wants
          const wantNetworkAsset = want.taxonomy[0] === 'networkasset';
          const market = {
            base_asset: wantNetworkAsset ? want : have,
            quote_asset: wantNetworkAsset ? have : want,
            quote: {},
          };
          return {
            market,
            willBuy: wantNetworkAsset,
            customRate: true,
            haveRate: '',
            wantRate: '',
          };
        }
      }
    }
    return {
      market,
      willBuy,
      ...RateSelector.getMarketRates(market, willBuy),
    };
  }

  static getDerivedStateFromProps(props, state) {
    let newState = {};

    if (state.submitCount !== props.submitCount) {
      // reset numeric fields when order is submitted
      newState = {
        submitCount: props.submitCount,
        customRate: false,
        give: '',
        take: '',
        qty: 0,
      };
    }

    if (state.have === null || state.want === null) {
      if (!props.wants.isEmpty()) {
        // || props.variant !== 'max') {
        newState = {
          ...newState,
          ...RateSelector.deriveHaveWant(state.have, state.want, props),
        };
      }
    }

    if (state.variant !== props.variant) {
      newState = {
        ...newState,
        ...RateSelector.deriveHaveWant(state.have, state.want, props),
        variant: props.variant,
      };
      return newState; // avoiding next conditional from using old market
    }

    if (props.variant === 'max' && state.market) {
      let market = props.markets[state.market.symbol];
      if (state.market !== market) {
        // likely a market.quote update
        newState = { ...newState, market };
        if (!state.customRate) {
          const { haveRate, wantRate } = RateSelector.getMarketRates(
            market,
            state.willBuy
          );
          newState = {
            ...newState,
            ...RateSelector.deriveGiveTake(
              market,
              state.willBuy,
              haveRate,
              wantRate,
              state.constWant,
              state.give,
              state.take,
              props.wallets
            ),
          };
        }
      }
    }

    return Object.keys(newState).length ? newState : null;
  }

  deriveFromHaveWant = (have, want) =>
    RateSelector.deriveHaveWant(have, want, this.props);

  static deriveHaveWant = (have, want, props) => {
    const { assets, assetLocal, assetHint, variant } = props;
    if (variant !== 'street') {
      if (want === null || !assets.get(want.code)) {
        want = assets.get(assetHint) || null;
      }
    }
    let haveAssets = RateSelector.deriveHaveAssets(want, props);
    if (haveAssets.isEmpty()) {
      want = assets.get(assetHint) || null;
      haveAssets = RateSelector.deriveHaveAssets(want, props);
    }
    if (have && haveAssets.get(have.code)) {
      //
      // Since the current Have is compatible with the new Want choice
      // leave the Have as-is and only set the new Want.
      //
    } else {
      //
      // Pick a new default Have choice, first trying props.assetLocal,
      // then tying the hint, and finally picking a random convertable from haveAssets.
      //
      const hint =
        variant !== 'street'
          ? assetHint
          : want && want.taxonomy === 'networkasset'
          ? assetHint.substr(1)
          : assetHint;
      have =
        haveAssets.get(assetLocal) ||
        haveAssets.get(hint) ||
        haveAssets.find(() => true) ||
        null;
    }
    const newState = {
      ...RateSelector.deduceMarket(props, have, want),
      have,
      want,
    };
    return newState;
  };

  updateWantMenuOpenState = (isOpen) => {
    this.setState({ wantMenuOpenState: isOpen });
  };

  handleWantChange = (assetCode) => {
    const { assets } = this.props;
    const want = assets.get(assetCode);
    let newState = this.deriveFromHaveWant(this.state.have, want);
    const customRate = !newState.wantRate; // must be custom if no MAX market
    if (!this.state.have || newState.have.code !== this.state.have.code) {
      //
      // clear inputs if both assets changed
      //
      this.setState({
        ...newState,
        give: '',
        take: '',
        customRate,
        wantMenuOpenState: false,
        haveMenuOpenState: false,
      });
    } else {
      //
      // otherwise recompute from exchange rate
      //
      this.setState({
        ...newState,
        ...RateSelector.deriveGiveTake(
          newState.market,
          newState.willBuy,
          newState.haveRate,
          newState.wantRate,
          false,
          this.state.give,
          this.state.take,
          this.props.wallets
        ),
        customRate,
        wantMenuOpenState: false,
        haveMenuOpenState: false,
      });
    }
  };

  updateHaveMenuOpenState = (isOpen) => {
    this.setState({ haveMenuOpenState: isOpen });
  };

  handleHaveChange = (assetCode) => {
    const { assets } = this.props;
    const have = assets.get(assetCode);
    let newState = this.deriveFromHaveWant(have, this.state.want);
    const customRate = !newState.haveRate; // must be custom if no MAX market
    if (newState.want.code !== this.state.want.code) {
      //
      // clear inputs if both assets changed
      //
      this.setState({
        ...newState,
        give: '',
        take: '',
        customRate,
        wantMenuOpenState: false,
        haveMenuOpenState: false,
      });
    } else {
      //
      // otherwise recompute from exchange rate
      //
      this.setState({
        ...newState,
        ...RateSelector.deriveGiveTake(
          newState.market,
          newState.willBuy,
          newState.haveRate,
          newState.wantRate,
          true,
          this.state.give,
          this.state.take,
          this.props.wallets
        ),
        customRate,
        wantMenuOpenState: false,
        haveMenuOpenState: false,
      });
    }
  };

  toggleCustomRate = () => {
    const { market, willBuy, constWant, customRate, give, take } = this.state;
    const { haveRate, wantRate } = RateSelector.getMarketRates(market, willBuy);
    this.setState({
      customRate: !customRate,
      ...RateSelector.deriveGiveTake(
        market,
        willBuy,
        haveRate,
        wantRate,
        constWant,
        give,
        take,
        this.props.wallets
      ),
    });
  };

  static deriveGiveTake(
    market,
    willBuy,
    haveRate,
    wantRate,
    constWant,
    give,
    take,
    wallets
  ) {
    let qty, have, haveDecimals, want;
    if (constWant) {
      if (willBuy) {
        // limit is wantRate
        qty = Math.round(10 ** market.base_asset.decimals * parseFloat(take));
        give = Math.ceil(qty * wantRate) / 10 ** market.base_asset.decimals;
        have = market.quote_asset;
        haveDecimals = market.quote_asset.decimals;
        want = market.base_asset;
      } else {
        // limit is haveRate
        qty = Math.ceil(
          10 ** market.base_asset.decimals * parseFloat(take) * wantRate
        );
        give = qty / 10 ** market.base_asset.decimals;
        have = market.base_asset;
        haveDecimals = market.base_asset.decimals;
        want = market.quote_asset;
      }
    } else {
      if (willBuy) {
        // limit is wantRate
        qty = Math.floor(
          10 ** market.base_asset.decimals * parseFloat(give) * haveRate
        );
        take = qty / 10 ** market.base_asset.decimals;
        have = market.quote_asset;
        haveDecimals = market.quote_asset.decimals;
        want = market.base_asset;
      } else {
        // limit is haveRate
        qty = Math.floor(10 ** market.base_asset.decimals * parseFloat(give));
        take = Math.floor(qty * haveRate) / 10 ** market.base_asset.decimals;
        have = market.base_asset;
        haveDecimals = market.base_asset.decimals;
        want = market.quote_asset;
      }
    }
    let insufficientFunds;
    if (
      /* variant === 'street' instead of */ want.taxonomy[0] ===
        'networkasset' &&
      have.taxonomy[0] !== 'networkasset'
    ) {
      insufficientFunds = false;
    } else {
      const wallet = wallets.get(have.code);
      insufficientFunds =
        give > (wallet ? wallet.available : 0) / 10 ** haveDecimals;
    }
    return {
      haveRate,
      wantRate,
      constWant,
      give,
      take,
      qty,
      insufficientFunds,
    };
  }

  handleFocus = (focus) => {
    this.setState({ focus });
  };

  handleCustomHaveRateChange = ({ floatValue, value }) => {
    const { focus, market, willBuy, constWant, give, take } = this.state;
    if (focus === 'have-rate') {
      let wantRate =
        Math.round(10 ** market.quote_asset.decimals / floatValue) /
        10 ** market.quote_asset.decimals;
      this.setState({
        haveValue: value,
        wantValue: null,
        ...RateSelector.deriveGiveTake(
          market,
          willBuy,
          floatValue,
          wantRate,
          constWant,
          give,
          take,
          this.props.wallets
        ),
      });
    }
  };

  handleCustomWantRateChange = ({ floatValue, value }) => {
    const { focus, market, willBuy, constWant, give, take } = this.state;
    if (focus === 'want-rate') {
      let haveRate =
        Math.round(10 ** market.quote_asset.decimals / floatValue) /
        10 ** market.quote_asset.decimals;
      this.setState({
        haveValue: null,
        wantValue: value,
        ...RateSelector.deriveGiveTake(
          market,
          willBuy,
          haveRate,
          floatValue,
          constWant,
          give,
          take,
          this.props.wallets
        ),
      });
    }
  };

  handleChangeGive = ({ floatValue, value }) => {
    const { focus, market, willBuy, haveRate, wantRate, take } = this.state;
    if (focus === 'give') {
      this.setState({
        giveValue: value,
        takeValue: null,
        ...RateSelector.deriveGiveTake(
          market,
          willBuy,
          haveRate,
          wantRate,
          false,
          floatValue,
          take,
          this.props.wallets
        ),
      });
    }
  };

  handleChangeTake = ({ floatValue, value }) => {
    const { focus, market, willBuy, haveRate, wantRate, give } = this.state;
    if (focus === 'take') {
      this.setState({
        giveValue: null,
        takeValue: value,
        ...RateSelector.deriveGiveTake(
          market,
          willBuy,
          haveRate,
          wantRate,
          true,
          give,
          floatValue,
          this.props.wallets
        ),
      });
    }
  };

  getOrder = () => {
    const {
      market,
      willBuy,
      qty,
      haveRate,
      wantRate,
      give,
      take,
      insufficientFunds,
    } = this.state;
    if (insufficientFunds || haveRate === Infinity || wantRate === Infinity) {
      return null;
    }
    if (willBuy) {
      if (qty && wantRate) {
        const price = Math.floor(wantRate * 10 ** market.quote_asset.decimals);
        const cost = Math.ceil(give * 10 ** market.quote_asset.decimals);
        return { market, buy: willBuy, quantity: qty, price, cost };
      } else {
        return null;
      }
    } else {
      if (qty && haveRate) {
        const price = Math.floor(haveRate * 10 ** market.quote_asset.decimals);
        const cost = Math.ceil(take * 10 ** market.quote_asset.decimals);
        return { market, buy: willBuy, quantity: qty, price, cost };
      } else {
        return null;
      }
    }
  };

  componentDidUpdate() {
    const { onChange } = this.props;
    const { market } = this.state;
    onChange &&
      onChange({
        baseAsset: market && market.base_asset,
        quoteAsset: market && market.quote_asset,
        order: this.getOrder(),
      });
  }

  render() {
    const {
      variant,
      classes,
      assets,
      marketAssets,
      wants,
      profile,
      translate,
    } = this.props;

    const {
      market,
      willBuy,
      have,
      want,
      customRate,
      haveRate,
      haveValue,
      wantRate,
      wantValue,
      give,
      giveValue,
      take,
      takeValue,
      insufficientFunds,
      wantMenuOpenState,
      haveMenuOpenState,
    } = this.state;

    if (
      !profile ||
      !want ||
      assets.isEmpty() ||
      marketAssets.isEmpty() ||
      wants.isEmpty()
    ) {
      return <LinearProgress />;
    }

    let haveRateUnits = '',
      wantRateUnits = '';
    if (market) {
      if (willBuy) {
        wantRateUnits =
          market && market.quote_asset ? market.quote_asset.code : '';
        haveRateUnits =
          market && market.base_asset ? market.base_asset.code : '';
      } else {
        wantRateUnits =
          market && market.base_asset ? market.base_asset.code : '';
        haveRateUnits =
          market && market.quote_asset ? market.quote_asset.code : '';
      }
    }

    const haveAssets = RateSelector.deriveHaveAssets(want, this.props);

    return (
      <div className={classNames(this.props.className, classes.root)}>
        <Grid container spacing={2} direction='row'>
          <Grid item sm={6} xs={12}>
            <Card className={classes.card}>
              <Typography variant='h5'>{translate.request.want}</Typography>
              <AssetChooser
                labelAvailable={
                  want && want.taxonomy[0] === 'networkasset'
                    ? translate.pocket.current_balance
                    : null
                }
                assets={variant === 'max' ? marketAssets : assets}
                selectedCode={want.code}
                onChange={this.handleWantChange}
                isMenuOpen={wantMenuOpenState}
                setMenuOpenState={this.updateWantMenuOpenState}
              />
              <AssetInput
                id='want-rate'
                label={
                  customRate
                    ? translate.express.rate.custom
                    : translate.express.rate.dnx
                }
                asset={have}
                onChange={this.handleCustomWantRateChange}
                margin='normal'
                value={wantValue || wantRate}
                onFocus={() => this.handleFocus('want-rate')}
                disabled={!customRate}
                InputProps={{
                  className: classes.nowrap,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton
                        aria-label='Toggle custom rate'
                        className={classes.adornmentButton}
                        onClick={this.toggleCustomRate}
                      >
                        {customRate ? <Input fontSize='small' /> : <MAXIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      {wantRateUnits}
                    </InputAdornment>
                  ),
                  disableUnderline: !customRate,
                }}
              />
              <AssetInput
                id='amount-to-receive'
                label={translate.request.receive_amount}
                asset={want}
                onChange={this.handleChangeTake}
                margin='normal'
                value={takeValue === null ? take : takeValue}
                onFocus={() => this.handleFocus('take')}
                error={insufficientFunds}
                disabled={wantRate === ''}
              />
            </Card>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card className={classes.card}>
              <Typography variant='h5'>{translate.request.have}</Typography>
              {haveAssets.isEmpty() ? (
                <Typography>Insufficient Asset Balance</Typography>
              ) : (
                <AssetChooser
                  labelAvailable={
                    have && have.taxonomy[0] === 'networkasset'
                      ? translate.pocket.current_balance
                      : null
                  }
                  assets={haveAssets}
                  selectedCode={have.code}
                  onChange={this.handleHaveChange}
                  isMenuOpen={haveMenuOpenState}
                  setMenuOpenState={this.updateHaveMenuOpenState}
                />
              )}
              <AssetInput
                id='have-rate'
                label={
                  customRate
                    ? translate.express.rate.custom
                    : translate.express.rate.dnx
                }
                asset={want}
                onChange={this.handleCustomHaveRateChange}
                margin='normal'
                value={haveValue || haveRate}
                onFocus={() => this.handleFocus('have-rate')}
                disabled={!customRate}
                InputProps={{
                  className: classes.nowrap,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IconButton
                        aria-label='Toggle custom rate'
                        className={classes.adornmentButton}
                        onClick={this.toggleCustomRate}
                      >
                        {customRate ? <Input fontSize='small' /> : <MAXIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      {haveRateUnits}
                    </InputAdornment>
                  ),
                  disableUnderline: !customRate,
                }}
              />
              <AssetInput
                id='amount-to-exchange'
                label={translate.request.send_amount}
                asset={have}
                onChange={this.handleChangeGive}
                margin='normal'
                value={giveValue === null ? give : giveValue}
                onFocus={() => this.handleFocus('give')}
                error={insufficientFunds}
                disabled={haveRate === ''}
              />
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    assets:
      ownProps.variant === 'street'
        ? state.assets
        : selectTradableAssets(state),
    markets: state.markets,
    marketAssets: selectMarketAssets(state),
    wants:
      ownProps.filter === 'available' || ownProps.variant === 'private'
        ? selectAvailableWants(state)
        : selectWants(state),
    wallets: state.wallets,
    translate: state.view.translate,
    profile: state.session.profile,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(RateSelector);
