import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { MQTT_SEND_, MQTT_SUBSCRIBE_ } from '../state/mqtt';

export default function useMQTT() {
  const dispatch = useDispatch();
  const publish = useCallback((topic, message) => dispatch(MQTT_SEND_(topic, JSON.stringify(message))), [
    dispatch
  ]);

  // TODO: Bind a callback sink with some kind of reference counting scheme and a cleanup function.
  // TBD: How do we cleanup the subscription sink when the client component is unmounted?
  //      The answer to this question forms the basis for a new kind of event flow tree.
  //
  // eslint-disable-next-line no-unused-vars
  const subscribe = useCallback((topic, sink) => dispatch(MQTT_SUBSCRIBE_(topic)), [
    dispatch
  ]);

  return [publish, subscribe];
}
