import graphql from 'babel-plugin-relay/macro';

//
// djangoContentTypeByRowId(rowId: 42) refers to a ContentType that happens to
// have a relation to all notifications.  This is great because, otherwise,
// I do not think React Relay pagination would work because all GraphQL
// connections must start from one object to have a cursor on other objects.
//
export default graphql`
  query ActivityStreamQuery(
    $filter: NotificationsNotificationCondition!
    $count: Int!
  ) {
    stub: djangoContentTypeByRowId(rowId: 42) {
      ...PagedFeed_stub @arguments(filter: $filter, count: $count)
    }
  }
`;
