import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';

/*
  <FormikSelectField name="color" value="blue">
    {{ red: 'Red' }}
    {{ white: 'White' }}
    {{ blue: 'Blue' }}
  </FormikSelectField/>

  <FormikSelectField name="color" value="blue">
    {{ value: 'red', text: 'Red' }}
    {{ value: 'white', text: 'White' }}
    {{ value: 'blue', text: 'Blue' }}
  </FormikSelectField/>

  <FormikSelectField name="color" value="blue">
    <MenuItem value="red">Red</MenuItem>
    <MenuItem value="white">White</MenuItem>
    <MenuItem value="blue">Blue</MenuItem>
  </FormikSelectField/>

  <FormikSelectField name="color" value="blue">
    {{ red: 'Red' }}
    {{ value: 'white', text: 'White' }}
    <MenuItem value="blue">Blue</MenuItem>
  </FormikSelectField/>
*/

const FormikSelectField = ({ name, children, ...props }) => {
  return (
    <Field component={Select} name={name} {...props}>
      {children.map((option, index) => {
        let {value, text} = option; // i.e. option:{value:string, text:any}
        if (!value || !text) {
          const entries = Object.entries(option);
          if (entries.length === 1) {
            [value, text] = entries[0]; // i.e. option:{[value]:text}
          }
          else {
            // The only other case is an explicit child component
            return option;
          }
        }
        return <MenuItem key={index} value={value}>{text}</MenuItem>
      })}
    </Field>
  );
};

FormikSelectField.propTypes = {
	name: PropTypes.string.isRequired,
	children: PropTypes.array.isRequired
}

export default FormikSelectField;
