import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Avatar,
  Grid,
} from '@material-ui/core';
import { FAQIcon } from '../icons';
import { Markdown } from '../components';
import { useAccordionGroup, useIntl } from '../hooks';
import { useHelpStyles } from '../containers/Help';

const FAQHeader = () => {
  const classes = useHelpStyles();
  const { faq } = useIntl();

  return (
    <Grid container direction='row' alignItems='center'>
      <Grid item xs={1}>
        <FAQIcon />
      </Grid>
      <Grid item xs={11} className={classes.paddedBottom}>
        <Typography variant='h6' key={`faq-header-title`}>
          {faq.header.title}
        </Typography>
        <Typography variant='body1' key={`faq-header-subtitle`}>
          {faq.header.subtitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

const FAQAnswers = ({ section, question }) => {
  const { faq } = useIntl();

  return (
    <Grid container direction='column' spacing={1}>
      {faq.answers[section] &&
        faq.answers[section][question] &&
        Object.keys(faq.answers[section][question]).map((answer) => (
          <Grid
            item
            key={`faq-answers-${section}-${question}-${answer}`}
            xs={12}
          >
            <Markdown variant='body1'>
              {faq.answers[section][question][answer]}
            </Markdown>
          </Grid>
        ))}
    </Grid>
  );
};

const FAQQuestions = ({ section }) => {
  const classes = useHelpStyles();
  const { faq } = useIntl();
  const { expanded, handleChange } = useAccordionGroup();

  return (
    <Grid container direction='column'>
      {faq.questions[section] &&
        Object.keys(faq.questions[section]).map((question) => (
          <Grid item key={`faq-questions-${section}-${question}`} xs={12}>
            <Accordion
              className={classes.fullWidth}
              expanded={expanded === question}
              onChange={handleChange(question)}
            >
              <AccordionSummary>
                <Grid container direction='row' alignItems='center'>
                  <Grid item xs={2}>
                    <Avatar>?</Avatar>
                  </Grid>
                  <Grid item xs={10}>
                    <Markdown variant='body1'>
                      {faq.questions[section][question]}
                    </Markdown>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                {expanded === question && (
                  <FAQAnswers
                    key={`faq-answers-${section}-${question}`}
                    faq={faq}
                    section={section}
                    question={question}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
    </Grid>
  );
};

const FAQBody = () => {
  const { faq } = useIntl();
  const { expanded, handleChange } = useAccordionGroup();

  return (
    <>
      {faq.sections &&
        Object.keys(faq.sections).map((section) => (
          <Accordion
            key={`'faq-section-${section}`}
            expanded={expanded === section}
            onChange={handleChange(section)}
            height='auto'
          >
            <AccordionSummary>
              <Typography>{faq.sections[section]}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === section && (
                <FAQQuestions
                  key={`faq-questions-${section}`}
                  faq={faq}
                  section={section}
                />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );
};

const FAQ = () => {
  return (
    <div>
      <FAQHeader key='faq-header' />
      <FAQBody key='faq-body' />
    </div>
  );
};

export default FAQ;
