import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query HandleRequestActivityQuery($rowId: Int!) {
    request: transfersHoldByTransactionPtrId(transactionPtrId: $rowId) {
      note
      status

    }
  }
`;
