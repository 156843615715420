/**
 * @generated SignedSource<<792997b211f457306978902ab572c3b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SecuritiesLedgersOrderBy = "CREDIT_DEBIT_ASC" | "CREDIT_DEBIT_DESC" | "ID_ASC" | "ID_DESC" | "NATURAL" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "TRANSACTION_ID_ASC" | "TRANSACTION_ID_DESC" | "WALLET_ID_ASC" | "WALLET_ID_DESC" | "%future added value";
export type LedgerFeedPaginationQuery$variables = {
  count: number;
  cursor?: any | null;
  orderBy?: ReadonlyArray<SecuritiesLedgersOrderBy> | null;
  walletID: string;
};
export type LedgerFeedPaginationQuery$data = {
  readonly wallet: {
    readonly " $fragmentSpreads": FragmentRefs<"LedgerFeedFragment_wallet">;
  } | null;
};
export type LedgerFeedPaginationQuery = {
  response: LedgerFeedPaginationQuery$data;
  variables: LedgerFeedPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "walletID"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "walletID"
  }
],
v2 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LedgerFeedPaginationQuery",
    "selections": [
      {
        "alias": "wallet",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v2/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "LedgerFeedFragment_wallet"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LedgerFeedPaginationQuery",
    "selections": [
      {
        "alias": "wallet",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "balance",
                "storageKey": null
              },
              {
                "alias": "asset",
                "args": null,
                "concreteType": "SecuritiesAsset",
                "kind": "LinkedField",
                "name": "securitiesAssetByAssetId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isBurnEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isMintEnabled",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "ledger",
                "args": (v5/*: any*/),
                "concreteType": "SecuritiesLedgersConnection",
                "kind": "LinkedField",
                "name": "securitiesLedgersByWalletId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SecuritiesLedgersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SecuritiesLedger",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "creditDebit",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          {
                            "alias": "tx",
                            "args": null,
                            "concreteType": "SecuritiesTransaction",
                            "kind": "LinkedField",
                            "name": "securitiesTransactionByTransactionId",
                            "plural": false,
                            "selections": [
                              {
                                "alias": "type",
                                "args": null,
                                "concreteType": "DjangoContentType",
                                "kind": "LinkedField",
                                "name": "djangoContentTypeByPolymorphicCtypeId",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "model",
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "time",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "ledger",
                "args": (v5/*: any*/),
                "filters": [
                  "orderBy"
                ],
                "handle": "connection",
                "key": "LedgerFeed_ledger",
                "kind": "LinkedHandle",
                "name": "securitiesLedgersByWalletId"
              }
            ],
            "type": "SecuritiesWallet",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6a0a5dd6fa9b6001cfec281bd24851aa",
    "id": null,
    "metadata": {},
    "name": "LedgerFeedPaginationQuery",
    "operationKind": "query",
    "text": "query LedgerFeedPaginationQuery(\n  $count: Int!\n  $cursor: Cursor\n  $orderBy: [SecuritiesLedgersOrderBy!]\n  $walletID: ID!\n) {\n  wallet: node(id: $walletID) {\n    __typename\n    ...LedgerFeedFragment_wallet_32czeo\n    id\n  }\n}\n\nfragment LedgerFeedFragment_wallet_32czeo on SecuritiesWallet {\n  id\n  balance\n  asset: securitiesAssetByAssetId {\n    isActive\n    isBurnEnabled\n    isMintEnabled\n    id\n  }\n  ledger: securitiesLedgersByWalletId(first: $count, after: $cursor, orderBy: $orderBy) {\n    edges {\n      node {\n        creditDebit\n        id\n        tx: securitiesTransactionByTransactionId {\n          type: djangoContentTypeByPolymorphicCtypeId {\n            model\n            id\n          }\n          time\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "662ce4300f009b251bdab01c48238b3e";

export default node;
