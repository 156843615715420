// import './wdyr';

import React from 'react'
import { render } from 'react-dom'
import './index.css'
import Root from './containers/Root'
import configureStore, { history } from './state/configureStore'
import * as serviceWorker from './serviceWorker'
import { initAudioContext } from './state/audio'
import {addHumanizeToNumber} from "./utils/number"

export const store = configureStore();

initAudioContext()
addHumanizeToNumber()

render(<Root history={history} store={store} />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
