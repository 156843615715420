import React from 'react'
import ReactMarkdown from 'markdown-to-jsx'
import {
  Divider,
  Link,
  Typography,
  withStyles
} from '@material-ui/core'

const styles = (theme) => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
})

const options = {
  overrides: {
    h1: { component: Typography, props: { gutterBottom: true, variant: 'h5' } },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
    h3: { component: Typography, props: { gutterBottom: true, variant: 'caption' } },
    p: { component: Typography, props: { paragraph: true } },
    a: { component: Link },
    hr: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <Divider {...props} className={classes.divider} />
      ))
    },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      ))
    }
  }
}

export default function Markdown(props) {
  return <ReactMarkdown options={options} {...props} />
}