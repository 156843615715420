import React from 'react';
import { Dialog, useMediaQuery, useTheme } from '@material-ui/core';

const ResponsiveDialog = ({ children, ...props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} {...props}>
      {children}
    </Dialog>
  );
};

export default ResponsiveDialog;
