/**
 * @generated SignedSource<<1052b21a21d48734c9633443ef30ee3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type HandlePrivateOfferActivityQuery$variables = {
  rowId: number;
};
export type HandlePrivateOfferActivityQuery$data = {
  readonly request: {
    readonly asset: {
      readonly code: string;
      readonly decimals: number;
      readonly decimals_min: number;
      readonly icon: string | null;
      readonly use_static_icon: boolean;
    } | null;
    readonly exAsset: {
      readonly code: string;
      readonly decimals: number;
      readonly decimals_min: number;
      readonly icon: string | null;
      readonly use_static_icon: boolean;
    } | null;
    readonly exQuantity: number | null;
    readonly note: string | null;
    readonly quantity: number;
    readonly status: string;
    readonly typeOf: string;
  } | null;
};
export type HandlePrivateOfferActivityQuery = {
  response: HandlePrivateOfferActivityQuery$data;
  variables: HandlePrivateOfferActivityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rowId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "transactionPtrId",
    "variableName": "rowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeOf",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exQuantity",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decimals",
  "storageKey": null
},
v9 = {
  "alias": "decimals_min",
  "args": null,
  "kind": "ScalarField",
  "name": "decimalsMin",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v11 = {
  "alias": "use_static_icon",
  "args": null,
  "kind": "ScalarField",
  "name": "useStaticIcon",
  "storageKey": null
},
v12 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v13/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HandlePrivateOfferActivityQuery",
    "selections": [
      {
        "alias": "request",
        "args": (v1/*: any*/),
        "concreteType": "TransfersHold",
        "kind": "LinkedField",
        "name": "transfersHoldByTransactionPtrId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": "asset",
            "args": null,
            "concreteType": "SecuritiesAsset",
            "kind": "LinkedField",
            "name": "securitiesAssetByAssetId",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "alias": "exAsset",
            "args": null,
            "concreteType": "SecuritiesAsset",
            "kind": "LinkedField",
            "name": "securitiesAssetByExAssetId",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HandlePrivateOfferActivityQuery",
    "selections": [
      {
        "alias": "request",
        "args": (v1/*: any*/),
        "concreteType": "TransfersHold",
        "kind": "LinkedField",
        "name": "transfersHoldByTransactionPtrId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": "asset",
            "args": null,
            "concreteType": "SecuritiesAsset",
            "kind": "LinkedField",
            "name": "securitiesAssetByAssetId",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": "exAsset",
            "args": null,
            "concreteType": "SecuritiesAsset",
            "kind": "LinkedField",
            "name": "securitiesAssetByExAssetId",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9fef2d6096f3c7559ead5ef3df6e714",
    "id": null,
    "metadata": {},
    "name": "HandlePrivateOfferActivityQuery",
    "operationKind": "query",
    "text": "query HandlePrivateOfferActivityQuery(\n  $rowId: Int!\n) {\n  request: transfersHoldByTransactionPtrId(transactionPtrId: $rowId) {\n    note\n    status\n    typeOf\n    exQuantity\n    quantity\n    asset: securitiesAssetByAssetId {\n      code\n      decimals\n      decimals_min: decimalsMin\n      icon\n      use_static_icon: useStaticIcon\n      id\n    }\n    exAsset: securitiesAssetByExAssetId {\n      code\n      decimals\n      decimals_min: decimalsMin\n      icon\n      use_static_icon: useStaticIcon\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "03ccb5ecdbf688717ffe3aa9f0f3fe7e";

export default node;
