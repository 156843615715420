import {
  blue,
  orange,
  green,
  red,
  purple,
  brown,
  pink,
  grey,
  lime,
  cyan,
} from '@material-ui/core/colors';
import { scaleOrdinal } from 'd3-scale';

export const lociColors = [
  blue[700],
  orange[700],
  green[700],
  red[700],
  purple[700],
  brown[700],
  pink[700],
  grey[700],
  lime[700],
  cyan[700],
];

export const nivoLociColors = scaleOrdinal(lociColors);
