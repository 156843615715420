import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import { GraphQL, Stone, TapToCopy } from '../components';
import AgentBankInfoQuery from './AgentBankInfoQuery';

const AgentBankInfo = ({ bankAccount }) => {
  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant='caption'>CBU</Typography>
            </TableCell>
            <TableCell>
              <TapToCopy text={bankAccount.cbu} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Stone variant='caption'>addbank.institution</Stone>
            </TableCell>
            <TableCell>
              <Typography>{bankAccount.bankName}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Stone variant='caption'>addbank.account_number</Stone>
            </TableCell>
            <TableCell>
              <Typography>{bankAccount.accountNumber}</Typography>
            </TableCell>
          </TableRow>
          {/*<TableRow>
          <TableCell><Typography variant="caption">{translate.addbank.account_type}</Typography></TableCell>
          <TableCell><Typography>{bankAccount.toa}</Typography></TableCell>
        </TableRow>*/}
          <TableRow>
            <TableCell>
              <Stone variant='caption'>street.receiving_entity</Stone>
            </TableCell>
            <TableCell>
              <Typography>{bankAccount.entity}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant='caption'>CUIT</Typography>
            </TableCell>
            <TableCell>
              <Typography>{bankAccount.taxId}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Stone variant='caption'>common.email</Stone>
            </TableCell>
            <TableCell>
              <Typography>{bankAccount.email}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

const AgentBankInfoContainer = ({ agent, asset }) => {

  return (
    <GraphQL
      Waiter={CircularProgress}
      variables={{
        filter: {
          agentId: { equalTo: agent.rowId },
          assetId: { equalTo: asset.rowId },
          active: { equalTo: true },
        },
      }}
      query={AgentBankInfoQuery}
    >
      {({accounts}) => {
        return accounts ? (
          <AgentBankInfo bankAccount={accounts.nodes[0]} />
        ) : (
          <Box p={1}>
            <Stone variant='h6'>mint.no_agent_bank.subtitle</Stone>
            <Stone>mint.no_agent_bank.text</Stone>
          </Box>
        );
      }}
    </GraphQL>
  );
};

AgentBankInfoContainer.propTypes = {
  agent: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    displayName: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  asset: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    code: PropTypes.string,
    decimals: PropTypes.number,
    decimals_min: PropTypes.number,
    icon: PropTypes.string,
    use_static_icon: PropTypes.bool,
  }).isRequired,
};

export default AgentBankInfoContainer;
