import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Stone, ProfileAnchor, TapToCopy } from '../../components';
import ActivityBox from './ActivityBox';

import type {
  ActivityButtonHandlerDictionary,
  ActivityDisplayProps,
  StreetActivityCounterParty,
  StreetActivityMethod,
  StreetActivityStatus,
} from './types';
import { AssetsStateValue } from '../../types/store';
import ActivityIcon from './ActivityIcon';

interface Props extends ActivityDisplayProps {
  variables: {
    quantity: string;
    price: string;
    cost: string;
    quote_asset: AssetsStateValue;
  };
  counterparty: StreetActivityCounterParty;
  method: StreetActivityMethod;
  status: StreetActivityStatus;
  handlers: ActivityButtonHandlerDictionary;
}

const StreetTicketActivityDisplay: React.FC<Props> = ({
  asset,
  badgeType,
  label,
  template,
  variables,
  counterparty,
  method,
  status,
  handlers,
  time,
  unread,
  onRead,
  children,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { quantity, price, cost, quote_asset } = variables;

  return (
    <ActivityBox time={time} unread={unread} onRead={onRead}>
      <ActivityIcon asset={asset} badgeType={badgeType} />
      <Box p={1}>
        <Stone variant='h6'>{label}</Stone>
        <Typography paragraph>
          {
            // template consumes quantity, price, cost, and quote_asset.code
            // eslint-disable-next-line no-eval
            eval('`' + template + '`')
          }
        </Typography>
        <Box
          display='flex'
          flexDirection='row'
          justifyItems='left'
          alignItems='flex-start'
        >
          <Box>
            <Stone variant='h6'>{counterparty.label}</Stone>
            <ProfileAnchor header={null} profile={counterparty.profile} />
            {
              // <Box p={1}>
              // <Button disabled variant="outlined"
              // onClick={() => push(`/@${counterparty.name}`)}
              // startIcon={<ChatIcon/>}>{translate.activity.send_message.label}
              // </Button>
              // </Box>
            }
          </Box>
          {method.show && (
            <Box pl={2}>
              <Stone variant='h6'>{method.label}</Stone>
              <Box pl={1}>
                <Stone>{method.name}</Stone>
              </Box>
              <TapToCopy text={method.account} />
            </Box>
          )}
        </Box>
        <Typography>Dealer instructions</Typography>
        <Typography>{method.instructions}</Typography>
        <Typography variant='caption'>{`Ticket: ${status.ticket_number} Ad: ${status.ad_number}`}</Typography>
        <Stone variant='h6'>{status.label}</Stone>
        <Stone paragraph>{status.text}</Stone>
        <Typography paragraph>{status.desc}</Typography>

        {children}

        <Box p={0} display='flex' flexDirection='row'>
          {handlers?.primary && (
            <Box pr={1}>
              <Button
                variant='contained'
                color={handlers.primary?.color ?? 'primary'}
                onClick={handlers.primary?.handler}
              >
                {handlers.primary?.caption ?? ''}
              </Button>
            </Box>
          )}
          {handlers?.secondary && (
            <Box p={0} display='flex' flexDirection='row'>
              <Button
                variant='outlined'
                color={handlers.primary ? 'default' : 'primary'}
                onClick={handlers.secondary.handler}
              >
                {handlers.secondary.caption}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </ActivityBox>
  );
};

export default StreetTicketActivityDisplay;
