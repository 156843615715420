import React from 'react'
import { Box, Typography } from '@material-ui/core'
import {HelpIcon as UnknownIcon} from '../icons'

const AssetNotFound = ({code, translate}) => (
  <>
    <Box p={1} align="center">
      <UnknownIcon style={{fontSize:96}}/>
    </Box>
    <Box p={1} align="center">
      <Typography variant="h5">{translate.common.not_found}</Typography>
    </Box>
    <Box p={1} align="center">
      <Typography>{code}</Typography>
    </Box>
  </>
)

export default AssetNotFound;
