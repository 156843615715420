import React from 'react';
import VerifiedUserIcon from './icons/VerifiedUserIcon';
import ProfileIcon from './icons/ProfileIcon';
import QualifiedInvestorIcon from './icons/QualifiedInvestorIcon';
import { Box, Grid } from '@material-ui/core';

const ProfileCredentials = ({ profile, ...props }) => (
  <Box p={1} {...props}>
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <ProfileIcon profile={profile} />
      </Grid>
      <Grid item xs={4}>
        <VerifiedUserIcon profile={profile} />
      </Grid>
      <Grid item xs={4}>
        <QualifiedInvestorIcon profile={profile} />
      </Grid>
    </Grid>
  </Box>
);

export default ProfileCredentials;
