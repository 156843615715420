import { markets } from 'liquibook-lib'
import { ofType } from 'redux-observable'
import { of, map, mergeMap, catchError } from 'rxjs'
import { ORDERS_RX_FILL } from './orders'
import { decodeProtobuf } from '../utils/utils'
import { MARKETS_FETCHED } from './markets'
import { Map, OrderedSet } from 'immutable'
import { FillsApiService } from '../services'

// action types
//
export const FILLS_FETCHED = "FILLS:FETCHED"
export const FILLS_FETCHED_ERROR = "FILLS:FETCHED_ERROR"

// action constructors
//
export const FILLS_FETCHED_ = events =>
  ({type: FILLS_FETCHED, events})
export const FILLS_FETCHED_ERROR_ = (status, message) =>
  ({ type: FILLS_FETCHED_ERROR, status, message })

export const reduce = (state = OrderedSet(), action) => {
  switch (action.type) {
  default:
    return state
  case ORDERS_RX_FILL:
    var fill = {
            timestamp: action.fill.event.timestamp,
            market: action.fill.event.market,
            orderID: action.fill.orderID,
            clOrdID: action.fill.clOrdID,
            fill: action.fill.event.fill
          }
    return state.add(Map(fill))

  case FILLS_FETCHED:
    //Do not overwrite new fills received while fetching history.
    var fills = action.events.fills.map((fill) => {
      return Map(fill)
    })
    fills = OrderedSet(fills)
    fills = fills.union(state)
    return fills
  }
}

export const epics = [

  (action$, state$) => action$.pipe(
    ofType(MARKETS_FETCHED),
    mergeMap(() =>
      new FillsApiService(state$.value.session.access).getAll$().pipe(
        map(response =>
          FILLS_FETCHED_(decodeProtobuf(markets.FillEventList, response))),
        catchError(error => {
          of(FILLS_FETCHED_ERROR_(error.status, error.message))})))),

]
