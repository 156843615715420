import React from 'react';
import ProfileAvatar from './ProfileAvatar';
import { Box, Typography } from '@material-ui/core';
import { useLink } from '../hooks';

const ProfileAnchor = ({ profile, header }) => {
  const pushLink = useLink();
  return (
    <Box display='flex' onClick={() => pushLink(`/@${profile.name}`)}>
      <Box p={1} alignSelf='center'>
        <ProfileAvatar profile={profile} />
      </Box>
      <Box p={1}>
        {header ? header : <></>}
        <Typography variant='subtitle1'>
          {profile.display_name || profile.displayName}
        </Typography>
        <Typography>@{profile.name}</Typography>
      </Box>
    </Box>
  );
};

export default ProfileAnchor;
