import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

export default function useAction(action) {
  const dispatch = useDispatch();
  return useCallback((...p) => dispatch(action(...p)), [dispatch, action]);
}

useAction.propTypes = {
  action: PropTypes.func.isRequired,
};
