import { useState } from 'react';

export default function useAccordionGroup() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return { expanded, handleChange };
}
