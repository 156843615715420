import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  makeStyles,
} from '@material-ui/core';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { TextField, Select, CheckboxWithLabel } from 'formik-material-ui';
import * as Yup from 'yup';
import { enqueueSnackbar } from '../components/utils/notistack_redux/redux/actions';
import { SESSION_FETCH_ } from '../state/session';
import { useLink, useIntl, useApiService } from '../hooks';
import { ResponsiveDialog as Dialog } from '.';
import { ProfilesApiService, UsernameApiService } from '../services';

const Types = [
  'personal', // just for me
  'llc', // partnership/llc
  'corporation', // corporation
  'financial', // financial institution
  'association', // club or association
  'other', // team, project, or other purpose
];

const Controller = ({ children, onSuccess }) => {
  const translate = useIntl();
  const dispatch = useDispatch();
  const switchProfile = useCallback(
    (profileId) => dispatch(SESSION_FETCH_(profileId)),
    [dispatch]
  );
  const pushLink = useLink();
  const profilesApi = useApiService(ProfilesApiService);
  const usernameApi = useApiService(UsernameApiService);
  const onError = useCallback(
    (message) =>
      dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: 'error',
          },
        })
      ),
    [dispatch]
  );

  return (
    <Formik
      initialValues={{
        name: '',
        type: 'personal',
        isGroup: false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .max(32)
          .required()
          .matches(/^([A-z])*[^\s]\1*$/, translate.profile?.no_whitespace || 'translate.profile.no_whitespace')
          .test('valid-new-name', 'Not valid', async (name) => {
            const response = await usernameApi.get(`${name}`);
            if (response && response.ok) {
              const data = response.bodyJson;
              return data.valid && !data.exists;
            }
            return true;
          }),
        type: Yup.string().required(),
        isGroup: Yup.boolean(),
      })}
      onSubmit={
        // eslint-disable-next-line no-unused-vars
        async ({ link, ...values }, { setSubmitting }) => {
        try {
          const response = await profilesApi.post(values);
          setSubmitting(false);
          if (response.ok) {
            onSuccess();
            const { profileId } = response.bodyJson;
            switchProfile(profileId);
            pushLink('/profile');
          } else {
            onError(translate.profile.failed_save_unspecified);
          }
        } catch (error) {
          onError(translate.profile.failed_save_unspecified);
        }
      }}
    >
      {children}
    </Formik>
  );
};

const useStyles = makeStyles((theme) => ({
  control: {
    margin: theme.spacing(2),
  },
}));

const ProfileCreateDialog = ({ onClose, open }) => {
  const classes = useStyles();
  const translate = useIntl();

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>
          {translate.nav.new_profile}
        </DialogTitle>
        <Controller onSuccess={onClose}>
          {({
            errors,
            isSubmitting,
            isValid,
            // eslint-disable-next-line no-unused-vars
            setFieldValue,
            submitForm,
            touched,
            values,
          }) => (
            <Form>
              <DialogContent>
                <DialogContentText>
                  {translate.nav.new_profile_desc || 'nav.new_profile_desc'}
                </DialogContentText>
                <FormControl className={classes.control}>
                  <Field
                    autoFocus={true}
                    name='name'
                    component={TextField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>@</InputAdornment>
                      ),
                    }}
                    label={translate.profile.field_name_label}
                  />
                  <FormHelperText>
                    {errors.name
                      ? errors.name.message
                      : translate.profile.field_name_hint}
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.control}>
                  <InputLabel htmlFor='type-label'>
                    {translate.profile.field_orgtype_label}
                  </InputLabel>
                  <Field
                    name='type'
                    component={Select}
                    type='text'
                    inputProps={{ id: 'type-label' }}
                  >
                    {Types.map((type) => (
                      <MenuItem
                        key={type}
                        value={type}
                        selected={type === values.type}
                      >
                        {translate.profile.field_orgtype[type].label}
                      </MenuItem>
                    ))}
                  </Field>
                  <FormHelperText>
                    {translate.profile.field_orgtype_hint}
                  </FormHelperText>
                  <ErrorMessage name='type' />
                </FormControl>
                <FormControl className={classes.control}>
                  <Field
                    name='isGroup'
                    component={CheckboxWithLabel}
                    type='checkbox'
                    Label={{
                      label: translate.profile.optional_enable_group_label,
                    }}
                  />
                  <FormHelperText>
                    {translate.profile.optional_enable_group_hint}
                  </FormHelperText>
                </FormControl>
              </DialogContent>
              <br />
              <DialogActions>
                <Button variant='text' onClick={onClose} color='default'>
                  {translate.dialog.cancel}
                </Button>
                <Button
                  variant='contained'
                  disabled={!touched || !isValid || isSubmitting}
                  onClick={submitForm}
                  color='primary'
                >
                  {translate.dialog.create}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Controller>
      </Dialog>
    </div>
  );
};

export default ProfileCreateDialog;
