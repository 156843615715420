import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { LinkInfo } from '../../components';
import { useIntl } from '../../hooks';
import StreetTicketActivityDisplay from './StreetTicketActivityDisplay';

import type {
  ActivityButtonHandlerDictionary,
  ActivityDisplayProps,
  StreetActivityCounterParty,
  StreetActivityMethod,
  StreetActivityStatus,
  StreetActivityVariables,
} from './types';

interface Props extends ActivityDisplayProps {
  variables: StreetActivityVariables;
  counterparty: StreetActivityCounterParty;
  method: StreetActivityMethod;
  status: StreetActivityStatus;
  // eslint-disable-next-line no-unused-vars
  handleAction: (action: number | string | undefined, props?: Record<string, unknown>) => () => void;
}

const StreetTicketActivityForDealerBuy: React.FC<Props> = ({
  asset,
  badgeType,
  label,
  template,
  variables,
  counterparty,
  method,
  status,
  time,
  unread,
  onRead,
  handleAction,
}) => {
  const translate = useIntl();
  // eslint-disable-next-line no-unused-vars
  const { quantity, price, cost, quote_asset } = variables;

  const handlers: ActivityButtonHandlerDictionary =
    status.code === 'pledged' || status.code === 'escrowed'
      ? {
          primary: {
            caption: translate.dialog.accept,
            handler: handleAction('accept'),
          },
          secondary: {
            caption: translate.dialog.decline,
            handler: handleAction('reject'),
          },
        }
      : status.code === 'accepted'
      ? {
          primary: {
            caption:
              method.handling === 'mint'
                ? translate.street_ticket.mint.label
                : translate.street_ticket.release_escrow.label,
            handler: handleAction('complete'),
          },
          secondary: {
            caption: translate.dialog.decline,
            handler: handleAction('reject'),
          },
        }
      : status.code === 'shipped'
      ? {
          primary: {
            caption:
              method.handling === 'mint'
                ? translate.street_ticket.mint.label
                : translate.street_ticket.release_escrow.label,
            handler: handleAction('complete'),
          },
        }
      : {};

  return (
    <StreetTicketActivityDisplay
      asset={asset}
      badgeType={badgeType}
      label={label}
      template={template}
      variables={variables}
      counterparty={counterparty}
      method={method}
      status={status}
      handlers={handlers}
      time={time}
      unread={unread}
      onRead={onRead}
    >
      <Box p={1}>
        <Box p={0}>
          {method?.link ? <LinkInfo link={method.link} /> : <div />}
          {status.code === 'shipped' && (
            <Typography>
              {translate.views.eft.reference}: {method?.shipmentRef ?? ''}
            </Typography>
          )}
        </Box>
      </Box>
    </StreetTicketActivityDisplay>
  );
};

export default StreetTicketActivityForDealerBuy;
