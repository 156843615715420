import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, IconButton, makeStyles } from '@material-ui/core';
import { LogoIcon } from '../../icons';

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  toolbar: {
    background:
      theme.palette.type === 'dark'
        ? theme.palette.background.default
        : theme.palette.primary.main,
  },
  logo: {
    width: 32,
    height: 32,
    color:
      theme.palette.type === 'dark'
        ? theme.palette.primary.dark
        : theme.palette.background.default,
  },
}));

const BottomAppBar = ({ drawerHandlers }) => {
  const { appBar, toolbar, logo } = useStyles();
  const { handleDrawerOpen } = drawerHandlers;

  return (
    <AppBar position='fixed' elevation={4} className={appBar}>
      <Toolbar className={toolbar}>
        <IconButton edge='start' color='inherit' onClick={handleDrawerOpen}>
          <LogoIcon className={logo} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

BottomAppBar.propTypes = {
  drawerHandlers: PropTypes.shape({
    handleDrawerOpen: PropTypes.func.isRequired,
  }).isRequired,
};

export default BottomAppBar;
