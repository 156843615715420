import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      ...theme.mixins.gutters(),
    },
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    minHeight: 800,
  },
}));

const CenteredGrid = ({children, ...rest}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '40vh' }}
        {...rest}
      >
        {children}
      </Grid>
    </div>
  );
};

export default CenteredGrid;
