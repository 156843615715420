import { createSelector } from 'reselect'

// This is a dumping ground for navigation and view context helpers.
//
// TODO: Remove this file and replace with more idiomatically consistent logic.
//       The navigtion selector helper should be replaced with React Router push.

export const title = translate => {
  if (window.subtitle) return window.subtitle
  if (window.location.pathname.startsWith('/home')) return translate.nav.home || 'Home'
  if (window.location.pathname.startsWith('/pocket')) return translate.nav.pocket
  if (window.location.pathname.startsWith('/activity')) return translate.nav.activity
  if (window.location.pathname.startsWith('/friends')) return translate.nav.friends || 'Friends'
  if (window.location.pathname.startsWith('/max')) return translate.nav.dnx
  if (window.location.pathname.startsWith('/groups')) return translate.nav.groups
  if (window.location.pathname.startsWith('/express')) return translate.nav.express
  if (window.location.pathname.startsWith('/private')) return translate.nav.private
  if (window.location.pathname.startsWith('/profile')) return translate.nav.profile
  if (window.location.pathname.startsWith('/ownprofile')) return translate.nav.ownprofile || 'Edit own profile'
  if (window.location.pathname.startsWith('/street')) return translate.nav.street
  if (window.location.pathname.startsWith('/search')) return translate.nav.search || 'Search'
  if (window.location.pathname.startsWith('/mint')) return translate.nav.mint
  if (window.location.pathname.startsWith('/burn')) return translate.nav.burn
  if (window.location.pathname.startsWith('/send')) return translate.nav.send
  if (window.location.pathname.startsWith('/request')) return translate.nav.request
  if (window.location.pathname.startsWith('/lib/faq')) return translate.nav.faq
  if (window.location.pathname.startsWith('/lib/freelancer-landing')) return translate.nav.freelancer || 'Freelancer'
  if (window.location.pathname.startsWith('/lib/help-landing')) return translate.nav.help
  if (window.location.pathname.startsWith('/lib/transfers')) return translate.nav.transfer
  if (window.location.pathname.startsWith('/tools')) return translate.nav.tools
  if (window.location.pathname.startsWith('/explore')) return translate.nav.explore
  if (window.location.pathname.startsWith('/asset')) return translate.nav.asset
  if (window.location.pathname.startsWith('/reports')) return translate.nav.reports
  if (window.location.pathname.startsWith('/subscribe')) return translate.nav.subscribe
  if (window.location.pathname.startsWith('/scores')) return translate.nav.scores
  if (window.location.pathname.startsWith('/rewards')) return translate.nav.rewards
  if (window.location.pathname.startsWith('/superpowers')) return translate.nav.super
  if (window.location.pathname.startsWith('/preferences')) return translate.nav.preferences
  if (window.location.pathname.startsWith('/security')) return translate.nav.security
  if (window.location.pathname.startsWith('/docs')) return translate.nav.docs
  if (window.location.pathname.startsWith('/dashboard')) return translate.nav.dashboard
  return ''
}

const selectSoukHost = state => state.session.soukHost
const selectLanguage = state => state.view.translate ? state.view.translate._lang : 'en'

// external navigation, all else should use connected-react-router's push
//
export default createSelector(
  selectSoukHost,
  selectLanguage,
  (soukHost, lang) => {
    const i18n = lang === 'en' ? '' : `/${lang}`
    return nav => {
      switch (nav) {
      default: return false
      case 'admin':        return window.location = '/admin'
      case 'souk':          return window.location = `/cas/login?service=https%3A%2F%2F${soukHost}%2Fauth%2Fauth%2Fcas%2Fcallback%3Furl`
      case 'new-profile':    return window.location = i18n + '/loci/pocket/new/'
      case 'alt-activity':   return window.location = i18n + '/loci/inbox/'
      case 'links':         return window.location = i18n + '/loci/link_account_list/'
      // case 'help':         return window.location = 'mailto:hello@loci-mail.com'
      }
    }
  }
)