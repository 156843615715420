import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@material-ui/core';
import NumberFormat from '../NumberFormat';

const Input = ({ value, onChange, asset, decimals, ...props }) => {
  const [declared, ] = useState(value);

  const handleValueChange = (values) => {
    if (declared !== values.value) {
      if (onChange) {
        onChange(values);
      }
    }
  };

  return (
    <NumberFormat
      customInput={TextField}
      onValueChange={e => handleValueChange(e)}
      isNumericString={true}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {asset ? asset.code : ''}
          </InputAdornment>
        ),
      }}
      disabled={!asset}
      fullWidth={true}
      decimalScale={decimals || (asset ? asset.decimals : 2)}
      {...props}
    />
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  asset: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  decimals: PropTypes.number,
};

export default Input;
