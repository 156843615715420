import React from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

// TODO: Someday make this mimic the specific activity type skeleton.
// When there is nothing else to do, that is.

const ActivitySkeleton: React.FC = () => {
  return (
      <Box pl={2} pb={1}>
        <Skeleton variant='text' width={48} />
        <Box
          display='flex'
          flexDirection='row'
          alignContent='flex-start'
          alignItems='center'
        >
          <Skeleton variant='circle' width={48} height={48} />
          <Skeleton variant='rect' width={180} height={60} />
        </Box>
      </Box>
  );
};

export default ActivitySkeleton;
