import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  withStyles,
} from '@material-ui/core';
import { TimePicker, DatePicker } from '@material-ui/pickers';
import { connect } from 'react-redux';
import {
  TIME_RANGE_CHOOSE,
  TIME_BEGIN_DATE,
  TIME_END_DATE,
} from '../state/filterMenu';

const styles = {
  grid: {
    width: '100%',
  },
};

function Transition(props) {
  return <Slide direction='up' {...props} />;
}

class FilterDateDialog extends React.Component {
  state = {
    // The first commit of Material-UI
    sinceDate: new Date(),
    throughDate: new Date(),
  };

  // eslint-disable-next-line no-unused-vars
  handleSinceDateChange = (date, e) => {
    let beginDate = date.toISOString();
    this.props.chooseDate(TIME_BEGIN_DATE, beginDate);
    this.setState({ sinceDate: date });
  };

  handleThroughDateChange = (date) => {
    let endDate = date.toISOString();
    this.props.chooseDate(TIME_END_DATE, endDate);
    this.setState({ throughDate: date });
  };

  render() {
    const { classes, open } = this.props;
    const { sinceDate, throughDate } = this.state;

    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth='md'
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='Time Dialog'
          aria-describedby='Date Range Time Pickers'
        >
          <DialogContent>
            <Grid container className={classes.grid} justifyContent='space-around'>
              <DatePicker
                margin='normal'
                label='Since Date'
                value={sinceDate}
                onChange={this.handleSinceDateChange}
              />
              <TimePicker
                margin='normal'
                label='Since Date Time'
                value={sinceDate}
                onChange={this.handleSinceDateChange}
              />

              <DatePicker
                margin='normal'
                label='Through Date'
                value={throughDate}
                onChange={this.handleThroughDateChange}
              />
              <TimePicker
                margin='normal'
                label='Through Date Time'
                value={throughDate}
                onChange={this.handleThroughDateChange}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.okDateRangehandler} color='primary'>
              Ok
            </Button>
            <Button onClick={this.props.cancelDateRangeHandler} color='primary'>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timeRange: state.timeRanges.TimeRange,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    chooseDate: (id, date) => {
      dispatch({ type: TIME_RANGE_CHOOSE, id: id, time: date });
    },
  };
};

FilterDateDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FilterDateDialog));
