import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useIntl } from '../hooks';
import { ResponsiveDialog as Dialog } from '.';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  jumbobutton: {
    textTransform: 'none',
    fontWeight: 'normal',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const VenueChoiceDialog = ({ onCancel, onChoice, open }) => {
  const classes = useStyles();
  const translate = useIntl();

  return (
    <Dialog open={open}>
      <DialogTitle>{translate.profile.optional_enable_group_label}</DialogTitle>
      <DialogContent>
        <DialogContentText>{translate.venue.choices}</DialogContentText>
        <Button
          className={classes.jumbobutton}
          variant='outlined'
          size='large'
          onClick={() => onChoice && onChoice('perk')}
        >
          <Box>
            <Typography paragraph align='left' variant='h6'>
              {translate.venue.for_profile_subtitle ?? 'As You'}
            </Typography>
            <Typography align='left' variant='body2'>
              {translate.venue.for_profile}
            </Typography>
          </Box>
        </Button>
        <Button
          className={classes.jumbobutton}
          variant='outlined'
          size='large'
          onClick={() => onChoice && onChoice('venue')}
        >
          <Box>
            <Typography paragraph align='left' variant='h6'>
              {translate.venue.for_cause_subtitle ?? 'As Sponsor'}
            </Typography>
            <Typography align='left' variant='body2'>
              {translate.venue.for_cause}
            </Typography>
          </Box>
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          variant='text'
          color='default'
          onClick={() => onCancel && onCancel()}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VenueChoiceDialog;
