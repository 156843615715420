import React, { useState } from 'react';
import {
  Box,
  Button,
  Paper,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import PerkDialog from './PerkDialog';
import VenueChoiceDialog from './VenueChoiceDialog';
import VenueDialog from './VenueDialog';
import Venue from './Venue';
import { Auth } from './Own';
import { useIntl, useLink } from '../hooks';

const useStyles = makeStyles((theme) =>
  createStyles({
    venue_image: {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        height: theme.spacing(18),
      },
      [theme.breakpoints.only('sm')]: {
        height: theme.spacing(18),
      },
      [theme.breakpoints.up('md')]: {
        height: theme.spacing(16),
      },
      backgroundImage: 'url(https://source.unsplash.com/random)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  })
);

const VenueList = ({ onChange, profile }) => {
  const t_ = useIntl();
  const [perkOpen, setPerkOpen] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [venueChoiceOpen, setVenueChoiceOpen] = useState(false);
  const [venueOpen, setVenueOpen] = useState(false);
  const classes = useStyles();
  const pushLink = useLink();

  const handleVenueChoice = (choice) => {
    if (choice === 'perk') {
      setPerkOpen(true);
      setVenueChoiceOpen(false);
    } else {
      setVenueOpen(true);
      setVenueChoiceOpen(false);
    }
  };

  const handleVenueCancel = () => {
    setVenueOpen(false);
    setSelectedVenue(null);
  };

  const handleNewVenue = ({ rowId }) => {
    pushLink(`/@${profile.name}/venue/${rowId}`);
  };

  const handlePerkSubmit = () => {
    setPerkOpen(false);
    onChange && onChange();
  };

  const handlePerkCancel = () => {
    setPerkOpen(false);
  };

  const isSociallyActive =
    profile.perks?.nodes?.length || profile.venues?.nodes?.length;

  return (
    <>
      <Auth own={profile}>
        <Button
          fullWidth
          variant={isSociallyActive ? 'outlined' : 'contained'}
          color='primary'
          style={{ textTransform: 'none' }}
          onClick={() => setVenueChoiceOpen(true)}
        >
          {t_.profile.optional_enable_group_label}
        </Button>
      </Auth>
      {profile.venues?.nodes.map((node, index) => {
        const venue = node.venue.nodes[0];
        return (
          <Box pt={1} key={index}>
            <Paper>
              <Box p={2}>
                <div
                  onClick={() =>
                    pushLink(
                      `/@${venue.pocket.sponsor.name}/venue/${venue.rowId}/`
                    )
                  }
                >
                  <div
                    className={classes.venue_image}
                    style={
                      venue.image
                        ? { backgroundImage: `url(/media/${venue.image})` }
                        : {}
                    }
                  />
                  <Venue {...venue} />
                </div>
              </Box>
            </Paper>
          </Box>
        );
      })}
      <Auth own={profile}>
        <VenueChoiceDialog
          open={venueChoiceOpen}
          onChoice={handleVenueChoice}
          onCancel={() => setVenueChoiceOpen(false)}
        />
        <VenueDialog
          open={venueOpen}
          venue={selectedVenue}
          onCancel={handleVenueCancel}
          onCreate={handleNewVenue}
        />
        <PerkDialog
          open={perkOpen}
          perk={null}
          venue={selectedVenue}
          onCancel={handlePerkCancel}
          onSubmit={handlePerkSubmit}
        />
      </Auth>
    </>
  );
};

export default VenueList;
