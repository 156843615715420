import { createSelector } from 'reselect'
import { Map } from 'immutable'

export default createSelector(

  state =>
    state.assets,

  assets =>
    assets.reduce(
      (acc, asset, code) =>
        asset.ctype === 'fiatproxyasset' || asset.ctype === 'cryptoproxyasset' ? acc.set(code, asset) : acc,
      Map()
    )
)