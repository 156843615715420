import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
  makeStyles,
  styled,
} from '@material-ui/core';
import { blue, green, red } from '@material-ui/core/colors';
import Transition from './Transition';
import { useIntl, useWidth } from '../hooks';

const useDialogTitleStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const DialogTitle = ({ children }) => {
  const classes = useDialogTitleStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant='h6'>{children}</Typography>
    </MuiDialogTitle>
  );
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 360,
      minHeight: 320,
    },
  },
}));

const AlertDialog = ({ content, open, onClose, title, variant }) => {
  const width = useWidth();
  const classes = useStyles();
  const translate = useIntl();

  const varStyle = (variant) => {
    if (variant === 'success') {
      return { background: green[50] };
    }
    if (variant === 'failure') {
      return { background: red[50] };
    }
    if (variant === 'info') {
      return { background: blue[800] };
    }
    return null;
  };

  return (
    <Dialog
      fullScreen={width === 'xs'}
      onClose={onClose}
      aria-labelledby='customized-dialog-title'
      open={!!open}
      TransitionComponent={Transition}
    >
      <DialogTitle
        style={varStyle(variant)}
        id='customized-dialog-title'
        onClose={onClose}
      >
        <Box align='center'>{title}</Box>
      </DialogTitle>
      <DialogContent className={classes.content} style={varStyle(variant)}>
        {content}
      </DialogContent>
      <DialogActions>
        <Box flexGrow='1' align='center'>
          <Button
            variant='contained'
            fullWidth
            onClick={onClose}
            color='primary'
          >
            {translate.common.ok}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
