import React from 'react';
import { QueryRenderer } from 'react-relay';
import { environment } from '../relay';
import PropTypes from 'prop-types';
import { useError } from '../hooks';
import { LinearProgress } from '@material-ui/core';

const GraphQL = ({ children, Waiter, ...props }) => {
  const onError = useError();

  const ProgressIndicator = Waiter === undefined ? LinearProgress : Waiter;

  return (
    <QueryRenderer
      environment={environment}
      render={({ error, props }) => {
        if (error) {
          onError(error);
          return <div />;
        } else if (!props) {
          return <ProgressIndicator />;
        } else {
          return children(props);
        }
      }}
      {...props}
    />
  );
};

GraphQL.propTypes = {
  children: PropTypes.func.isRequired,
  Waiter: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export default GraphQL;
