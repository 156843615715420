import React from 'react';
import {
  Box,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { AssetImage, Markdown } from '../components';
import { useAssetContent, useLink } from '../hooks';

const useStyles = makeStyles((theme) => ({
  background: {
    width: '100%',
    zIndex: -1,
  },
  overlay: {
    bottom: theme.spacing(1),
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    // width: 20,
    marginTop: '-16%',
    width: '100%',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
}));

const AssetHeader = ({ asset }) => {
  const classes = useStyles();
  const pushLink = useLink();
  const assetContent = useAssetContent(asset.code);

  if (!assetContent) {
    return <div />;
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      onClick={() => pushLink(`/preview/${asset.code}`)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <GridList spacing={1}>
            <GridListTile cols={2} rows={1}>
              <img
                src={
                  // eslint-disable-next-line no-mixed-operators
                  asset.featured_art_uri ||
                  (asset.image && `/media/${asset.image.file.path}`)
                }
                alt={asset.code}
              />
              <GridListTileBar
                title={assetContent.name}
                subtitle={<span>({asset.code})</span>}
                actionPosition='left'
                actionIcon={
                  asset.icon ? (
                    <IconButton className={classes.icon}>
                      <AssetImage
                        alt={asset.code}
                        src={
                          asset.icon[0] === '/'
                            ? asset.icon
                            : `/media/${asset.icon}`
                        }
                        useStatic={asset.use_static_icon}
                      />
                    </IconButton>
                  ) : undefined
                }
              />
            </GridListTile>
          </GridList>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography variant='h5'>{assetContent.heading}</Typography>
          {assetContent?.caption && (
            <Typography variant='caption'>{assetContent.caption}</Typography>
          )}
          <div> {/* force Markdown to block instead of inline */}
            <Markdown>{assetContent.description}</Markdown>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetHeader;
