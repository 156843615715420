import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BankIcon, AddCircleOutlineIcon as AddIcon } from '../icons';
import AddBankDialog from './links/AddBankDialog.js';
import { GraphQL, SelectedMenu } from '../components';
import DestinationBankPickerQuery from './DestinationBankPickerQuery';
import { useIntl } from '../hooks';

const DestinationBankPicker = ({
  asset,
  banks,
  t_,
  value,
  variant,
  onChange,
  onAdd,
}) => {
  const [newBank, setNewBank] = useState(false);

  const handleBankChange = (index, bank) => {
    setNewBank(bank.key === 'add');
    if (bank.key !== 'add') {
      onChange && onChange(banks[index].key);
    }
  };

  const handleAddBankClose = () => {
    setNewBank(false);
  };

  const handleAddBankSubmit = (bank) => {
    setNewBank(false);
    onAdd(bank);
  };

  const handleAddBankError = () => {
    setNewBank(false);
  };

  let index = null;
  for (let i = 0; i < banks.length; ++i) {
    if (banks[i].key === value) {
      index = i;
    }
  }

  return (
    <>
      <AddBankDialog
        asset={asset}
        open={newBank}
        onClose={handleAddBankClose}
        onSubmit={handleAddBankSubmit}
        onError={handleAddBankError}
        variant={variant}
      />
      <SelectedMenu
        label={t_.destination}
        options={banks}
        value={index}
        onSelect={handleBankChange}
      />
    </>
  );
};

const DestinationBankPickerContainer = ({
  asset,
  variant,
  onChange,
  ...rest
}) => {
  const profileId = useSelector((state) => state.session.profileID);
  const { burn: t_ } = useIntl();

  const [recentlyAdded, setRecentlyAdded] = useState(null);

  const handleAdd = (bank) => {
    setRecentlyAdded(bank);
    onChange(bank);
  };

  return (
    <GraphQL
      variables={{
        recentlyAdded, // trigger refetch
        filter: {
          securitiesLinkByLinkPtrId: {
            profileId: {
              equalTo: profileId,
            },
            or: [
              { assetId: { equalTo: asset.id } },
              {
                securitiesAssetByAssetId: {
                  underlyingId: { equalTo: asset.id },
                },
              },
            ],
          },
          and: [
            {
              not: {
                bankName: {
                  equalTo: 'PayPal',
                },
              },
            },
            {
              not: {
                bankName: {
                  equalTo: 'VIRTUAL',
                },
              },
            },
          ],
        },
      }}
      query={DestinationBankPickerQuery}
    >
      {(gq) => {
        const banks = gq.banks.nodes.map((bank) => {
          const label =
            bank.link.nickname || variant === 'mobile'
              ? `${bank.bankName} ${bank.accountNumber}`
              : `${bank.bankName} ****${bank.accountNumber.substr(-4, 4)}`;
          return {
            key: bank.link.rowId,
            icon: <BankIcon />,
            label,
          };
        });
        banks.push({
          key: 'add',
          icon: <AddIcon />,
          label: `${t_.add_new_bank}...`,
        });
        return (
          <DestinationBankPicker
            asset={asset}
            profileId={profileId}
            t_={t_}
            variant={variant}
            onChange={onChange}
            {...rest}
            banks={banks}
            onAdd={handleAdd}
          />
        );
      }}
    </GraphQL>
  );
};

export default DestinationBankPickerContainer;
