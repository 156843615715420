export const FILTER_BY_OPEN = '1';
export const FILTER_BY_ACTIVE = '2';
export const FILTER_BY_PAST_24_HOURS = '3';
export const FILTER_SHOW_DATETIME = '4';
export const FILTER_BY_DATETIME = '5';

export const FILTER_ITEM_SELECT = 'FILTER:ITEM_SELECT';


export const FILTER_ITEM_SELECT_ = id =>
  ({ type: FILTER_ITEM_SELECT, id })

// time Range for filter
//
export const TIME_BEGIN_DATE = 'TIME:BEGIN_DATE'
export const TIME_END_DATE = 'TIME:END_DATE'

export const TIME_RANGE_CHOOSE = "TIME:RANGE_CHOOSE"

const initState = {
  OrdersFilters: [
    {id: FILTER_BY_OPEN, title: 'Open Orders', selected: false},
    {id: FILTER_BY_ACTIVE, title: 'Selected Market', selected: false},
    {id: FILTER_BY_PAST_24_HOURS, title: 'Today’s Orders', selected: false},
    {id: FILTER_SHOW_DATETIME, title: 'Time Range', selected: false},
    {id: FILTER_BY_DATETIME, title: 'Time Range', selected: false},
  ]
}

export const filterMenus = (state = initState, action) => {

  if (action.type === FILTER_ITEM_SELECT) {

    var newStatus = [];
    for(var i=0; i<state.OrdersFilters.length; i++) {

      // eslint-disable-next-line eqeqeq
      if (state.OrdersFilters[i].id == action.id) {
        state.OrdersFilters[i].selected = !state.OrdersFilters[i].selected;
      }
      newStatus.push(state.OrdersFilters[i]);
    }
    return Object.assign({OrdersFilters: newStatus})
  } 
  return state;
}

const timeRangeInitState = {
  TimeRange: [
    {id: TIME_BEGIN_DATE, time: ''},
    {id: TIME_END_DATE, time: ''}
  ]
}

export const timeRanges = (state = timeRangeInitState, action) => {

  // eslint-disable-next-line eqeqeq
  if (action.type == TIME_RANGE_CHOOSE) {

    var newStatus = [];
    for(var i=0; i<state.TimeRange.length; i++) {
      // eslint-disable-next-line eqeqeq
      if (state.TimeRange[i].id == action.id) {
        state.TimeRange[i].time = action.time;
      }
      newStatus.push(state.TimeRange[i]);
    }
    return Object.assign({TimeRange: newStatus})
  }

  return state;
}