import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  format,
  formatDistance,
  formatDistanceStrict,
  formatRelative,
} from 'date-fns';
import {
  enUS as en,
  enUS as en_US,
  enGB as en_GB,
  es,
  pt,
  ptBR as pt_BR,
  arSA as ar,
} from 'date-fns/locale';

export default function useDateFns() {
  const language = useSelector((state) => state.session.language);
  const [bestLocale, setBestLocale] = useState(null);
  
  useEffect(() => {
    const locales = { en, en_US, en_GB, es, pt, pt_BR, ar };
    if (language) {
      const [lang, region] = language.split('-', 2);
      const langRegion = lang + (region ? '_' + region.toUpperCase() : '');
      
      setBestLocale({
        locale: locales[langRegion] ?? locales[lang] ?? locales['en'],
      });
    }
  }, [language]);

  const fmt = useCallback(
    (date, formatStr, options) =>
      format(date, formatStr, { ...options, bestLocale }),
    [bestLocale]
  );

  const fmtRelative = useCallback(
    (date, baseDate, options) =>
      formatRelative(date, baseDate, { ...options, bestLocale }),
    [bestLocale]
  );

  const fmtDistance = useCallback(
    (date, baseDate, options) =>
      formatDistance(date, baseDate, { ...options, bestLocale }),
    [bestLocale]
  );

  const fmtDistanceStrict = useCallback(
    (date, baseDate, options) =>
      formatDistanceStrict(date, baseDate, { ...options, bestLocale }),
    [bestLocale]
  );

  return {
    format: fmt,
    formatRelative: fmtRelative,
    formatDistance: fmtDistance,
    formatDistanceStrict: fmtDistanceStrict,
  };
}
