import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Progress from './Progress';
import Stone from './Stone';

const FundingProgress = ({ pocket, target, targetAsset }) => {
  const assets = useSelector(state => state.assets);
  const notionalViewAssetCode = useSelector(state => state.session.notionalAssetCode);
  const [total, setTotal] = useState(0);
  const [notionalTotal, setNotionalTotal] = useState(0);
  const notionalTarget = targetAsset.notionalValue * target;

  useEffect(() => {
    if (assets && notionalViewAssetCode && pocket) {
      const notionalViewAsset = assets.get(notionalViewAssetCode);
      if (notionalViewAsset) {
        const total = pocket.wallets?.nodes.reduce(
          (acc, wallet) => acc + parseFloat(wallet.asset.notionalValue) * (wallet.ledger?.nodes.reduce(
            (acc2, ledger) => acc2 + parseInt(ledger.payment), 0) ?? 0) / 10**wallet.asset.decimals, 0) ?? 0;
        setTotal(total / targetAsset.notionalValue);
        setNotionalTotal(total / notionalViewAsset.notionalValue);
      }
    }
  },
    [assets, notionalViewAssetCode, pocket, targetAsset.notionalValue]);

  return (
    <Progress
        target={target}
        total={total}
        assetCode={targetAsset.code}
        {...(targetAsset.code === notionalViewAssetCode ? {} : {
          notionalTarget: notionalTarget,
          notionalTotal: notionalTotal,
          notionalAssetCode: notionalViewAssetCode
        })}
    />
  );
};
  
const Venue = ({ name, noTitle, venueType, description, pocket, target, targetAsset }) => {
  return (
    <>
      {noTitle || <Typography variant="h6">{name}</Typography>}
      <Stone variant="h5">venue.field.venueType.{venueType}</Stone>
      <Typography variant="caption">{description}</Typography>
      {venueType === 'fund' && (
        <FundingProgress
            pocket={pocket} 
            target={target}
            targetAsset={targetAsset}
        />
      )}
    </>
  );
}

export default Venue;
