/**
 * @generated SignedSource<<ae7335cd279b769d2b6f168e39d58891>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useActiveFiatAssetsQuery$variables = {};
export type useActiveFiatAssetsQuery$data = {
  readonly assets: {
    readonly nodes: ReadonlyArray<{
      readonly code: string;
      readonly name: string;
    } | null>;
  } | null;
};
export type useActiveFiatAssetsQuery = {
  response: useActiveFiatAssetsQuery$data;
  variables: useActiveFiatAssetsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "isActive": {
        "equalTo": true
      },
      "securitiesExternalassetByAssetPtrId": {
        "securitiesFiatassetByExternalassetPtrIdExists": true
      }
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useActiveFiatAssetsQuery",
    "selections": [
      {
        "alias": "assets",
        "args": (v0/*: any*/),
        "concreteType": "SecuritiesAssetsConnection",
        "kind": "LinkedField",
        "name": "allSecuritiesAssets",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SecuritiesAsset",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allSecuritiesAssets(filter:{\"isActive\":{\"equalTo\":true},\"securitiesExternalassetByAssetPtrId\":{\"securitiesFiatassetByExternalassetPtrIdExists\":true}})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useActiveFiatAssetsQuery",
    "selections": [
      {
        "alias": "assets",
        "args": (v0/*: any*/),
        "concreteType": "SecuritiesAssetsConnection",
        "kind": "LinkedField",
        "name": "allSecuritiesAssets",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SecuritiesAsset",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allSecuritiesAssets(filter:{\"isActive\":{\"equalTo\":true},\"securitiesExternalassetByAssetPtrId\":{\"securitiesFiatassetByExternalassetPtrIdExists\":true}})"
      }
    ]
  },
  "params": {
    "cacheID": "8f8dc033516d5cae17fd14bcb22ac28d",
    "id": null,
    "metadata": {},
    "name": "useActiveFiatAssetsQuery",
    "operationKind": "query",
    "text": "query useActiveFiatAssetsQuery {\n  assets: allSecuritiesAssets(filter: {securitiesExternalassetByAssetPtrId: {securitiesFiatassetByExternalassetPtrIdExists: true}, isActive: {equalTo: true}}) {\n    nodes {\n      code\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "405199ed6ca66525b7390f50fa0c706e";

export default node;
