import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.icon,
    minWidth: theme.spacing(6),
  },
  text: {
    textColor: (props) =>
      props.showText
        ? theme.palette.text.primary
        : theme.palette.background.paper,
  },
}));

const NavItem = ({
  icon,
  onClick,
  primaryText,
  secondaryText,
  // eslint-disable-next-line no-unused-vars
  showText = true,
}) => {
  const classes = useStyles({ showText });

  return (
    <ListItem button onClick={onClick}>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography className={classes.text} noWrap={true}>
            {primaryText}
          </Typography>
        }
        secondary={
          secondaryText && (
            <Typography className={classes.text} noWrap={true}>
              {secondaryText}
            </Typography>
          )
        }
      />
    </ListItem>
  );
};

export default React.memo(NavItem);
