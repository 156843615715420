/**
 * @generated SignedSource<<e679185e667707db4e097f65421a9279>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TransferActivityQuery$variables = {
  rowId: number;
};
export type TransferActivityQuery$data = {
  readonly transfer: {
    readonly networkAsset: {
      readonly asset: {
        readonly code: string;
        readonly decimals: number;
        readonly decimals_min: number;
        readonly icon: string | null;
        readonly use_static_icon: boolean;
      } | null;
    } | null;
    readonly note: string | null;
    readonly quantity: number;
  } | null;
};
export type TransferActivityQuery = {
  response: TransferActivityQuery$data;
  variables: TransferActivityQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rowId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "transactionPtrId",
    "variableName": "rowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantity",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decimals",
  "storageKey": null
},
v6 = {
  "alias": "decimals_min",
  "args": null,
  "kind": "ScalarField",
  "name": "decimalsMin",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v8 = {
  "alias": "use_static_icon",
  "args": null,
  "kind": "ScalarField",
  "name": "useStaticIcon",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransferActivityQuery",
    "selections": [
      {
        "alias": "transfer",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesTransfer",
        "kind": "LinkedField",
        "name": "securitiesTransferByTransactionPtrId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": "networkAsset",
            "args": null,
            "concreteType": "SecuritiesNetworkasset",
            "kind": "LinkedField",
            "name": "securitiesNetworkassetByAssetId",
            "plural": false,
            "selections": [
              {
                "alias": "asset",
                "args": null,
                "concreteType": "SecuritiesAsset",
                "kind": "LinkedField",
                "name": "securitiesAssetByAssetPtrId",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransferActivityQuery",
    "selections": [
      {
        "alias": "transfer",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesTransfer",
        "kind": "LinkedField",
        "name": "securitiesTransferByTransactionPtrId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": "networkAsset",
            "args": null,
            "concreteType": "SecuritiesNetworkasset",
            "kind": "LinkedField",
            "name": "securitiesNetworkassetByAssetId",
            "plural": false,
            "selections": [
              {
                "alias": "asset",
                "args": null,
                "concreteType": "SecuritiesAsset",
                "kind": "LinkedField",
                "name": "securitiesAssetByAssetPtrId",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "97c3411478446f7f5f72357dea3f86f9",
    "id": null,
    "metadata": {},
    "name": "TransferActivityQuery",
    "operationKind": "query",
    "text": "query TransferActivityQuery(\n  $rowId: Int!\n) {\n  transfer: securitiesTransferByTransactionPtrId(transactionPtrId: $rowId) {\n    note\n    quantity\n    networkAsset: securitiesNetworkassetByAssetId {\n      asset: securitiesAssetByAssetPtrId {\n        code\n        decimals\n        decimals_min: decimalsMin\n        icon\n        use_static_icon: useStaticIcon\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "11730e113319bfeb3dbe33eb90bebb8a";

export default node;
