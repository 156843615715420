import { ofType } from 'redux-observable'
import { of, map, mergeMap, filter, catchError, take } from 'rxjs'
import { SESSION_FETCHED } from './session';
import { Map } from 'immutable'
import { AssetsApiService } from '../services';

// action types
//
export const ASSETS_FETCHED = 'ASSETS:FETCHED'
export const ASSETS_FETCHED_ERROR = 'ASSETS:FETCHED_ERROR'

// action creators
//
export const ASSETS_FETCHED_ = assets =>
  ({ type: ASSETS_FETCHED, assets })
export const ASSETS_FETCHED_ERROR_ = (status, message) =>
({ type: ASSETS_FETCHED_ERROR, status, message })

export const reduce = (state = Map(), action) => {
  switch (action.type) {
  default:
    return state
  case ASSETS_FETCHED:
    return action.assets.reduce((acc, w) => acc.set(w.code, w), state)
  }
}

// epics
//
export const epics = [

  // Fetch assets
  //
  (action$, state$) => action$.pipe(
    ofType(SESSION_FETCHED),
    filter(action => action.session.isAuthorized),
    take(1),
    mergeMap(() =>
      new AssetsApiService(state$.value.session.access).getAll$().pipe(
        map(response => ASSETS_FETCHED_(response)),
      catchError(error => {
        of(ASSETS_FETCHED_ERROR_(error.status, error.message))})))),
]
