import { HttpService } from './HttpService';

// Methods ending with "$" return an observable using the rxjs/ajax library.
// Plain methods return the response if successful, null if error.

export class BaseApiService {
  http;

  constructor(url_prefix = '', access = '', onError = null) {
    this.http = new HttpService(url_prefix, access, onError);
  }

  getAll$ = (queryParams) => {
    return this.http.getAjax(``, queryParams);
  };

  getAll = async (queryParams) => {
    return this.http.getFetch(``, queryParams);
  };

  get$ = (id, queryParams) => {
    return this.http.getAjax(`${id}/`, queryParams);
  };

  get = async (id, queryParams) => {
    return this.http.getFetch(`${id}/`, queryParams);
  };

  post$ = (body, queryParams) => {
    return this.http.postAjax(``, body, queryParams);
  };

  post = async (body, queryParams) => {
    return this.http.postFetch(``, body, queryParams);
  };

  put$ = (id, body, queryParams) => {
    return this.http.putAjax(`${id}/`, body, queryParams);
  };

  put = async (id, body, queryParams) => {
    return this.http.putFetch(`${id}/`, body, queryParams);
  };

  patch$ = (id, body, queryParams) => {
    return this.http.patchAjax(`${id}/`, body, queryParams);
  };

  patch = (id, body, queryParams) => {
    return this.http.patchFetch(`${id}/`, body, queryParams);
  };

  delete$ = (id, queryParams) => {
    return this.http.deleteAjax(`${id}/`, queryParams);
  };
  delete = async (id, queryParams) => {
    return this.http.deleteFetch(`${id}/`, queryParams);
  };

}
