/**
 * @generated SignedSource<<a68edf3eba48b31a5a5219c7dfd9bd88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useAssetClassContentQuery$variables = {
  assetClassId: number;
  lang?: string | null;
};
export type useAssetClassContentQuery$data = {
  readonly assetClasses: {
    readonly classcontent: {
      readonly nodes: ReadonlyArray<{
        readonly code: string | null;
        readonly contentName: string | null;
        readonly tag: string | null;
        readonly text: string | null;
      } | null>;
    };
    readonly slug: string;
  } | null;
};
export type useAssetClassContentQuery = {
  response: useAssetClassContentQuery$data;
  variables: useAssetClassContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetClassId"
  },
  {
    "defaultValue": "en",
    "kind": "LocalArgument",
    "name": "lang"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "assetClassId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "languageCode",
      "variableName": "lang"
    }
  ],
  "concreteType": "SecuritiesAssetclassClasscontentConnection",
  "kind": "LinkedField",
  "name": "classcontent",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SecuritiesAssetclassClasscontentRecord",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAssetClassContentQuery",
    "selections": [
      {
        "alias": "assetClasses",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesAssetclass",
        "kind": "LinkedField",
        "name": "securitiesAssetclassByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAssetClassContentQuery",
    "selections": [
      {
        "alias": "assetClasses",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesAssetclass",
        "kind": "LinkedField",
        "name": "securitiesAssetclassByRowId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25e7c56744d03bc385c43658c2edcc76",
    "id": null,
    "metadata": {},
    "name": "useAssetClassContentQuery",
    "operationKind": "query",
    "text": "query useAssetClassContentQuery(\n  $assetClassId: Int!\n  $lang: String = \"en\"\n) {\n  assetClasses: securitiesAssetclassByRowId(rowId: $assetClassId) {\n    slug\n    classcontent(languageCode: $lang) {\n      nodes {\n        code\n        contentName\n        tag\n        text\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "63d69d4b745af8778650b101e6db40aa";

export default node;
