import { useState } from 'react';
import PropTypes from 'prop-types';

function useToggle(initialState = false) {
  const [toggle, setToggle] = useState(initialState);

  const handleToggle = () => {
    setToggle((p) => !p);
  };

  return [toggle, handleToggle];
}

useToggle.propTypes = {
  initialState: PropTypes.boolean,
};

export default useToggle;
