/**
 * @generated SignedSource<<7de42407dedf2047aef259c7aabc3990>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useAssetClassesQuery$variables = {
  lang?: string | null;
};
export type useAssetClassesQuery$data = {
  readonly assetClasses: {
    readonly nodes: ReadonlyArray<{
      readonly classcontent: {
        readonly nodes: ReadonlyArray<{
          readonly code: string | null;
          readonly contentName: string | null;
          readonly tag: string | null;
          readonly text: string | null;
        } | null>;
      };
      readonly isOpen: boolean;
      readonly slug: string;
    } | null>;
  } | null;
};
export type useAssetClassesQuery = {
  response: useAssetClassesQuery$data;
  variables: useAssetClassesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "en",
    "kind": "LocalArgument",
    "name": "lang"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOpen",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "languageCode",
      "variableName": "lang"
    }
  ],
  "concreteType": "SecuritiesAssetclassClasscontentConnection",
  "kind": "LinkedField",
  "name": "classcontent",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SecuritiesAssetclassClasscontentRecord",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAssetClassesQuery",
    "selections": [
      {
        "alias": "assetClasses",
        "args": null,
        "concreteType": "SecuritiesAssetclassesConnection",
        "kind": "LinkedField",
        "name": "allSecuritiesAssetclasses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SecuritiesAssetclass",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAssetClassesQuery",
    "selections": [
      {
        "alias": "assetClasses",
        "args": null,
        "concreteType": "SecuritiesAssetclassesConnection",
        "kind": "LinkedField",
        "name": "allSecuritiesAssetclasses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SecuritiesAssetclass",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d788115873ed5020cdda377ea860ee3",
    "id": null,
    "metadata": {},
    "name": "useAssetClassesQuery",
    "operationKind": "query",
    "text": "query useAssetClassesQuery(\n  $lang: String = \"en\"\n) {\n  assetClasses: allSecuritiesAssetclasses {\n    nodes {\n      slug\n      isOpen\n      classcontent(languageCode: $lang) {\n        nodes {\n          code\n          contentName\n          tag\n          text\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a2a8fd456be61eb3ab91dcc84d4edb8";

export default node;
