import React, { useState } from 'react';
import { format } from '../../utils/number';
import { post_text } from '../../utils/utils';
import { useIntl } from '../../hooks';
import GraphQL from '../GraphQL';
import ActivitySkeleton from './ActivitySkeleton';
import HandlePrivateOfferActivityQuery from './HandlePrivateOfferActivityQuery';
import { RequestActivityDisplay } from './RequestActivityDisplay';

import type { HandlePrivateOfferActivityQuery$data } from './__generated__/HandlePrivateOfferActivityQuery.graphql';
import { ActivityButtonHandlerDictionary, ActivityProps } from './types';

interface Props extends ActivityProps {
  sender: string;
  notificationId: number;
}

const HandlePrivateOfferActivity: React.FC<Props> = ({
  actionId,
  assets,
  notificationId,
  sender,
  locale,
  onRead,
  time,
  unread,
}) => {
  const translate = useIntl();
  const [nonce, setNonce] = useState(0);

  const handleApprove = () => {
    unread && onRead();
    post_text(`/transaction/accept/hold/${notificationId}/`).then(
      (r) => r.ok && setNonce(1 + nonce)
    );
  };

  const handleDecline = () => {
    unread && onRead();
    post_text(`/transaction/cancel/hold/${notificationId}/`).then(
      (r) => r.ok && setNonce(1 + nonce)
    );
  };

  return (
    <GraphQL
      Waiter={ActivitySkeleton}
      query={HandlePrivateOfferActivityQuery}
      variables={{ nonce, rowId: actionId }}
    >
      {({ request }: HandlePrivateOfferActivityQuery$data) => {
        const asset_ =
          request?.typeOf === 'B' ? request.asset?.code : request?.exAsset?.code;
        const quantity_ =
          request?.typeOf === 'B' ? request.quantity : request?.exQuantity;
        const cost_asset_ =
          request?.typeOf === 'S' ? request.asset?.code : request?.exAsset?.code;
        const cost_ =
          request?.typeOf === 'S' ? request.quantity : request?.exQuantity;
        const asset = asset_ ? assets.get(asset_) : undefined;
        const [quantity] = format(quantity_, asset, locale);
        const cost_asset = cost_asset_ ? assets.get(cost_asset_) : undefined;
        const [cost] = format(cost_, cost_asset, locale);

        const handlers: ActivityButtonHandlerDictionary =
          request?.status === 'pending'
            ? {
                primary: {
                  caption: translate.dialog.approve,
                  handler: handleApprove,
                },
                secondary: {
                  caption: translate.dialog.decline,
                  handler: handleDecline,
                },
              }
            : {};

        return (
          <RequestActivityDisplay
            asset={asset}
            badgeType='none'
            label='activity.handle_offer.label'
            template={translate.activity.handle_offer.text}
            variables={{
              sender: sender,
              cost: cost,
              quantity: quantity,
              cost_asset: cost_asset,
            }}
            note={request?.note ?? undefined}
            status={`${translate.request.status}: ${
              request?.status ? translate.activity.offer.status[request.status] : 'UNKNOWN_STATUS'
            }`}
            handlers={handlers}
            time={time}
            unread={unread}
            waitFor={request?.status !== 'pending'}
            onRead={onRead}
          />
        );
      }}
    </GraphQL>
  );
};

export default HandlePrivateOfferActivity;
