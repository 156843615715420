import { decode } from 'base64-arraybuffer';
import { Big } from 'big.js';
import { markets } from 'liquibook-lib';
import Humanize from 'humanize-plus';
import { get as getCookie } from 'es-cookie';

Big.NE = -11; // exponent notation safely outside 8 decimal points

export const decodeProtobuf = (protobuf_obj, protobuf_base64) => {
  let b = decode(protobuf_base64);
  let u8 = new Uint8Array(b);
  return protobuf_obj.decode(u8);
};

// Strip away the Protobuf settable-null px or sz structure
// returning its numeric value or null so that other nullable operators
// may be used with less code girth.
//
export const v_ = (val) =>
  val
    ? 'number' == typeof val
      ? val
      : Object.prototype.hasOwnProperty.call(val, 'val')
      ? val.val
      : null
    : null;

// fixed-point val to float
//
export const v2f = (val, asset) =>
  val && asset ? val / 10 ** asset.decimals : null;

// fixed-point price val to float
//
export const pv2f = (val, market) => v2f(val, market.quote_asset);

// fixed-point size val to float
//
export const sv2f = (val, market) => v2f(val, market.base_asset);

// market field price to float
//
export const mfpx2f = (field, market) => pv2f(mfv(market, field), market);

// market field size to float
//
export const mfsz2f = (field, market) => sv2f(mfv(market, field), market);

// format a nullable value
//
export const formatAsset = (val, asset) =>
  asset ? format(val, asset.decimals, asset.decimals_min) : '';

export const formatAssetFull = (val, asset) =>
  asset ? format(val, asset.decimals) : '';

// format a nullable value
//
// -- converts fixed decimals value integer
// -- won't show trailing 0s for decimals > 2
//
export const format = (val, decimals, decimalsToShow) => {
  if (val === 0) {
    return '0';
  } else if (val) {
    let v = Big(val).div(Big(10 ** decimals));
    let n = decimalsToShow === undefined ? decimals : decimalsToShow;
    return v.humanize(n);
  } else {
    return '';
  }
};

// add nullable values
//
export const add = (v1, v2) => (v1 == null || v2 == null ? null : v1 + v2);

// subtract nullable values
//
export const sub = (v1, v2) => (v1 == null || v2 == null ? null : v1 - v2);

// divide nullable values
//
export const div = (v1, v2) => (v1 == null || v2 == null ? null : v1 / v2);

// inverse
//
export const inv = (v) => (v ? 1 / v : null);

// given market fields, subtract field2 from field1
//
export const mfsub = (market, field1, field2) => {
  if (!market) {
    return null;
  }
  let f1 = market.quote[field1];
  let f2 = market.quote[field2];
  if (f1 && f2 && Object.prototype.hasOwnProperty.call(f1, 'val') && Object.prototype.hasOwnProperty.call(f2, 'val')) {
    return f1.val - f2.val;
  } else {
    return null;
  }
};

// given market field, return its value
//
export const mfv = (market, field) => {
  if (!market) {
    return null;
  }
  let f = market.quote[field];
  return f && Object.prototype.hasOwnProperty.call(f, 'val') ? f.val : null;
};

// given market value, format price
//
export const mvpx = (market, val, long) =>
  market ? (long ? formatAssetFull : formatAsset)(val, market.quote_asset) : '';

// given market field, format price
//
export const mfpx = (market, field, long) => {
  if (!market) {
    return '';
  }
  let f = market.quote[field];
  return f && Object.prototype.hasOwnProperty.call(f, 'val')
    ? (long === undefined ? formatAsset : formatAssetFull)(
        f.val,
        market.quote_asset
      )
    : '';
};

// given market value, format size
//
export const mvsz = (market, val, long) =>
  market ? (long ? formatAssetFull : formatAsset)(val, market.base_asset) : '';

// given market field, format size
//
export const mfsz = (market, field, long) => {
  if (!market) {
    return '';
  }
  let f = market.quote[field];
  return f && Object.prototype.hasOwnProperty.call(f, 'val')
    ? (long === undefined ? formatAsset : formatAssetFull)(
        f.val,
        market.base_asset
      )
    : '';
};

// given fixed-point decimal market vals sz and px, format cost (sz*px)
//
export const mvcost = (market, sz, px, long) =>
  market
    ? (long ? formatAssetFull : formatAsset)(
        px * (sz / 10 ** market.base_asset.decimals),
        market.quote_asset
      )
    : '';

// given fixed-point decimal market vals sz and px, return fixed-point cost val (sz * px)
//
export const mvcostv = (market, sz, px) =>
  market ? Math.ceil(px * (sz / 10 ** market.base_asset.decimals)) : null;

export const orderStatusCodeLabel = (code) => {
  switch (code) {
    default:
      return 'UNKNOWN';
    case markets.OrderStatusCode.OS_NEW:
      return 'NEW';
    case markets.OrderStatusCode.OS_PENDING:
      return 'PENDING';
    case markets.OrderStatusCode.OS_REJECTED:
      return 'REJECTED';
    case markets.OrderStatusCode.OS_OPEN:
      return 'OPEN';
    case markets.OrderStatusCode.OS_PARTIAL_FILL:
      return 'PARTIAL_FILL';
    case markets.OrderStatusCode.OS_FILLED:
      return 'FILLED';
    case markets.OrderStatusCode.OS_CANCELED:
      return 'CANCELED';
  }
};

export const orderStatusCodeShortLabel = (code) => {
  switch (code) {
    default:
      return '?';
    case markets.OrderStatusCode.OS_NEW:
      return 'N';
    case markets.OrderStatusCode.OS_PENDING:
      return 'P';
    case markets.OrderStatusCode.OS_REJECTED:
      return 'R';
    case markets.OrderStatusCode.OS_OPEN:
      return 'O';
    case markets.OrderStatusCode.OS_PARTIAL_FILL:
      return 'PF';
    case markets.OrderStatusCode.OS_FILLED:
      return 'F';
    case markets.OrderStatusCode.OS_CANCELED:
      return 'X';
  }
};

export const cancelStatusCodeLabel = (code) => {
  switch (code) {
    default:
      return 'UNKNOWN';
    case markets.CancelStatusCode.CS_NEW:
      return 'SENT';
    case markets.CancelStatusCode.CS_PENDING:
      return 'PENDING';
    case markets.CancelStatusCode.CS_ACCEPTED:
      return 'ACCEPTED';
    case markets.CancelStatusCode.CS_REJECTED:
      return 'REJECTED';
  }
};

// eslint-disable-next-line no-unused-vars
export const get = (url, object) =>
  fetch(url, {
    method: 'GET',
    headers: {
      //'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
  });

export const put = (url, object) =>
  fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
    body: JSON.stringify(object),
  });

export const patch = (url, object) =>
  fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
    body: JSON.stringify(object),
  });

export const post_text = (url, object) =>
  fetch(url, {
    method: 'POST',
    headers: {
      //'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
    body: JSON.stringify(object),
  });

export const post_public = (url, object) =>
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken')
    },
    body: JSON.stringify(object),
  });

export const post = (url, object) =>
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
      'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
    },
    body: JSON.stringify(object),
  });

export const fetch_delete = (url) =>
  fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCookie('csrftoken'),
    },
  });

export const localeNumberSeparators = (locale) => {
  let n = 1.1;
  let thousandSeparator;
  let decimalSeparator;

  if (n.toLocaleString(locale).search('\\.') === 1) {
    thousandSeparator = ',';
    decimalSeparator = '.';
  } else {
    thousandSeparator = '.';
    decimalSeparator = ',';
  }
  return [thousandSeparator, decimalSeparator];
};

export class HumanizeIt {
  constructor(locale) {
    this.locale = locale;
  }

  humanizeNumber = ({
    number,
    locale = window.navigator.language,
    fixedPointDecimals,
    // eslint-disable-next-line no-unused-vars
    minDecimalDisplay,
    shortDisplay = false,
  }) => {
    return number;
    // eslint-disable-next-line no-unreachable
    if (!number || Number.isNaN(number) || number === 'NaN') {
      return '';
    }

    if (this.locale) {
      locale = this.locale;
    }

    let dot;
    let numberSeparators;
    let thousandSeparator;
    let decimalSeparator;

    number = Big(number);
    if (!fixedPointDecimals) {
      dot = number.toString().search('\\.');
      if (dot >= 0)
        fixedPointDecimals = number
          .toString()
          .slice(number.toString().search('\\.') + 1).length;
      else fixedPointDecimals = 2;
    }

    numberSeparators = localeNumberSeparators(locale);

    thousandSeparator = numberSeparators[0];
    decimalSeparator = numberSeparators[1];

    if (shortDisplay) {
      let short = Humanize.compactInteger(
        number.toString(),
        fixedPointDecimals
      );
      short =
        Humanize.formatNumber(
          short.slice(0, -1),
          fixedPointDecimals,
          thousandSeparator,
          decimalSeparator
        ) + short.slice(-1);
      return short;
    }

    return Humanize.formatNumber(
      number.toString(),
      fixedPointDecimals,
      thousandSeparator,
      decimalSeparator
    );
  };
}
