import { useState, useEffect } from 'react';
import { fetchQuery } from 'react-relay';
import { environment } from '../relay';
import useCountriesQuery from './useCountriesQuery';

export default function useCountries() {
  const [countries, setCountries] = useState(null);

  useEffect(() => {
    fetchQuery(environment, useCountriesQuery).subscribe({
      next: (data) => {
        setCountries(data?.countries?.nodes);
      },
    });
  }, []);

  return countries;
}
