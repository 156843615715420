import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Stone } from '..';
import ActivityBox from './ActivityBox';
import ActivityIcon from './ActivityIcon';

import type { ActivityButtonHandlerDictionary, ActivityDisplayProps } from './types';
import type { AssetsStateValue } from '../../types/store';

interface Props extends ActivityDisplayProps {
  variables: {
    quantity: string;
    receiver?: string;
    sender?: string;
    cost?: string;
    cost_asset?: AssetsStateValue;
    profile?: string;
  };
  status?: string;
  handlers?: ActivityButtonHandlerDictionary;
  waitFor?: boolean;
}

export const RequestActivityDisplay: React.FC<Props> = ({
  asset,
  badgeType,
  label,
  template,
  variables,
  note,
  status,
  handlers,
  onRead,
  time,
  unread,
  waitFor,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { sender, receiver, profile, cost, quantity, cost_asset } = variables;
  return (
    <ActivityBox time={time} unread={unread} waitFor={waitFor} onRead={onRead}>
      <ActivityIcon asset={asset} badgeType={badgeType} />
      <Box p={1}>
        <Stone variant='h6'>{label}</Stone>
        <Typography>
          {
            // eslint-disable-next-line no-eval
            eval('`' + template + '`')
          }
        </Typography>
        {!(note === null || note === undefined || note.length === 0) && (
          <Typography color='textSecondary'>
            `&ldquo;`{note}`&rdquo;`
          </Typography>
        )}
        {status && (
          <Typography paragraph variant='caption' color='textSecondary'>
            {status}
          </Typography>
        )}
        <Box p={0} display='flex' flexDirection='row'>
          {handlers?.primary && (
            <Box pr={1}>
              <Button
                variant='contained'
                color='primary'
                onClick={handlers.primary.handler}
                disabled={handlers.primary?.disabled}
              >
                {handlers.primary.caption}
              </Button>
            </Box>
          )}
          {handlers?.secondary && (
            <Box p={0} display='flex' flexDirection='row'>
              <Button
                variant='outlined'
                color={handlers.primary ? 'default' : 'primary'}
                onClick={handlers.secondary.handler}
              >
                {handlers.secondary.caption}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </ActivityBox>
  );
};

