import React from 'react';
import NumberFormat from './NumberFormat';
import { TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  big: {
    fontSize: 40,
    lineHeight: 1.4,
  },
}));

const AssetNumberFormat = ({ asset, error, ...props }) => {
  const classes = useStyles();

  return (
    <NumberFormat
      allowNegative={false}
      decimalScale={asset.decimals}
      customInput={TextField}
      className={classes.big}
      error={error}
      {...props}
    />
  );
};

export default AssetNumberFormat;
