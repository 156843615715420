/**
 * @generated SignedSource<<6eb7842de29a439d9dafa6102682ebcf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetPreviewFragment_asset$data = {
  readonly classcontent: {
    readonly nodes: ReadonlyArray<{
      readonly code: string | null;
      readonly contentName: string | null;
      readonly tag: string | null;
      readonly text: string | null;
    } | null>;
  };
  readonly code: string;
  readonly derivatives: {
    readonly nodes: ReadonlyArray<{
      readonly classcontent: {
        readonly nodes: ReadonlyArray<{
          readonly code: string | null;
          readonly contentName: string | null;
          readonly tag: string | null;
          readonly text: string | null;
        } | null>;
      };
      readonly code: string;
      readonly ctype: {
        readonly model: string;
      } | null;
      readonly icon: string | null;
      readonly networkAsset: {
        readonly securityAsset: {
          readonly noteAsset: {
            readonly coupon: number;
          } | null;
        } | null;
      } | null;
      readonly sortTerm: string;
      readonly symbol: string | null;
    } | null>;
  };
  readonly icon: string | null;
  readonly image: {
    readonly file: {
      readonly path: string | null;
    } | null;
  } | null;
  readonly images: {
    readonly nodes: ReadonlyArray<{
      readonly image: {
        readonly file: {
          readonly path: string | null;
        } | null;
      } | null;
    } | null>;
  };
  readonly isOfferGroup: boolean;
  readonly isQuestionnaireForDerivatives: boolean;
  readonly name: string;
  readonly property: {
    readonly faceValue: number;
    readonly faceValueFiat: {
      readonly external: {
        readonly asset: {
          readonly code: string;
          readonly symbol: string | null;
        } | null;
      } | null;
    } | null;
    readonly grantor: {
      readonly displayName: string;
      readonly name: string;
    } | null;
    readonly manager: {
      readonly displayName: string;
      readonly name: string;
    } | null;
    readonly noteDate: any;
    readonly project: string;
  } | null;
  readonly underlying: {
    readonly classcontent: {
      readonly nodes: ReadonlyArray<{
        readonly code: string | null;
        readonly contentName: string | null;
        readonly tag: string | null;
        readonly text: string | null;
      } | null>;
    };
    readonly code: string;
    readonly isOfferGroup: boolean;
    readonly isQuestionnaireForDerivatives: boolean;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "AssetPreviewFragment_asset";
};
export type AssetPreviewFragment_asset$key = {
  readonly " $data"?: AssetPreviewFragment_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetPreviewFragment_asset">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOfferGroup",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isQuestionnaireForDerivatives",
  "storageKey": null
},
v5 = [
  {
    "alias": "file",
    "args": null,
    "concreteType": "FilerFile",
    "kind": "LinkedField",
    "name": "filerFileByFilePtrId",
    "plural": false,
    "selections": [
      {
        "alias": "path",
        "args": null,
        "kind": "ScalarField",
        "name": "file",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "languageCode",
      "variableName": "language"
    }
  ],
  "concreteType": "SecuritiesAssetClasscontentConnection",
  "kind": "LinkedField",
  "name": "classcontent",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SecuritiesAssetClasscontentRecord",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "language"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetPreviewFragment_asset",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": "image",
      "args": null,
      "concreteType": "FilerImage",
      "kind": "LinkedField",
      "name": "filerImageByFeaturedArtId",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    },
    {
      "alias": "images",
      "args": null,
      "concreteType": "SecuritiesAssetimagesConnection",
      "kind": "LinkedField",
      "name": "securitiesAssetimagesByAssetId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SecuritiesAssetimage",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": "image",
              "args": null,
              "concreteType": "FilerImage",
              "kind": "LinkedField",
              "name": "filerImageByImageId",
              "plural": false,
              "selections": (v5/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "property",
      "args": null,
      "concreteType": "SecuritiesPropertyasset",
      "kind": "LinkedField",
      "name": "securitiesPropertyassetByAssetPtrId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "noteDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "faceValue",
          "storageKey": null
        },
        {
          "alias": "faceValueFiat",
          "args": null,
          "concreteType": "SecuritiesFiatasset",
          "kind": "LinkedField",
          "name": "securitiesFiatassetByFaceValueQuoteAssetId",
          "plural": false,
          "selections": [
            {
              "alias": "external",
              "args": null,
              "concreteType": "SecuritiesExternalasset",
              "kind": "LinkedField",
              "name": "securitiesExternalassetByExternalassetPtrId",
              "plural": false,
              "selections": [
                {
                  "alias": "asset",
                  "args": null,
                  "concreteType": "SecuritiesAsset",
                  "kind": "LinkedField",
                  "name": "securitiesAssetByAssetPtrId",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v6/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "grantor",
          "args": null,
          "concreteType": "SecuritiesProfile",
          "kind": "LinkedField",
          "name": "securitiesProfileByGrantorId",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        },
        {
          "alias": "manager",
          "args": null,
          "concreteType": "SecuritiesProfile",
          "kind": "LinkedField",
          "name": "securitiesProfileByPropertyManagerId",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v8/*: any*/),
    {
      "alias": "derivatives",
      "args": [
        {
          "kind": "Literal",
          "name": "condition",
          "value": {
            "isActive": true,
            "visible": true,
            "visibleInvest": true
          }
        }
      ],
      "concreteType": "SecuritiesAssetsConnection",
      "kind": "LinkedField",
      "name": "securitiesAssetsByUnderlyingId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SecuritiesAsset",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sortTerm",
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": "ctype",
              "args": null,
              "concreteType": "DjangoContentType",
              "kind": "LinkedField",
              "name": "djangoContentTypeByPolymorphicCtypeId",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": "networkAsset",
              "args": null,
              "concreteType": "SecuritiesNetworkasset",
              "kind": "LinkedField",
              "name": "securitiesNetworkassetByAssetPtrId",
              "plural": false,
              "selections": [
                {
                  "alias": "securityAsset",
                  "args": null,
                  "concreteType": "SecuritiesSecurityasset",
                  "kind": "LinkedField",
                  "name": "securitiesSecurityassetByNetworkassetPtrId",
                  "plural": false,
                  "selections": [
                    {
                      "alias": "noteAsset",
                      "args": null,
                      "concreteType": "SecuritiesNoteasset",
                      "kind": "LinkedField",
                      "name": "securitiesNoteassetBySecurityassetPtrId",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "coupon",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v8/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "securitiesAssetsByUnderlyingId(condition:{\"isActive\":true,\"visible\":true,\"visibleInvest\":true})"
    },
    {
      "alias": "underlying",
      "args": null,
      "concreteType": "SecuritiesAsset",
      "kind": "LinkedField",
      "name": "securitiesAssetByUnderlyingId",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "SecuritiesAsset",
  "abstractKey": null
};
})();

(node as any).hash = "eb4dfeb5452165941cd4cca35532e348";

export default node;
