import React from 'react'
import { Box, Button, Fade } from '@material-ui/core'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { DoneIcon } from '../icons'

export default ({text}) => {
  const [copied, setCopied] = React.useState(0)
  return (
    <Box p={0} display="flex" flexDirection="row" alignItems="center">
      <Box>
        <CopyToClipboard
            text={text}
            onCopy={() => {
              setCopied(1)
              setTimeout(() => {
                setCopied(2)
                setTimeout(() => {
                  setCopied(0)
                }, 750)
              }, 250)
            }}>
          <Button variant="text">{text}</Button>
        </CopyToClipboard>
      </Box>
      <Box pl={1}>
        {
          copied > 0
          &&
          <Fade
              in={ copied === 1 }
              timeout={{ enter: 0, exit: 750 }}>
            <DoneIcon/>
          </Fade>
        }
      </Box>
    </Box>
  )
}