import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMoreIcon } from '../../icons';
import { AgentBankInfo, LinkInfo, Stone } from '../../components';
import { useIntl } from '../../hooks';
import StreetTicketActivityDisplay from './StreetTicketActivityDisplay';

import type {
  ActivityButtonHandlerDictionary,
  ActivityDisplayProps,
  StreetActivityCounterParty,
  StreetActivityMethod,
  StreetActivityStatus,
  StreetActivityVariables,
} from './types';

interface Props extends ActivityDisplayProps {
  variables: StreetActivityVariables;
  counterparty: StreetActivityCounterParty;
  method: StreetActivityMethod;
  status: StreetActivityStatus;
  bank: string;
  setBank: React.Dispatch<React.SetStateAction<string>>;
  reference: string;
  setReference: React.Dispatch<React.SetStateAction<string>>;
  // eslint-disable-next-line no-unused-vars
  handleAction: (action: number | string | undefined, props?: Record<string, unknown>) => () => void;
}

const StreetTicketActivityForTakerBuy: React.FC<Props> = ({
  asset,
  badgeType,
  label,
  template,
  variables,
  counterparty,
  method,
  status,
  time,
  unread,
  onRead,
  bank,
  setBank,
  reference,
  setReference,
  handleAction,
}) => {
  const translate = useIntl();
  // eslint-disable-next-line no-unused-vars
  const { quantity, price, cost, quote_asset } = variables;

  const handlers: ActivityButtonHandlerDictionary =
    status.code === 'pledged' || status.code === 'escrowed'
      ? {
          secondary: {
            caption: translate.dialog.cancel,
            handler: handleAction('cancel'),
          },
        }
      : status.code === 'accepted'
      ? {
          primary: {
            caption: translate.street_ticket.ship.label,
            handler: handleAction('ship', { bank, reference }),
          },
          secondary: {
            caption: translate.dialog.cancel,
            handler: handleAction('cancel'),
          },
        }
      : status.code === 'shipped'
      ? {
          primary: {
            // eslint-disable-next-line no-eval
            caption: eval(
              '`' + translate.street_ticket.return_escrow.label + '`'
            ),
            handler: handleAction('return'),
            color: 'default',
          },
        }
      : {};

  return (
    <StreetTicketActivityDisplay
      asset={asset}
      badgeType={badgeType}
      label={label}
      template={template}
      variables={variables}
      handlers={handlers}
      counterparty={counterparty}
      method={method}
      status={status}
      time={time}
      unread={unread}
      onRead={onRead}
    >
      <Box p={1}>
        {status.code === 'pledged' ? null : status.code === 'accepted' ? (
          method.name === 'paypal' ? null : (
            <Box pt={1}>
              <Typography paragraph>
                {
                  // eslint-disable-next-line no-eval
                  eval('`' + translate.street_ticket.dest.description + '`')
                }
              </Typography>
              {method.variant === 'mobile' ? (
                method?.link ? (
                  <LinkInfo link={method.link} />
                ) : (
                  <div />
                )
              ) : (
                <AgentBankInfo asset={method.linkAsset} agent={method.agent} />
              )}
              <br />
              <br />
              <Stone>
                {`street_ticket.action.buy.taker.ship.${method.variant}`}
              </Stone>
              <Box pt={1} display='flex'>
                <Box p={1}>
                  <TextField
                    label='Originating Bank'
                    value={bank}
                    onChange={(e) => setBank(e.target.value)}
                  />
                </Box>
                <Box p={1}>
                  <TextField
                    label='Reference#'
                    value={reference}
                    onChange={(e) => setReference(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>
          )
        ) : status.code === 'shipped' ? (
          method.name === 'paypal' ? null : (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stone>street_ticket.dest.title</Stone>
              </AccordionSummary>
              <AccordionDetails>
                {method.variant === 'mobile' ? (
                  method?.link ? (
                    <LinkInfo link={method.link} />
                  ) : (
                    <div />
                  )
                ) : (
                  <AgentBankInfo
                    asset={method.linkAsset}
                    agent={method.agent}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          )
        ) : null}
      </Box>
    </StreetTicketActivityDisplay>
  );
};

export default StreetTicketActivityForTakerBuy;
