import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import PerkDialog from './PerkDialog';
import PerkJoinDialog from './PerkJoinDialog';
import Perk from './Perk';
import { Auth, NotOwn } from './Own';

const PerkList = ({ onChange, venue }) => {
  const [perkOpen, setPerkOpen] = useState(false);
  const [selectedPerk, setSelectedPerk] = useState(null);

  const handlePerkClick = perk => {
    setSelectedPerk(perk);
    setPerkOpen(true);
  };

  const handlePerkSubmit = () => {
    setPerkOpen(false);
    setSelectedPerk(null);
    onChange && onChange();
  };

  const handlePerkCancel = () => {
    setPerkOpen(false);
    setSelectedPerk(null);
  };

  return (
    <>
      {venue.perks?.nodes.map(
        (perk, index) => (
          <Box pt={1} key={index}>
            <Perk
                perk={perk}
                onChange={onChange}
                onClick={() => handlePerkClick(perk)}
            />
          </Box>
        )
      )}
      <Auth own={venue.pocket?.sponsor ?? venue}>
        <PerkDialog
            open={perkOpen}
            perk={selectedPerk}
            onCancel={handlePerkCancel}
            onSubmit={handlePerkSubmit}
        />
      </Auth>
      <Auth>
        <NotOwn of={venue.pocket?.sponsor ?? venue}>
          <PerkJoinDialog
              open={perkOpen}
              perk={selectedPerk}
              onCancel={handlePerkCancel}
              onClose={handlePerkCancel}
              onSubmit={handlePerkSubmit}
          />
        </NotOwn>
      </Auth>
    </>
  );
};

export default PerkList;
