/**
 * @generated SignedSource<<f573a45ee5e3ab59fe6d2711eea34cfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useAssetExploreQuery$variables = {
  language: string;
};
export type useAssetExploreQuery$data = {
  readonly classes: {
    readonly nodes: ReadonlyArray<{
      readonly classcontent: {
        readonly nodes: ReadonlyArray<{
          readonly code: string | null;
          readonly contentName: string | null;
          readonly tag: string | null;
          readonly text: string | null;
        } | null>;
      };
      readonly image: {
        readonly file: {
          readonly path: string | null;
        } | null;
      } | null;
      readonly slug: string;
      readonly sortTerm: string;
    } | null>;
  } | null;
};
export type useAssetExploreQuery = {
  response: useAssetExploreQuery$data;
  variables: useAssetExploreQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "language"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "isVisible": {
        "equalTo": true
      }
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortTerm",
  "storageKey": null
},
v4 = {
  "alias": "path",
  "args": null,
  "kind": "ScalarField",
  "name": "file",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "languageCode",
      "variableName": "language"
    }
  ],
  "concreteType": "SecuritiesAssetclassClasscontentConnection",
  "kind": "LinkedField",
  "name": "classcontent",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SecuritiesAssetclassClasscontentRecord",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAssetExploreQuery",
    "selections": [
      {
        "alias": "classes",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesAssetclassesConnection",
        "kind": "LinkedField",
        "name": "allSecuritiesAssetclasses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SecuritiesAssetclass",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": "image",
                "args": null,
                "concreteType": "FilerImage",
                "kind": "LinkedField",
                "name": "filerImageByImageId",
                "plural": false,
                "selections": [
                  {
                    "alias": "file",
                    "args": null,
                    "concreteType": "FilerFile",
                    "kind": "LinkedField",
                    "name": "filerFileByFilePtrId",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allSecuritiesAssetclasses(filter:{\"isVisible\":{\"equalTo\":true}})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAssetExploreQuery",
    "selections": [
      {
        "alias": "classes",
        "args": (v1/*: any*/),
        "concreteType": "SecuritiesAssetclassesConnection",
        "kind": "LinkedField",
        "name": "allSecuritiesAssetclasses",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SecuritiesAssetclass",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": "image",
                "args": null,
                "concreteType": "FilerImage",
                "kind": "LinkedField",
                "name": "filerImageByImageId",
                "plural": false,
                "selections": [
                  {
                    "alias": "file",
                    "args": null,
                    "concreteType": "FilerFile",
                    "kind": "LinkedField",
                    "name": "filerFileByFilePtrId",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "allSecuritiesAssetclasses(filter:{\"isVisible\":{\"equalTo\":true}})"
      }
    ]
  },
  "params": {
    "cacheID": "1597732bf516c0c83aa737a5e3a58fff",
    "id": null,
    "metadata": {},
    "name": "useAssetExploreQuery",
    "operationKind": "query",
    "text": "query useAssetExploreQuery(\n  $language: String!\n) {\n  classes: allSecuritiesAssetclasses(filter: {isVisible: {equalTo: true}}) {\n    nodes {\n      slug\n      sortTerm\n      image: filerImageByImageId {\n        file: filerFileByFilePtrId {\n          path: file\n          id\n        }\n        id\n      }\n      classcontent(languageCode: $language) {\n        nodes {\n          code\n          contentName\n          tag\n          text\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8555b2ab93173e13d2994fd581854db";

export default node;
