import React from 'react';
import { useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  Typography,
  Button,
  LinearProgress,
  FormControlLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';
import PrecisePrice from './PrecisePrice';
import { useError, useIntl, useApiService } from '../hooks';
import { MembershipsApiService } from '../services';
import * as Yup from 'yup';
import FormikAssetQuantityInput from './fields/FormikAssetQuantityInput';
import { ResponsiveDialog as Dialog } from '.';

const JoinGroupDialog = ({
  // eslint-disable-next-line no-unused-vars
  dispatch,
  perk,
  onCancel,
  onSubmit,
  ...props
}) => {
  const translate = useIntl();
  const assets = useSelector((state) => state.assets);
  const wallets = useSelector((state) => state.wallets);
  const onError = useError();
  const membershipsApi = useApiService(MembershipsApiService);

  if (!perk) {
    return <div />;
  }
  const wallet = wallets.get(perk.quote.asset.code);
  const available = wallet?.available ?? 0;
  const asset = assets.get(perk.quote.asset.code);
  if (!asset) {
    return <LinearProgress />;
  }

  const toFixedInt = (floating) =>
    floating ? parseInt(floating * 10 ** asset.decimals) : '';
  const dues = {
    lifetime: toFixedInt(perk.duesLifetime),
    annual: toFixedInt(perk.duesAnnual),
    monthly: toFixedInt(perk.duesMonthly),
    weekly: toFixedInt(perk.duesWeekly),
    hourly: toFixedInt(perk.duesDailiy),
    daily: toFixedInt(perk.duesHourly),
    minute: toFixedInt(perk.duesMinute),
  };

  const choiceDefault = dues.lifetime
    ? 'lifetime'
    : dues.annual
    ? 'annual'
    : dues.monthly
    ? 'monthly'
    : dues.weekly
    ? 'weekly'
    : dues.daily
    ? 'daily'
    : dues.hourly
    ? 'hourly'
    : dues.minute
    ? 'minute'
    : 'lifetime'; // e.g. membership 'none' is not bound by time
  return (
    <Formik
      key={perk.rowId}
      initialValues={{
        perkId: perk.rowId,
        choice: choiceDefault,
        payment: dues[choiceDefault] ?? '',
      }}
      isInitialValid={available >= dues[choiceDefault]}
      validateOnMount={true}
      validationSchema={Yup.object().shape({
        choice: Yup.string().required(),
        payment: Yup.number()
          .positive()
          .max(available, translate.groups.errors.insf)
          .when('choice', (choice, schema) =>
            perk.membership === 'none' ? schema : schema.min(dues[choice])
          ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        membershipsApi.post(values).then((response) => {
          setSubmitting(false);
          response.ok ? onSubmit() : onError(response.statusText);
        });
      }}
      render={({
        errors,
        isSubmitting,
        isValid,
        setFieldValue,
        submitForm,
        // eslint-disable-next-line no-unused-vars
        touched,
        values,
      }) => {
        const dueNow = dues[values.choice];
        const choiceprops = {
          asset,
          dues,
          isSubmitting,
          setFieldValue,
          translate,
        };
        return (
          <Form>
            <Dialog fullWidth={true} maxWidth='xs' {...props}>
              <DialogTitle>{translate.groups.join.title}</DialogTitle>
              <DialogContent>
                <Typography paragraph>{perk.description}</Typography>
                {perk.membership !== 'none' && !!dueNow && (
                  <>
                    <Typography paragraph>
                      {translate.groups.dues.required}
                    </Typography>
                    <Box py={1}>
                      <Field name='choice' component={RadioGroup}>
                        {!!dues.minute && (
                          <FeeChoice choice='minute' {...choiceprops} />
                        )}
                        {!!dues.hourly && (
                          <FeeChoice choice='hourly' {...choiceprops} />
                        )}
                        {!!dues.daily && (
                          <FeeChoice choice='daily' {...choiceprops} />
                        )}
                        {!!dues.weekly && (
                          <FeeChoice choice='weekly' {...choiceprops} />
                        )}
                        {!!dues.monthly && (
                          <FeeChoice choice='monthly' {...choiceprops} />
                        )}
                        {!!dues.annual && (
                          <FeeChoice choice='annual' {...choiceprops} />
                        )}
                        {!!dues.lifetime && (
                          <FeeChoice choice='lifetime' {...choiceprops} />
                        )}
                      </Field>
                    </Box>
                  </>
                )}
                {perk.membership !== 'none' &&
                (!!dueNow || perk.isFlexibleFee) ? (
                  <Box
                    py={1}
                    display='flex'
                    flexDirection='row'
                    alignItems='center'
                  >
                    <Box flexGrow='1' />
                    <FormControl>
                      <Box p={1}>
                        <Typography>
                          {translate.groups.join.total_due_now}
                        </Typography>
                      </Box>
                      <FormikAssetQuantityInput
                        name='payment'
                        asset={asset}
                        disabled={!perk.isFlexibleFee}
                      />
                      <FormHelperText></FormHelperText>
                    </FormControl>
                  </Box>
                ) : null}
                {isSubmitting && <LinearProgress />}
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color='primary'>
                  {translate.groups.dialog.cancel}
                </Button>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={!!errors.length || !isValid || isSubmitting}
                  onClick={submitForm}
                >
                  {translate.groups.join.cta}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    />
  );
};

const FeeChoice = ({
  asset,
  choice,
  dues,
  isSubmitting,
  setFieldValue,
  translate,
}) => {
  const payment = dues[choice];
  const savings =
    choice === 'annual'
      ? dues.weekly
        ? parseInt(dues.weekly * 52 - dues.annual)
        : dues.monthly
        ? parseInt(dues.monthly * 12 - dues.annual)
        : 0
      : choice === 'monthly'
      ? dues.weekly
        ? parseInt((dues.weekly * 52) / 12 - dues.monthly)
        : 0
      : 0;
  return (
    <FormControlLabel
      value={choice}
      control={
        <Radio
          disabled={isSubmitting}
          onClick={() => {
            setFieldValue('choice', choice, false);
            setFieldValue('payment', payment, true);
          }}
        />
      }
      label={
        <Box display='flex' flexDirection='row' alignItems='center'>
          <Box pr={1}>{translate.groups.dues[choice]}</Box>
          <PrecisePrice asset={asset} value={payment} suffix={asset.code} />
          {savings > 0 && (
            <>
              <Box p={1}>
                <Typography color='secondary'>
                  - {translate.groups.dues.save}
                </Typography>
              </Box>
              <PrecisePrice asset={asset} value={savings} color='secondary' />
            </>
          )}
        </Box>
      }
      disabled={isSubmitting}
    />
  );
};

export default JoinGroupDialog;
