import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Stone } from '../../components';
import ActivityBox from './ActivityBox';
import ActivityIcon from './ActivityIcon';

import type { ActivityDisplayProps } from './types';

interface Props extends ActivityDisplayProps {
  variables: { quantity: string; receiver: string; sender: string };
}

const TransferActivityDisplay: React.FC<Props> = ({
  asset,
  badgeType,
  label,
  template,
  variables,
  note,
  onRead,
  time,
  unread,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { quantity, receiver, sender } = variables;
  return (
    <ActivityBox time={time} unread={unread} onRead={onRead}>
      <ActivityIcon asset={asset} badgeType={badgeType} />
      <Box p={1}>
        <Stone variant='h6'>{label}</Stone>
        <Typography>
          {
            // template uses quantity, asset.code, and receiver or sender
            // eslint-disable-next-line no-eval
            eval('`' + template + '`')
          }
        </Typography>
        <Typography color='textSecondary'>{note}</Typography>
      </Box>
    </ActivityBox>
  );
};

export default TransferActivityDisplay;
