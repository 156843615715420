import React from 'react';
import { DateTime } from 'luxon';
import { Box, Typography } from '@material-ui/core';
import { DotIcon as Dot } from '../../icons';
import MarkReadOnVisible from './MarkReadOnVisible';
import { ActivityProps } from './types';

interface Props extends Pick<ActivityProps, 'time' | 'unread' | 'onRead'> {
  waitFor?: boolean;
}

const ActivityBox: React.FC<Props> = ({
  time,
  unread,
  onRead,
  children,
  waitFor = true,
  ...rest
}) => {
  return (
    <MarkReadOnVisible unread={unread} onRead={onRead} waitFor={waitFor}>
      <Box pl={2} pb={1} {...rest}>
        <Typography color='textSecondary' variant='caption'>
          {unread && <Dot fontSize='small' color='secondary' />}
          {time.toLocaleString(DateTime.TIME_SIMPLE)}
        </Typography>
        <Box
          display='flex'
          flexDirection='row'
          alignContent='flex-start'
          alignItems='center'
        >
          {children}
        </Box>
      </Box>
    </MarkReadOnVisible>
  );
};

export default ActivityBox;
