import { useState, useEffect } from 'react';
import { fetchQuery } from 'react-relay';
import { environment } from '../relay';
import { useLang } from '.';
import useAssetClassesQuery from './useAssetClassesQuery';

export default function useAssetClasses() {
  const locale = useLang();
  const [value, setValue] = useState(null);

  useEffect(() => {
    fetchQuery(environment, useAssetClassesQuery, {
      lang: locale,
    }).subscribe({
      next: (data) => {
        setValue(
          data?.assetClasses?.nodes.map((assetClass) => ({
            ...assetClass,
            ...assetClass.classcontent?.nodes.reduce(
              (a, n) => ({ ...a, [n.contentName]: n.text }),
              {}
            ),
          }))
        );
      },
    });
  }, [setValue, locale]);

  return value;
}
