/**
 * @generated SignedSource<<7112f5372339dfbcd5eceee48351f7a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LedgerFeedFragment_wallet$data = {
  readonly asset: {
    readonly isActive: boolean;
    readonly isBurnEnabled: boolean;
    readonly isMintEnabled: boolean;
  } | null;
  readonly balance: number;
  readonly id: string;
  readonly ledger: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly creditDebit: number;
        readonly id: string;
        readonly tx: {
          readonly time: any;
          readonly type: {
            readonly model: string;
          } | null;
        } | null;
      } | null;
    }>;
  };
  readonly " $fragmentType": "LedgerFeedFragment_wallet";
};
export type LedgerFeedFragment_wallet$key = {
  readonly " $data"?: LedgerFeedFragment_wallet$data;
  readonly " $fragmentSpreads": FragmentRefs<"LedgerFeedFragment_wallet">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": [
        "PRIMARY_KEY_DESC"
      ],
      "kind": "LocalArgument",
      "name": "orderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "ledger"
        ]
      }
    ]
  },
  "name": "LedgerFeedFragment_wallet",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "balance",
      "storageKey": null
    },
    {
      "alias": "asset",
      "args": null,
      "concreteType": "SecuritiesAsset",
      "kind": "LinkedField",
      "name": "securitiesAssetByAssetId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isBurnEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isMintEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "ledger",
      "args": [
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        }
      ],
      "concreteType": "SecuritiesLedgersConnection",
      "kind": "LinkedField",
      "name": "__LedgerFeed_ledger_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SecuritiesLedgersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SecuritiesLedger",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "creditDebit",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": "tx",
                  "args": null,
                  "concreteType": "SecuritiesTransaction",
                  "kind": "LinkedField",
                  "name": "securitiesTransactionByTransactionId",
                  "plural": false,
                  "selections": [
                    {
                      "alias": "type",
                      "args": null,
                      "concreteType": "DjangoContentType",
                      "kind": "LinkedField",
                      "name": "djangoContentTypeByPolymorphicCtypeId",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "model",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "time",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SecuritiesWallet",
  "abstractKey": null
};
})();

(node as any).hash = "34ac450096590e413a0ca54b8b47090b";

export default node;
