import { ofType } from 'redux-observable';
import { interval, ignoreElements, tap, filter, throttle } from 'rxjs';
import { ORDERS_RX_FILL, OS_CANCELED, ORDERS_RX_STATUS } from './orders';

import aw from '../audio/aw.mp3';
import ding from '../audio/ding.mp3';

let context;
let dingBuffer = null;
let awBuffer = null;

export function initAudioContext() {
  try {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    context = new AudioContext();
    loadSound(ding, buf => dingBuffer = buf);
    loadSound(aw, buf => awBuffer = buf);
  }
  catch (e) {
    console.log('Web Audio API is not supported in this browser');
  }
}

// eslint-disable-next-line no-unused-vars
function loadSound(url, next) {
  var request = new XMLHttpRequest();
  request.open('GET', url, true);
  request.responseType = 'arraybuffer';
  request.onload = function () {
    context.decodeAudioData(request.response, next);
  };
  request.send();
}

function playSound(buffer) {
  var source = context.createBufferSource();
  source.buffer = buffer;
  source.connect(context.destination);
  source.start(0);
}

function playDing() {
  playSound(dingBuffer);
}

function playAw() {
  playSound(awBuffer);
}

export const epics = [
  // audio alert for fills
  //
  // eslint-disable-next-line no-unused-vars
  (action$, state$) =>
    action$.pipe(
      ofType(ORDERS_RX_FILL),
      throttle(() => interval(1000)),
      // eslint-disable-next-line no-unused-vars
      tap((action) => playDing()),
      ignoreElements()
    ),

  // audio alert for cancels
  //
  // eslint-disable-next-line no-unused-vars
  (action$, state$) =>
    action$.pipe(
      ofType(ORDERS_RX_STATUS),
      filter((action) => action.status.event.code === OS_CANCELED),
      throttle(() => interval(1000)),
      // eslint-disable-next-line no-unused-vars
      tap((action) => playAw()),
      ignoreElements()
    ),
];
