/**
 * @generated SignedSource<<75d27a863bb5bf62d2ef88acdb1b3a46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useLocalesQuery$variables = {};
export type useLocalesQuery$data = {
  readonly allLocales: {
    readonly nodes: ReadonlyArray<{
      readonly code: string;
      readonly flag: string | null;
      readonly name: string;
    } | null>;
  } | null;
  readonly userLocales: {
    readonly nodes: ReadonlyArray<{
      readonly code: string;
      readonly flag: string | null;
      readonly name: string;
    } | null>;
  } | null;
};
export type useLocalesQuery = {
  response: useLocalesQuery$data;
  variables: useLocalesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "isUserSelectable": {
        "equalTo": true
      }
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flag",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RosettaLocale",
    "kind": "LinkedField",
    "name": "nodes",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RosettaLocale",
    "kind": "LinkedField",
    "name": "nodes",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useLocalesQuery",
    "selections": [
      {
        "alias": "userLocales",
        "args": (v0/*: any*/),
        "concreteType": "RosettaLocalesConnection",
        "kind": "LinkedField",
        "name": "allRosettaLocales",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": "allRosettaLocales(filter:{\"isUserSelectable\":{\"equalTo\":true}})"
      },
      {
        "alias": "allLocales",
        "args": null,
        "concreteType": "RosettaLocalesConnection",
        "kind": "LinkedField",
        "name": "allRosettaLocales",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useLocalesQuery",
    "selections": [
      {
        "alias": "userLocales",
        "args": (v0/*: any*/),
        "concreteType": "RosettaLocalesConnection",
        "kind": "LinkedField",
        "name": "allRosettaLocales",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": "allRosettaLocales(filter:{\"isUserSelectable\":{\"equalTo\":true}})"
      },
      {
        "alias": "allLocales",
        "args": null,
        "concreteType": "RosettaLocalesConnection",
        "kind": "LinkedField",
        "name": "allRosettaLocales",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d2f475ea85724dfeb018f57c08ac34f",
    "id": null,
    "metadata": {},
    "name": "useLocalesQuery",
    "operationKind": "query",
    "text": "query useLocalesQuery {\n  userLocales: allRosettaLocales(filter: {isUserSelectable: {equalTo: true}}) {\n    nodes {\n      code\n      name\n      flag\n      id\n    }\n  }\n  allLocales: allRosettaLocales {\n    nodes {\n      code\n      name\n      flag\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f97a8dcd99a0616e685c1f23f19a944";

export default node;
