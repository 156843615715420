// https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s21.html

/* eslint-disable no-useless-escape */

import validate_ar from 'cuit-validator'

const US = {
  person: {
    label: 'SSN',
    regex: /^(\d{3}\-\d{2}\-\d{4})$/,
    mask: '999-99-9999'
  },
  business: {
    label: 'Tax ID',
    regex: /^(\d{2}\-\d{7})$/,
    mask: '99-9999999'
  }
}

const countries = {
  US: US,
  PR: US,
  AR: {
    label: 'CUIT',
    regex: /^\d{2}\-\d{8}\-\d{1}$/,  // (20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]
    mask: '99-99999999-9',
    validate: validate_ar
  },
  VE: {
    person: {
      label: 'CI',
      regex: /^((V|E|P|C)\d{1,9})$/,
      mask: 'V/E/C/P999999999'
    },
    business: {
      label: 'RIF',
      regex: /^((G|J)\d{1,15})$/,
      mask: 'J/G999999999999999'
    }
  },
  BR: {
    label: 'CPF',
    person: {
      regex: /^(\d{3}\.\d{3}\.\d{3}\-\d{2})$/,
      mask: '999.999.999-99'
    },
    business: {
      regex: /^(\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2})$/,
      mask: '99.999.999/9999-99'
    }
  },
  MX: {
    label: 'RFC',
    regex: /^[A-Za-z]{4}[ |\-]{0,1}[0-9]{6}[ |\-]{0,1}[0-9A-Za-z]{3}$/,
    mask: 'AAAA 999999 AAA'
  },
  CO: {
    label: 'NUIP',
    regex: /^\d{9}|\d{10}$/,
    mask: '9999999999'
  },
  IT: {
    label: 'CF',
    person: {
      regex: /^\w{16}$/,
      mask: 'AAAAAAAAAAAAAAAA'
    },
    business: {
      regex: /^[0-9]{11}$/,
      mask: '999999999999'
    }
  },
  ES: {
    // https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/SPAIN-TIN.pdf
    person: {
      label: 'NIF/NIE',
      regex: /^\d|[LKXYZM]\d{7}[A-Z]$/,
      mask: '99999999A / A9999999A'
    },
    business: {
      label: 'CIF',
      regex: /^[ABCDEFGHJNPQSUVW]\d{8}$/,
      mask: 'A9999999A'
    }
  },
  EU: {
    mask: 'AAAAAAAAAAAAAAAA / A-9999999-A / 999999999999 / A-99999999',
    person: {
      label: 'CF NIF/NIE',
      regex: /^\w{16}$|[A-Z]\-[0-9]{7}\-[A-Z]$|[0-9]{11}$|[A-Z]\-[0-9]{8}$/,
      mask: 'AAAAAAAAAAAAAAAA / A-9999999-A / 999999999999 / A-99999999'
    },
    business: {
      label: 'CF CIF',
      regex: /^[0-9]{11}|[A-Z]\-[0-9]{8}$/,
      mask: '999999999999 / A-99999999'
    }
  },
  AT: {
    label: 'TIN',
    regex: /^\d{2}\-\d{3}\-\d{4}$/,
    mask: '99-999/9999'
  },
  BE: {
    label: 'TIN',
    person: {
      regex: /^\d{11}$/,
      mask: '99999999999'
    },
    business: {
      regex: /^\d{10}$/,
      mask: '9999999999'
    }
  },
  CA: {
    person: {
      label: 'SIN',
      regex: /^\d{9}$/,
      mask: '999999999'
    },
    business: {
      label: 'TIN',
      regex: /^\d{9}[A-Z]{2}\d{4}$/,
      mask: '999999999AA9999'
    }
  },
  CH: {
    person: {
      label: 'OASI',
      regex: /^756\.\d{4}\.\d{4}\.\d{2}$/,
      mask: '999999999'
    },
    business: {
      label: 'UID',
      regex: /^CHE\-\d{3}\.\d{3}\.\d{2}[A-Z]{1}$/,
      mask: 'CHE-999.999.99A'
    }
  },
  CL: {
    regex: /^\d{8}\-\d{1}|[A-Z]$/,
    mask: '99999999-A',
    person: {
      label: 'RUT',
    },
    business: {
      label: 'RUN',
    }
  },
  CY: {
    label: 'TIN',
    regex: /^\d{8}\L$/,
    mask: '99999999L'
  },
  DE: {
    label: 'TIN',
    regex: /^\d{10}[A-Z]$/,
    mask: '9999999999A'
  },
  EE: {
    label: 'TIN',
    regex: /^\d{11}$/,
    mask: '99999999999'
  },
  FI: {
    label: 'TIN',
    person: {
      regex: /^\d{6}\+|-|A\d{3}[A-Z]|[0-9]{1}$/,
      mask: '999999-999A'
    },
    business: {
      regex: /^\d{7}\-\d{1}$/,
      mask: '9999999-9'
    }
  },
  FR: {
    person: {
      label: 'SPI',
      regex: /^\d{2}\s\d{2}\s\d{3}\s\d{3}\s\d{3}$/,
      mask: '99 99 999 999 999'
    },
    business: {
      label: 'SIREN',
      regex: /^\d{3}\s\d{3}\s\d{3}$/,
      mask: '999 999 999'
    }
  },
  UK: {
    // https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/UK-TIN.pdf
    label: 'UTR/NINO',
    regex: /^\d{10}|([A-Z]{2}[0-9]{6}[ABCD])$/,
    mask: '9999999999 / AA999999A', // uppercase letters and numbers
  },
  GR: {
    label: 'TIN',
    regex: /^\d{9}$/,
    mask: '999999999'
  },
  IE: {
    person: {
      label: 'TRN',
      regex: /^\d{7}[A-Z]{1}|[A-Z]{2}$/,
      mask: '9999999AA'
    },
    business: {
      label: 'CHY',
      regex: /^CHY\d{1}|\d{2}\d{3}\d{4}\d{5}$/,
      mask: 'CHY99999'
    }
  },
  LT: {
    label: 'TIN',
    regex: /^\d{11}$/,
    mask: '99999999999'
  },
  LU: {
    label: 'TIN',
    regex: /^\d{13}$/,
    mask: '9999999999999'
  },
  LV: {
    label: 'TIN',
    person: {
      regex: /^\d{11}$/,
      mask: '99999999999'
    },
    business: {
      regex: /^9000|4000|5000\d{5}$/,
      mask: '999999999'
    }
  },
  MT: {
    label: 'TIN',
    person: {
      regex: /^(\d{7}[A-Z]{1})|(\d{9})$/,
      mask: '9999999A'
    },
    business: {
      regex: /^\d{9}$/,
      mask: '999999999'
    }
  },
  NL: {
    label: 'TIN',
    regex: /^\d{9}$/,
    mask: '999999999'
  },
  PT: {
    label: 'TIN',
    regex: /^\d{9}$/,
    mask: '999999999'
  },
  SK: {
    label: 'TIN',
    regex: /^\d{9}|\d{10}$/,
    mask: '9999999999'
  },
  other: {
    label: 'Tax ID',
    regex: /^.*$/, // anything
    mask: ' ', // uppercase letters and numbers
    validate: () => true
  }
}

/*
export default country => {
  const country = countries[country] || countries['other']
  return {
    person: {
      label: country.label || country.person.label,
      regex: country.regex || country.person.regex,
      mask: country.mask || country.person.mask
    },
    business: {
      label: country.label || country.business.label,
      regex: country.regex || country.business.regex,
      mask: country.mask || country.business.mask
    }
  }
}
*/

export default country => countries[country] || countries['other']