import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { GraphQL } from '../components';
import { useLang, useLink } from '../hooks';
import { PagedFeed } from './activity/PagedFeed';
import ActivityStreamQuery from './ActivityStreamQuery';

const ActivityStream = ({ onlyUnread }) => {
  const assets = useSelector((state) => state.assets);
  const profile = useSelector((state) => state.session.profile);
  const locale = useLang();
  const pushLink = useLink();

  if (!profile || assets.size === 0) {
    return <LinearProgress />;
  }

  return (
    <GraphQL
      Waiter={CircularProgress}
      query={ActivityStreamQuery}
      variables={{
        count: 21,
        filter: {
          actorObjectId: `${profile.id}`,
          ...(onlyUnread ? { unread: true } : null),
        },
      }}
    >
      {({ stub }) => {
        return (
          <PagedFeed
            assets={assets}
            profile={profile}
            push={pushLink}
            stub={stub}
            locale={locale}
            onlyUnread={onlyUnread}
          />
        );
      }}
    </GraphQL>
  );
};

export default ActivityStream;
