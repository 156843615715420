import React from 'react';
import { Avatar, MenuItem, Menu, makeStyles } from '@material-ui/core';
import { AssetImage } from '../components';

const useStyles = makeStyles(() => ({
  menuItemText: {
    paddingLeft: 32,
  },
  baseAsset: {
    marginLeft: -64,
  },
  quoteAsset: {
    marginLeft: 32,
  },
}));

const MarketMenu = ({ active, anchorEl, asset, markets, onClick, onClose }) => {
  const classes = useStyles();

  if (!anchorEl) {
    return null;
  }
  return (
    <Menu
      id='dd-lock-menu'
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {Object.keys(markets).reduce((acc, symbol) => {
        const market = markets[symbol];
        if (
          !asset ||
          market.base_asset.code === asset.code ||
          market.quote_asset === asset.code
        ) {
          acc.push(
            <MenuItem
              key={symbol}
              selected={symbol === active}
              onClick={(event) => {
                onClick(market);
                event.stopPropagation();
              }}
            >
              <AssetImage
                component={Avatar}
                className={classes.quoteAsset}
                alt={market.quote_asset.code}
                src={market.quote_asset.icon}
                useStatic={market.quote_asset.use_static_icon}
              />
              <AssetImage
                component={Avatar}
                className={classes.baseAsset}
                alt={market.base_asset.code}
                src={market.base_asset.icon}
                useStatic={market.base_asset.use_static_icon}
              />
              <div className={classes.menuItemText}>{symbol}</div>
            </MenuItem>
          );
        }
        return acc;
      }, [])}
    </Menu>
  );
};

export default MarketMenu;
