import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 480,
    display: 'block',
  },
}));

function AnswerInput({ label, value, onChange, ...props }) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.textField}
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
}

class AnswerOption extends Component {
  constructor(props) {
    super(props);

    let inputValues = null;
    if (props.answer && props.answer.inputValues) {
      inputValues = props.answer.inputValues;
    } else if (props.answerInputs) {
      inputValues = props.answerInputs.reduce(
        (acc, input) => ({ ...acc, [input]: '' }),
        {}
      );
    }

    this.state = {
      inputValues,
    };
  }

  handleInputChange = (label) => (event) => {
    const inputValues = {
      ...this.state.inputValues,
      [label]: event.currentTarget.value,
    };
    this.setState({ inputValues });
    this.props.onAnswerSelected({
      value: this.props.answerType,
      inputValues,
    });
  };

  handleAnswer = () => {
    if (this.props.answerInputs) {
      this.props.onAnswerSelected({
        value: this.props.answerType,
        inputValues: this.state.inputValues,
      });
    } else {
      this.props.onAnswerSelected(this.props.answerType);
    }
  };

  render() {
    const { answer, answerInputs, answerType, answerContent } = this.props;
    const { inputValues } = this.state;
    const answerValue = answerInputs && answer ? answer.value : answer;

    return (
      <li className='answerOption'>
        <input
          type='radio'
          className='radioCustomButton'
          name='radioGroup'
          checked={answerType === answerValue}
          id={answerType}
          value={answerType}
          onChange={this.handleAnswer}
        />
        <label className='radioCustomLabel' htmlFor={answerType}>
          {answerContent}
          {answerInputs &&
            answerInputs.map((label) => (
              <AnswerInput
                key={label}
                label={label}
                disabled={answerType !== answerValue}
                value={inputValues ? inputValues[label] : ''}
                onChange={this.handleInputChange(label)}
              />
            ))}
        </label>
      </li>
    );
  }
}

AnswerOption.propTypes = {
  answerType: PropTypes.string.isRequired,
  answerContent: PropTypes.string.isRequired,
  answerInputs: PropTypes.array,
  answer: PropTypes.any,
  onAnswerSelected: PropTypes.func.isRequired,
};

export default AnswerOption;
