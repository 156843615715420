import graphql from 'babel-plugin-relay/macro';

export default graphql`
  query useAssetExploreQuery($language: String!) {
    classes: allSecuritiesAssetclasses(filter: {isVisible: {equalTo: true}}) {
      nodes {
        slug
        sortTerm
        image: filerImageByImageId {
          file: filerFileByFilePtrId {
            path: file
          }
        }
        classcontent(languageCode: $language) {
          nodes {
            code
            contentName
            tag
            text
          }
        }
      }
    }
  }
`;
