import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from '../components/utils/notistack_redux/redux/actions';
import useIntl from './useIntl';

export default function useError() {
  const { error: translate } = useIntl();
  const dispatch = useDispatch();
  const onError = useCallback(
    (err) => {
      const message =
        typeof err === 'string'
          ? err // better already be translated
          : typeof err === 'object'
          ? err.message ?? err.statusText // better already be translated by HTTP client agent
          : translate.service_unavailable;
      dispatch(
        enqueueSnackbar({
          message,
          options: {
            variant: 'error',
          },
        })
      );
    },
    [dispatch, translate]
  );

  return onError;
}
