import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';

// returns assets from redux state filtered to only those that the
// active profile has a wallet for, and always LUSD.

export default function useWalletAssets() {
  const [walletAssets, setWalletAssets] = useState(Map());
  const wallets = useSelector((state) => state.wallets);
  const assets = useSelector((state) => state.assets);

  useEffect(
    () =>
      setWalletAssets(
        assets.filter((value, key) => wallets.has(key) || key === 'LUSD')
      ),
    [assets, wallets, setWalletAssets]
  );

  return walletAssets ? walletAssets : {};
}
