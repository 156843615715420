import React from 'react';
import { SvgIcon } from '@material-ui/core';

// material-icons-outlined payments
export default () => {
  return (
    <SvgIcon>
      <svg xmlns='http://www.w3.org/2000/svg' height='24' width='24'>
        <path fill='none' d='M0 0h24v24H0z' />
        <path d='M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-2 0H3V6h14v8zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm13 0v11c0 1.1-.9 2-2 2H4v-2h17V7h2z' />
      </svg>{' '}
    </SvgIcon>
  );
};
