import React from 'react';
import { useIntl } from '../../hooks';
import StreetTicketActivityDisplay from './StreetTicketActivityDisplay';

import type {
  ActivityButtonHandlerDictionary,
  ActivityDisplayProps,
  StreetActivityCounterParty,
  StreetActivityMethod,
  StreetActivityStatus,
  StreetActivityVariables,
} from './types';

interface Props extends ActivityDisplayProps {
  variables: StreetActivityVariables;
  counterparty: StreetActivityCounterParty;
  method: StreetActivityMethod;
  status: StreetActivityStatus;
  // eslint-disable-next-line no-unused-vars
  handleAction: (action: number | string | undefined, props?: Record<string, unknown>) => () => void;
}

const StreetTicketActivityForTakerSell: React.FC<Props> = ({
  asset,
  badgeType,
  label,
  template,
  variables,
  counterparty,
  method,
  status,
  time,
  unread,
  onRead,
  handleAction,
}) => {
  const translate = useIntl();
  // eslint-disable-next-line no-unused-vars
  const { quantity, price, cost, quote_asset } = variables;

  const handlers: ActivityButtonHandlerDictionary =
    status.code === 'pledged' || status.code === 'escrowed'
      ? {
          secondary: {
            caption: translate.dialog.cancel,
            handler: handleAction('cancel'),
          },
        }
      : status.code === 'accepted'
      ? {
          primary: {
            caption: translate.street_ticket.release_escrow.label,
            handler: handleAction('complete'),
          },
        }
      : status.code === 'shipped'
      ? {
          primary: {
            caption: translate.street_ticket.release_escrow.label,
            handler: handleAction('complete'),
          }
        }
      : {};

  return (
    <StreetTicketActivityDisplay
      asset={asset}
      badgeType={badgeType}
      label={label}
      template={template}
      variables={variables}
      handlers={handlers}
      counterparty={counterparty}
      method={method}
      status={status}
      time={time}
      unread={unread}
      onRead={onRead}
    >

      </StreetTicketActivityDisplay>
  );
};

export default StreetTicketActivityForTakerSell;
