import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';

interface Props {
  time: DateTime;
}

const DateDivider: React.FC<Props> = ({ time }) => (
  <Box p={2} textAlign='left'>
    <Divider />
    <Typography color='textSecondary'>
      {time.toLocaleString(DateTime.DATE_MED)}
    </Typography>
  </Box>
);

function arePropsEqual(prev: Props, next: Props) {
  return (
    prev.time.toLocaleString(DateTime.DATE_MED) ===
    next.time.toLocaleString(DateTime.DATE_MED)
  );
}

export default React.memo(DateDivider, arePropsEqual);
