import React from 'react';
import { format } from '../../utils/number';
import { GraphQL } from '../../components';
import { useIntl } from '../../hooks';
import ActivitySkeleton from './ActivitySkeleton';
import TransferActivityDisplay from './TransferActivityDisplay';
import TransferActivityQuery from './TransferActivityQuery';

import type { TransferActivityQuery$data } from './__generated__/TransferActivityQuery.graphql';
import { ActivityProps } from './types';

interface Props extends ActivityProps{
  amount: number;
  currency: string;
  quantity: number;
  receiver: string;
  sender: string;
  type: string;
}

const TransferActivity: React.FC<Props> = ({
  actionId,
  assets,
  locale,
  onRead,
  receiver,
  sender,
  time,
  type,
  unread,
  currency,
  quantity: amount,
}) => {
  const translate = useIntl();
  const direction = type === 'send-to' ? 'sent' : 'received';

  return (
    <GraphQL
      Waiter={ActivitySkeleton}
      variables={{ rowId: actionId }}
      query={TransferActivityQuery}
    >
      {({ transfer }: TransferActivityQuery$data) => {
        const asset = transfer?.networkAsset?.asset ?? assets.get(currency);
        const [quantity] = format((transfer?.quantity ?? amount), asset, locale);

        return (
          <TransferActivityDisplay
            time={time}
            unread={unread}
            onRead={onRead}
            badgeType='none'
            asset={asset}
            label={`activity.${direction}.label`}
            template={translate.activity[direction].text}
            variables={{
              sender: sender ?? '',
              receiver: receiver ?? '',
              quantity: quantity ?? '',
            }}
            note={transfer?.note ?? ''}
          />
        );
      }}
    </GraphQL>
  );
};

export default TransferActivity;
