import graphql from 'babel-plugin-relay/macro';

export default graphql`
query useActiveFiatAssetsQuery {
  assets: allSecuritiesAssets(filter: {securitiesExternalassetByAssetPtrId: {securitiesFiatassetByExternalassetPtrIdExists: true}, isActive: {equalTo: true}}) {
    nodes {
      code
      name
    }
  }
}
`;
