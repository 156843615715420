import React from 'react';
import GraphQL from "./GraphQL";
import PromotionsQuery from "./PromotionsQuery";
import Promo from '../components/Promo';
import { Box } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { useLang } from '../hooks';

const Promotions = ({ promos }) => {
  return promos?.nodes.length ? (
    <Box p={1}>
      <Carousel interval={15000}>
        {promos.nodes.map((promo, index) => (
          <Promo key={index} {...promo} />
        ))}
      </Carousel>
    </Box>
  ) : (
    <div/>
  );
};

const PromotionsController = () => {
  const language = useLang();
  return (
    <GraphQL query={PromotionsQuery} variables={{language}}>
      {props => <Promotions {...props}/>}
    </GraphQL>
  )
}

export default PromotionsController;